import { getData, postData } from "services/apiService";

export const GET_VENDORS = "GET_VENDORS";
export const GET_VENDORS_IS_LOADING = "GET_VENDORS_IS_LOADING";
export const GET_VENDORS_FAILED = "GET_VENDORS_FAILED";

// Get Vendors
//************************************** /

export const getVendorsIsLoading = () => {
  return {
    type: GET_VENDORS_IS_LOADING,
    payload: {},
  };
};

export const getVendorsFailed = (error) => ({
  type: GET_VENDORS_FAILED,
  payload: error,
});

export function getVendors() {
  const endPoint = "/mobileVendor/vendors/getAll";

  return (dispatch) => {
    dispatch(getVendorsIsLoading());
    getData(endPoint)
      .then((response) => {
        dispatch({
          type: GET_VENDORS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch(getVendorsFailed(error));
      });
  };
}

export const GET_VENDORS_BY_SLUG = "GET_VENDORS_BY_SLUG";
export const GET_VENDORS_SLUG_IS_LOADING = "GET_VENDORS_SLUG_IS_LOADING";
export const GET_VENDORS_SLUG_FAILED = "GET_VENDORS_SLUG_FAILED";

// Get Vendors By Slug
//************************************** /

export const getVendorsSlugIsLoading = () => ({
  type: GET_VENDORS_SLUG_IS_LOADING,
  payload: {},
});

export const getVendorsSlugFailed = (error) => ({
  type: GET_VENDORS_SLUG_FAILED,
  payload: error,
});

export function getVendorsBySlug(slug) {
  const endPoint = `/mobileVendor/vendors/get/${slug}`;

  return (dispatch) => {
    dispatch(getVendorsSlugIsLoading());
    getData(endPoint)
      .then((response) => {
        dispatch({
          type: GET_VENDORS_BY_SLUG,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch(getVendorsSlugFailed(error));
      });
  };
}

export const CREATE_VENDOR = "CREATE_VENDOR";
export const CREATE_VENDOR_IS_lOADING = "CREATE_VENDOR_IS_lOADING";
export const CREATE_VENDOR_FAILED = "CREATE_VENDOR_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const createVendorIsLoading = () => ({
  type: CREATE_VENDOR_IS_lOADING,
  payload: {},
});

export const createVendorFailed = (error) => ({
  type: CREATE_VENDOR_FAILED,
  payload: error,
});

export function createVendor(name) {
  const endPoint = "/mobileVendor/vendors/create";
  createVendorIsLoading();

  return (dispatch) => {
    dispatch(createVendorIsLoading());
    const payload = {
      name: name,
    };
    postData(endPoint, payload)
      .then((response) => {
        dispatch({
          type: CREATE_VENDOR,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch(createVendorFailed(error));
      });
  };
}

// Get Categories

export const GET_CATEGORY = "GET_CATEGORY";
export const GET_CATEGORY_IS_LOADING = "GET_CATEGORY_IS_LOADING";
export const GET_CATEGORY_FAILED = "GET_CATEGORY_FAILED";

export const getCategoryIsLoading = () => ({
  type: GET_CATEGORY_IS_LOADING,
  payload: {},
});

export const getCategoryFailed = (error) => ({
  type: GET_CATEGORY_FAILED,
  payload: error,
});

export function getCategory(vendorId, level) {
  // const endPoint = `/items/fetch-categories?vendor=${vendorId}&level=5`;
  const endPoint = `/items/fetch-top-menu?vendor=${vendorId}&level=2`;

  return (dispatch) => {
    dispatch(getCategoryIsLoading());
    getData(endPoint)
      .then((response) => {
        dispatch({
          type: GET_CATEGORY,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch(getCategoryFailed(error));
      });
  };
}

// Get Shopify Vendors SideMenu

export const GET_SHOPIFY_SIDEMENU = "GET_SHOPIFY_SIDEMENU";
export const GET_SHOPIFY_SIDEMENU_IS_LOADING = "GET_SHOPIFY_SIDEMENU_IS_LOADING";
export const GET_SHOPIFY_SIDEMENU_FAILED = "GET_SHOPIFY_SIDEMENU_FAILED";

export const getShopifySidemenuiIsLoading = () => ({
  type: GET_SHOPIFY_SIDEMENU_IS_LOADING,
  payload: {},
});

export const getShopifySidemenuFailed = (error) => ({
  type: GET_SHOPIFY_SIDEMENU_FAILED,
  payload: error,
});

export function getShopifySidemenu(vendorId) {
  const endPoint = `/items/secondLife-side-menu/${vendorId}`;
  return (dispatch) => {
    dispatch(getShopifySidemenuiIsLoading());
    getData(endPoint)
      .then((response) => {
        dispatch({
          type: GET_SHOPIFY_SIDEMENU,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch(getShopifySidemenuFailed(error));
      });
  };
}