import { Fragment } from "react"
import { Link } from "react-router-dom"
import { GridCardView } from "../GridCardView/GridCardView"
import ProductCard from "../ProductCard/ProductCard"
import { ProductListView } from "../ProductListView/ProductListView"
import { VendorInfoCard } from "../VendorInfoCard/VendorInfoCard"
import { VendorListingCard } from "../VendorListingCard/VendorListingCard"

interface CardInterface {
    onClick: any
    cardHeight: string
    cardType: string
    VendorData: any
    CardData: any
    ProductCardData: any
    tickerLoading: boolean
    isLoading: boolean
    vendorLoading: boolean
    productsLoading: boolean
    RedirectTo: any
    slug: any
    searchName: any
    isShopifyLoading: boolean
    searchProduct: any
}

export const Card: React.FC<CardInterface> = ({cardHeight,cardType, isShopifyLoading, onClick, searchProduct, VendorData, CardData, ProductCardData, tickerLoading, isLoading, vendorLoading, productsLoading, RedirectTo, slug, searchName}) => {
  switch(cardType) {
    case 'VendorListingCard':
      return (
        <VendorListingCard VendorData={VendorData} cardHeight={cardHeight} isLoading={isLoading} />
      )
    case 'VendorGridLayout':
      return (
        <GridCardView onClick={undefined} VendorData={VendorData} isLoading={isLoading} />
      )
    case 'VendorPageInfoCard':
    return (
      <VendorInfoCard VendorData={VendorData} onClick={onClick} cardHeight={cardHeight} vendorLoading={vendorLoading} />
    )
    case 'ProductCard':
      return(
        <ProductCard searchProduct={searchProduct} ProductCardData={ProductCardData} handleClick={onClick} productsLoading={productsLoading} RedirectTo={RedirectTo} slug={slug} searchName={searchName} />
    )
    case 'ProductListView':
      return (
        <ProductListView ProductCardData={ProductCardData} onClick={onClick} isShopifyLoading={isShopifyLoading} searchProduct={searchProduct} />
      )
    default:
      return (
        <Fragment>
          {CardData?.map((data: any, i: number) => (
            <div key={i} className="flex w-full items-center rounded-lg" style={{ height: cardHeight, background: data.bgColor }}>
              <div className="flex items-center w-full justify-between space-x-3 mx-6">
                <div className="min-w-0 flex-1">
                  <p className="text-base mb-6 text-secondary-700"> 
                    {data.title}
                  </p>
                  <p className="text-2xl font-semibold text-secondary-700">
                    {!tickerLoading ? data.count : <div className="animate-pulse"><div className="h-6 w-6 bg-secondary-500 rounded"></div></div>}
                  </p>
                </div>
                {/* Card Icons */}
                <div className={`w-12 h-12 mt-12 border-2 flex items-center justify-center rounded-full  border-none`} style={{ backgroundColor: data.defaultCardColor }}>
                  {data.icons}
                </div>
              </div>
            </div>
          ))}
        </Fragment>
      )
  }
}
