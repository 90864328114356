import { getStore } from "store";
import Cookies from "js-cookie";
export const checkIfVendorConnected = (currentVendor) => {
  let alreadyAuthenticated: false | any = false;
  let authReducer = getStore?.autReducer?.GetUserReducer?.data;
  let availableVendors = authReducer?.data?.vendors;

  alreadyAuthenticated =
    availableVendors &&
    availableVendors.length > 0 &&
    availableVendors.filter(
      (ele: any) => ele.name === currentVendor /** ele.id === data?.vendor */
    );
  return alreadyAuthenticated && alreadyAuthenticated?.length > 0
    ? alreadyAuthenticated[0]
    : false;
};

class CookieHandler {
  private parentDomain = "repairdesk.co";
  // private parentDomain = "repairdesk.co";
  setCookie = (name: string, value: string, parentDomain: boolean) => {
    let config = {};
    if (parentDomain) {
      config = { domain: this.parentDomain };
    }
    Cookies.set(name, value, config);
    return { name, value, status: "success" };
  };

  getCookie = (name: string, parentDomain: boolean) => {
    let config = {};
    if (parentDomain) {
      config = { domain: this.parentDomain };
    }
    return Cookies.get(name, config);
  };
}
let cookieHandler = new CookieHandler();
export default cookieHandler;
