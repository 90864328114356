import { useDispatch, useSelector } from 'react-redux'
import { classNames, dataPoints } from '../../utils/CommonFunctions'
import { svgIcons } from '../../assets/svg'
import { Badges } from '../Badges/Badges'
import { useNavigate } from 'react-router-dom'
import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader'
import { Fragment, useEffect, useState } from 'react'
import PlaceholderImg from '../../assets/images/placeholder-image.png'

interface GridCardViewProps {
  onClick: any
  VendorData: any
  isLoading: boolean
}
  
  export const GridCardView:React.FC<GridCardViewProps> = ({VendorData, onClick, isLoading}) => {
  
  // Hooks
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // Reducer
  const { data }: any = useSelector(({ autReducer }: any) => autReducer.GetUserReducer)
  // States
  // Extract Data
  const extUserId = data?.data?._doc?.rdUser
  const extStoreId = data?.data?._doc?.storeId
  // const extVendorsList = VendorData[5]?.promotion
  // console.log('extVendorList', extVendorsList)
  // Functions
  const RedirectToVendorPage = (data) => {
  if(data?.isVisible === true){
  dataPoints(extUserId, 'Vendor_Clicked', extStoreId, `Marketplace | ${data?.name} `)
  navigate(`/vendor/${data?.name}`)
  } else {
    return ""
  }
  window.scrollTo(0,0)
  }
    return (
      <Fragment>
      {!isLoading ? 
      <div className={`grid grid-cols-3 ${VendorData?.length > 0 ? 'xl:grid-cols-3' : 'xl:grid-cols-1'}  xl:gap-x-6 xl:gap-y-6 overflow-hidden sm:grid md:grid ${VendorData?.length > 0 ? 'md:grid-cols-2' : 'md:grid-cols-1'}  sm:gap-x-6 sm:gap-y-6`}>
        {VendorData?.length > 0 ? VendorData?.map((data:any) => ( 
          <div
            key={data.id}
            style={{height: "270px"}}
            onClick={data?.maintenance === true ? () => {} : () => RedirectToVendorPage(data)}
            className={classNames(
              `relative rounded-md group ${data?.maintenance === true ? 'opacity-80' : 'opacity-100'} bg-white p-6 hover:${data?.maintenance === true ? 'opacity-0' : 'opacity-80'} ${data?.promotion === true ? 'promotion' : data?.maintenance === true ? 'maintenance' : ''}`
            )}
          >
            <div className="flex-shrink-0 border flex justify-center items-center border-secondary-300 rounded-md" style={{width: "130px", height: "90px"}}>
            <img
                className="h-[60px] w-[110px]  rounded-full object-contain"
                src={data?.logo || PlaceholderImg}
                alt=""
            />
            </div>
            <div className="mt-4">
              <h3 className="text-lg font-medium" style={{width: "397px"}}>
                <button className="focus:outline-none">
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true" />
                  {data.label}
                </button>
              </h3>
              <p className="mt-2 text-sm text-secondary-500" style={{minWidth: "auto", maxWidth: "397px", height: "62px"}}>
                {data.description.length > 100 ? data.description.slice(0,150) + "..." : data.description}
              </p>
            </div>
            {/* Coming Soon Badge */}
            {data?.isVisible === false ?
            <span
              className="mr-8 pointer-events-none absolute top-6 right-6 text-secondary-300 group-hover:text-secondary-400"
              aria-hidden="true"
            >
              <Badges badgeBgColor="#DCFCE7" badgeTextColor="#166534" badgeContent="Coming Soon" textSize={'text-sm'} textWeight={'font-medium'} badgeWidth={'130px'} />
            </span>
            :
            ''
            }
            {/* Maintanence */}
            {data?.maintenance === true ?
              <div className="ribbon">
                <span className="ribbon3">Under Maintenance</span>
              </div>
              :
              ''
            }
            {data?.promotion === true ?
                <div className="ribbon">
                  <span className="ribbon2">New device vendor</span>
                </div>
              :
              ''
            }
            <span
              className="pointer-events-none absolute top-6 right-6 text-secondary-300 group-hover:text-secondary-400"
              aria-hidden="true"
            >
              {svgIcons.arrowUpRight}
            </span>
          </div>
        )) : <div className='animate-pulse flex justify-center mt-16 text-lg font-bold text-primary-600'>No Vendors!</div>}
      </div>
      : 
      <div className='flex gap-x-4'><SkeletonLoader skeletonType={'GridCardViewSkeleton'} defaultSkeletonHeight={''} smallInputWidth={''} smallInputHeight={''} /></div> // Skeleton Loader
      }
      </Fragment>
    )
  }
  