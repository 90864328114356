import React from 'react'
import { Link } from 'react-router-dom'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { svgIcons } from '../../assets/svg'
import { useDispatch, useSelector } from 'react-redux'
import { dataPoints } from 'utils/CommonFunctions'

export const Breadcrumbs = ({currentPage, currentPageSlug, productId, location, id, extName}:any) => {
  // Hooks
  const dispatch = useDispatch()
  // Reducer
  const { data }: any = useSelector(({ autReducer }: any) => autReducer.GetUserReducer)
  const extUserId = data?.data?._doc?.rdUser
  const extStoreId = data?.data._doc?.storeId
  // Function
  const addDataPoints = () => {
    dataPoints(extUserId, 'Home_Opened', extStoreId, 'Marketplace | Nav Bar')
  }
  // Product Path variable
  const Check_Product_Page_Path = location.pathname === `/vendor/${currentPageSlug}/${id}`;

  // Breadcrunmbs Array
  const pages = 
  Check_Product_Page_Path ? 
  [
    { name: currentPage, to: `/vendor/${currentPageSlug}`, current: true },
    { name: extName ? `${extName.slice(0,30)}` + " ..." : '...', to: `/vendor/${currentPageSlug}/${productId}`, current: true }
  ]
  :
  [
    { name: currentPage, to: `/vendor/${currentPageSlug}`, current: true },
  ]

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <Link to="/" onClick={addDataPoints} className="text-secondary-400 hover:text-secondary-500">
              {svgIcons.homeIcon}
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {pages.map((page) => (
          <li key={page.name}>
            <div className="flex items-center">
              <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-secondary-400" aria-hidden="true" />
              <Link
                to={page.to}
                className="ml-4 text-sm font-medium text-secondary-500 hover:text-secondary-700"
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}
