import * as Actions from "../actions";

const initialState = {
  isLoading: false,
  errMsg: null,
  data: null,
  success: "",
};

export const GetTickersReducer = function (state = initialState, action) {
    switch (action.type) {
      case Actions.FETCH_TICKER:
        return {
          ...state,
          isLoading: false,
          errMsg: null,
          data: action.payload,
          success: true,
        };
      case Actions.FETCH_TICKER_IS_lOADING:
        return {
          ...state,
          isLoading: 'loading',
          errMsg: null,
          data: null,
          success: false,
        };
      case Actions.FETCH_TICKER_FAILED:
        return {
          ...state,
          isLoading: false,
          errMsg: action.payload,
          data: null,
          success: "failed",
        };
      default:
        return state;
    }
  };