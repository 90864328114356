import React, { Fragment, useEffect, useState } from "react"
import withReducer from "store/withReducer"
import * as Actions from "./store/actions"
import reducer from "./store/reducers"
import { useSelector, useDispatch } from "react-redux"
import { Table } from "../../components/Table/Table"
import { MenuDropdown } from "../../components/MenuDropdown/MenuDropdown"
import { dataPoints } from "utils/CommonFunctions"

const OrderPage: React.FC = () => {
  // Hooks
  const dispatch = useDispatch()
  // Reducers
  const {data: userData}: any = useSelector(({ autReducer }: any) => autReducer.GetUserReducer)
  const {data: orderData} = useSelector(({ Orders }: any) => Orders.GetOrdersReducer)
  // Extract Data
  const extUserId = userData?.data?._doc?.rdUser;
  const extStoreId = userData?.data._doc?.storeId
  const extNewUi = userData?.data?.data?.rdUser?.map(u => u.new_ui) || userData?.data?.rdUser?.map(u => u.new_ui)
  const extSubDomain = userData?.data?._doc?.subdomain
  // states
  const [searchValue, setSearchValue] = useState<string>("")
  const [supplierFilter, setSupplierFilter] = useState("")
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [sortType, setSortType] = useState<1 | -1>(-1)
  // Effects
  useEffect(() => {
    dispatch(Actions.getOrders(searchValue, currentPage, sortType, supplierFilter))
  }, [searchValue, currentPage, supplierFilter, sortType])
  // Methods
  const handleSearch = (e: any) => {
    setSearchValue(e.trim())
    setCurrentPage(1)
  }
  const handleNavigate = (id) => {
    const nux = extNewUi && extNewUi[0] === 1 ? "nux/" : ""
    const viewId = extNewUi && extNewUi[0] === 1 ? `index#/${id}` : `view&id=${id}`
    // const url = `https://team-c.repaircrm.co/index.php?r=${nux}purchaseorder/index#/${id}`
    const url = `${extSubDomain}/index.php?r=${nux}purchaseorder/${viewId}`
    return window.open(url)
  }
  const handleTableChange = (page) => {
    setCurrentPage(page)
  }
  const handleSort = (sortType: 1 | -1) => {
    setSortType(sortType)
    dataPoints(extUserId, "Orders_Sorted", extStoreId , `Marketplace | Order History  ${sortType}`)
  }


  

  return (
    <div className={`w-full 3xl:w-10/12 4xl:w-7/12 mx-auto`}>
      <div className="sm:flex sm:items-center mx-6 mt-6">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-secondary-700">My Orders</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <MenuDropdown sortHandler={handleSort} sortType={sortType} />
        </div>
      </div>
      <Table orderReducerData={orderData} handleNavigate={handleNavigate} currentSupplier={supplierFilter} supplierFilter={setSupplierFilter} vendors={orderData?.vendorList} searchOrder={handleSearch} currentPage={orderData?.page} totalDocs={orderData?.totalDocs} totalPages={orderData?.totalPages} handleChangePage={handleTableChange} ProductCardData={undefined} onClick={undefined} tableType={'OrderHistory'} reportData={''} orderHistoryData={orderData?.docs} />
    </div>
  )
}

export default withReducer("Orders", reducer)(OrderPage)

