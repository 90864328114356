import React, { Fragment, useEffect, useState } from "react";
import { Spin } from "antd";
import jwtService from "../services/jwtService";
import { useDispatch } from "react-redux";
import * as Actions from "./store/actions";
import * as CommonActions from "store/actions";
import mpLogo from "../assets/images/repairdesk-marketplace.svg";
import Loader from "../assets/images/RollingLoader.svg";
import useCurrentRedirectLocation from "hooks/useCurrentRedirectLocation/useCurrentRedirectLocation";
const Auth: React.FC = ({ children }) => {
  const [authWait, setAuthWait] = useState<boolean>(true);
  const dispatch = useDispatch();
  const REDIRECT_URL = useCurrentRedirectLocation()

  useEffect(() => {
    setTimeout(() => {
      jwtService.setInterceptors();
      jwtService
        .handleAuthentication()
        .then(({ token, userData }) => {
          jwtService.setUserData(token, userData);
          dispatch(Actions.getUser({ token, ...userData }));
          dispatch(CommonActions.getVendors());
          setAuthWait(false);
        })
        .catch((err) => {
          window.location.href = REDIRECT_URL || "https://app.repairdesk.co"
        });
    }, 500);
  }, []);

  return (
    <Fragment>
      {authWait ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            background: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <div style={{ marginBottom: "25px" }}>
              <img src={mpLogo} alt="logo" width={300} />
            </div>
            <div>
              <img
                src={Loader}
                alt=""
                style={{ margin: "auto" }}
              />
            </div>
            {/* <Spin size="large" /> */}
          </div>
          {/* <div
            style={{
              position: "absolute",
              bottom: "100px ",
              textAlign: "center",
              fontSize: "20px",
              color: "RGBA(255, 255, 255, 0.56)",
            }}
          >
            Powered by RepairDesk
          </div> */}
        </div>
      ) : (
        <>{children}</>
      )}
    </Fragment>
  );
};

export default Auth;
