import { Menu, Transition } from '@headlessui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import { Fragment } from 'react'
import { SearchIcon } from '@heroicons/react/solid'
import { useEffect, useState } from "react"
import dayjs from "dayjs"
import { Link } from 'react-router-dom'
import { svgIcons } from '../../assets/svg'
import { checkIfVendorConnected } from "../../services/HelperService/HelperService"
import { classNames, dataPoints } from '../../utils/CommonFunctions'
import { Badges } from '../Badges/Badges'
import Modal from "../Modal/Modal"
import { QuantityCounter } from '../QuantityCounter/QuantityCounter'
import { SearchField } from '../SearchField/SearchField'
import dots from '../../assets/images/3dot primary.svg'
import { Pagination } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader'
import { useLocation, useMatch, useNavigate } from 'react-router'
import PlaceholderImg from "../../assets/images/placeholder-image.png"
import * as AuthActions from "auth/store/actions"

interface TableProps {
  ProductCardData: any
  onClick: any
  tableType: any
  reportData: any
  orderHistoryData: any
  totalPages?: number
  currentPage?: number
  totalDocs?: number
  handleChangePage?: any
  searchOrder?: any
  vendors?: any
  supplierFilter?: any
  currentSupplier?: any
  handleNavigate?: any
  orderReducerData?: any
}



export const Table: React.FC<TableProps> = ({ vendors, currentSupplier, supplierFilter, currentPage, totalDocs, totalPages, searchOrder, handleChangePage, ProductCardData, onClick, tableType, reportData, orderHistoryData, handleNavigate, orderReducerData }) => {
  // Hooks
  const location = useLocation()
  // Reducer
  const { data }: any = useSelector(({ autReducer }: any) => autReducer.GetUserReducer)
  // Extract
  const ifOnVendorPage = useMatch({path: "/vendor/:slug/:id", end: true})
  const extUserId = data?.data?._doc?.rdUser
  const extStoreId = data?.data._doc?.storeId
  // Common CSS
  const commonBorderClass = 'border-b border-gray-200 rounded-none'
  const commonTableHeadClass = 'text-sm text-secondary-700   py-3'
  // Reducer
  const addDataPoints = (report) => {
  dataPoints(extUserId, 'Purchase_Order_Clicked', extStoreId, 'Marketplace | View Orders')
  }
  // State
  const [searchText, setSearchText] = useState("")
  const [firstLoad, setFirstLoad] = useState(true)
  // console.log('productCardData', ProductCardData)
  // useEffect
  useEffect(() => {
    if (!firstLoad) {
      const delayDebounceFn = setTimeout(() => {
        searchOrder(searchText)
      }, 1000)

      return () => clearTimeout(delayDebounceFn)
    }
    setFirstLoad(false)
  }, [searchOrder, searchText])

  switch (tableType) {
    case 'Product':
      return (
        <div className="sm:px-0 lg:px-0">
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-0 lg:-mx-0">
              <div className="inline-block  rounded-lg min-w-full py-2 align-middle md:px-0 lg:px-0">
                <div className="overflow-hidden">
                  <table className="min-w-full">
                    <thead className="bg-none">
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-normal text-sm text-secondary-500 sm:pl-6">
                          Name
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-center font-normal text-sm text-secondary-500">
                          Quantity
                        </th>
                        {localStorage.getItem("Name")=='true' ? <td></td>
                        :
                        <th scope="col" className="px-3 py-3.5 text-center font-normal text-sm text-secondary-500">
                          Price
                        </th>
                        }
                        {/* <th scope="col" className="px-3 py-3.5 text-center font-normal text-sm text-secondary-500">
                          Price
                        </th> */}
                        <th scope="col" className="px-3 py-3.5 text-center font-normal text-sm text-secondary-500">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y-8 divide-secondary-100 bg-white">
                      {!ifOnVendorPage ? 
                      ProductCardData && ProductCardData?.map((productData: any) => (
                        <ProductsListComponent key={productData.id} product={productData} addToCartListener={onClick} location={location} extUserId={extUserId} extStoreId={extStoreId} />
                      ))
                      :
                      ProductCardData && ProductCardData?.relatedProducts?.length > 0 ? ProductCardData?.relatedProducts.map((productData: any) => (
                        <ProductsListComponent key={productData.id} product={productData} addToCartListener={onClick} location={location} extUserId={extUserId} extStoreId={extStoreId} />
                      ))
                      : 
                      ''
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    case 'OrderHistory':
      return (
        <div className="mx-6 my-6">
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="inline-block w-full align-middle">
                {/* starts */}
                <div className="bg-white rounded-b-none rounded-t-lg px-3 py-3 flex items-center justify-between border-t border-x border-gray-200">
                  <div className="flex gap-x-3 items-center">
                    <span>Filters</span>
                    <VendorsFilterDropdown currentSupplier={currentSupplier} vendors={vendors} supplierFilter={supplierFilter} />
                  </div>
                  <div className="border flex rounded-lg px-2 items-center">
                    <input type="text" className="outline-none py-0.5" placeholder="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                    <SearchIcon className="h-4 w-4 text-secondary-400" aria-hidden="true" />
                  </div>
                </div>
                {/* ends */}
                <div className="relative overflow-y-auto overflow-x-hidden " style={{ boxShadow: "0px 0px 15px -3px rgb(0,0,0,0.05), 0px 0px 0px -4px rgb(0,0,0,0.1)" }}>
                {orderHistoryData ? orderHistoryData.length > 0 ?
                <>
                  <table className="min-w-full border-x">
                    <thead className='sticky top-0 '>
                      {/* row */}
                      <tr className='bg-gray-100 border-t'>
                        <th scope="col" className="bg-secondary-100 sticky top-0  w-12 px-6 sm:w-16 sm:px-8" >
                          <span className='invisible'>checkbox</span>
                        </th>
                        <th scope="col" className="sticky top-0  min-w-[8rem] py-3.5 pr-3 text-left text-sm  text-secondary-500">
                          ID
                        </th>
                        <th scope="col" className="sticky top-0  min-w-[8rem] py-3.5 pr-3 text-left text-sm  text-secondary-500">
                          Purchase Order
                        </th>
                        <th scope="col" className="sticky top-0  min-w-[8rem] px-3 py-3.5 text-left text-sm  text-secondary-500">
                          Order Status
                        </th>
                        <th scope="col" className="sticky top-0  min-w-[8rem] px-3 py-3.5 text-left text-sm  text-secondary-500">
                          Items
                        </th>
                        <th scope="col" className="sticky top-0  min-w-[8rem] px-3 py-3.5 text-left text-sm  text-secondary-500">
                          Amount
                        </th>
                        <th scope="col" className="sticky top-0  min-w-[8rem] px-3 py-3.5 text-left text-sm  text-secondary-500">
                          Supplier
                        </th>
                        <th scope="col" className="sticky top-0  min-w-[8rem] px-3 py-3.5 text-left text-sm  text-secondary-500">
                          Order Time
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                        <>
                          {orderHistoryData.map((report: any) => (
                            <tr key={report.id} className='bg-white border-t border-gray-200'>
                              <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                                <input
                                  type="checkbox"
                                  className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-secondary-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                                  value={report.id}
                                />
                              </td>
                              <td
                                className="py-4 pr-3 text-sm text-secondary-500"
                              >
                                {report?.vendorOrderId}
                              </td>
                              <td className="px-3 py-4 text-sm text-secondary-500 hover:text-secondary-600 cursor-pointer" onClick={() => {handleNavigate(report?.purchaseOrder?.id); addDataPoints(report)}}>{report.purchaseOrder?.order_id}</td>
                              <td className="px-3 py-4 text-sm text-secondary-500">
                                <Badges badgeContent={report.status} badgeBgColor={`${report.status === 'Received' ? '#DCFCE7' : report.status === 'In Progress' ? '#E0E7FF' : '#FDE047'}`} badgeTextColor={'#166534'} textSize={'text-xs'} textWeight={'font-regular'} badgeWidth={report.status.length >= 17 ? '130px' : '100px'} />
                              </td>
                              <td className="px-3 py-4 text-sm text-secondary-500">{report?.cartItems?.length}</td>
                              <td className="px-3 py-4 text-sm text-secondary-500">{parseFloat(report?.totalAmount).toFixed(2)}</td>
                              <td className="px-3 py-4 text-sm text-secondary-500">{report?.vendor?.label}</td>
                              <td className="px-3 py-4 text-sm text-secondary-500">{dayjs(report?.createdAt).format("MM/DD/YYYY hh:mm A")}</td>
                            </tr>
                          ))
                        }
                        </>
                    </tbody>
                  </table>
                  </>
                  : <div className='my-16 animate-pulse text-md font-bold text-primary-600 flex justify-center'>No Record Found!</div>
                  // TODO show skeleton
                  : <SkeletonLoader skeletonType={"TableBodySkeleton"} defaultSkeletonHeight={''} smallInputWidth={''} smallInputHeight={''} />
                }
                </div>
                {
                  
                    <div className="bg-white rounded-t-none rounded-bl-lg rounded-br-lg px-6 py-8 flex items-center justify-between border border-gray-200 sm:px-6">
                      <p className="text-sm text-gray-700">
                        Showing <span className="font-medium">{((currentPage!! - 1) * 10) + 1 || '...'}</span> to <span className="font-medium">{totalDocs!! <= 10 ? totalDocs : (currentPage!! * 10) || '...'}</span> of{' '}
                        <span className="font-medium">{totalDocs}</span> results
                      </p>
                      <Pagination
                        hideOnSinglePage
                        current={currentPage}
                        onChange={handleChangePage}
                        total={orderReducerData?.totalPages || 0}
                        pageSizeOptions={[]}
                        defaultPageSize={10}
                      />
                    </div>
                    
                }
              </div>
            </div>
          </div>
        </div>
      )
    default:
      return (
        <div className="mx-6 my-6">
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full align-middle">
                <div className="relative overflow-y-auto overflow-x-hidden  md:rounded-b-none md:rounded-lg" style={{ height: "634px", boxShadow: "0px 0px 15px -3px rgb(0,0,0,0.05), 0px 0px 0px -4px rgb(0,0,0,0.1)" }}>
                  <table className="min-w-full">
                    <thead className=' sticky top-0 '>
                      <tr className='bg-gray-100'>
                        <th className={`${commonTableHeadClass} bg-white sticky top-0 `}>
                          <div className='flex items-center pl-4 w-64'>
                            <div>Filters By</div>
                            <div className='ml-6'><Badges badgeContent={'Supplier'} badgeBgColor={'purple'} badgeTextColor={'white'} textSize={'text-xs'} textWeight={''} badgeWidth={'100px'} /></div>
                          </div>
                        </th>
                        <th className='sticky to-0  bg-white'></th>
                        <th className='sticky to-0  bg-white'></th>
                        <th className='sticky to-0  bg-white'></th>
                        <th className='sticky to-0  bg-white'>
                          <div className='flex items-center w-full justify-end pr-4'>
                            <div>
                              <SearchField searchType={'default'} width={'w-206'} height={'h-7'} navigate={undefined} onSearchValueChange={undefined} setSearchValue={undefined} focus={false} />
                            </div>
                            <div className='pl-4 cursor-pointer'>
                              {svgIcons.settingIcon}
                            </div>
                          </div>
                        </th>
                      </tr>
                      <tr className='bg-gray-100'>
                        <th scope="col" className="bg-secondary-100 sticky top-0  w-12 px-6 sm:w-16 sm:px-8" >
                          <p className='invisible'>checkbox</p>
                        </th>
                        <th scope="col" className="sticky top-0  min-w-[12rem] py-3.5 pr-3 text-left text-sm  text-secondary-500">
                          SKU
                        </th>
                        <th scope="col" className="sticky top-0  min-w-[12rem] px-3 py-3.5 text-left text-sm  text-secondary-500">
                          Name
                        </th>
                        <th scope="col" className="sticky top-0  min-w-[12rem] px-3 py-3.5 text-left text-sm  text-secondary-500">
                          Supplier
                        </th>
                        <th scope="col" className="sticky top-0  min-w-[12rem] px-3 py-3.5 text-left text-sm  text-secondary-500">
                          Required Quantity
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {reportData.map((report: any, reportId: any) => (
                        <tr key={report.email} className='bg-white'>
                          <td className={classNames(reportId !== reportData.length - 1 ? `${commonBorderClass}` : '', 'border-gray-200 relative w-12 px-6 sm:w-16 sm:px-8')}>
                            <input
                              type="checkbox"
                              className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-secondary-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                              value={report.email}
                            />
                          </td>
                          <td
                            className={classNames(reportId !== reportData.length - 1 ? `${commonBorderClass}` : '', 'py-4 pr-3 text-sm text-secondary-500')}
                          >
                            {report.name}
                          </td>
                          <td className={classNames(reportId !== reportData.length - 1 ? `${commonBorderClass}` : '', 'px-3 py-4 text-sm text-secondary-500')}>{report.title}</td>
                          <td className={classNames(reportId !== reportData.length - 1 ? `${commonBorderClass}` : '', 'px-3 py-4 text-sm text-secondary-500')}>{report.supplier}</td>
                          <td className={classNames(reportId !== reportData.length - 1 ? `${commonBorderClass}` : '', 'px-3 py-4 text-sm text-secondary-500')}><Badges badgeContent={report.quantity} badgeBgColor={'#DCFCE7'} badgeTextColor={'#166534'} textSize={'text-xs'} textWeight={'font-regular'} badgeWidth={'100px'} /></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="bg-white rounded-t-none rounded-bl-lg rounded-br-lg border-0 px-6 py-8 flex items-center justify-between border-t border-gray-200 sm:px-6">
                  <div className="flex-1 flex justify-between sm:hidden">
                    <Link
                      to="#"
                      className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Previous
                    </Link>
                    <Link
                      to="#"
                      className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Next
                    </Link>
                  </div>
                  <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                    <div>
                      <p className="text-sm text-gray-700">
                        Showing <span className="font-medium">1</span> to <span className="font-medium">10</span> of{' '}
                        <span className="font-medium">97</span> results
                      </p>
                    </div>
                    <div>
                      <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                        <Link
                          to="#"
                          className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        >
                          <span className="sr-only">Previous</span>
                          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                        </Link>
                        {/* Current: " bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                        <Link
                          to="#"
                          aria-current="page"
                          className=" bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                        >
                          1
                        </Link>
                        <Link
                          to="#"
                          className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                        >
                          2
                        </Link>
                        <Link
                          to="#"
                          className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
                        >
                          3
                        </Link>
                        <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                          ...
                        </span>
                        <Link
                          to="#"
                          className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
                        >
                          8
                        </Link>
                        <Link
                          to="#"
                          className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                        >
                          9
                        </Link>
                        <Link
                          to="#"
                          className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                        >
                          10
                        </Link>
                        <Link
                          to="#"
                          className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        >
                          <span className="sr-only">Next</span>
                          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                        </Link>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
  }
}


function VendorsFilterDropdown({ vendors, supplierFilter, currentSupplier }: { currentSupplier: any, vendors: any, supplierFilter: any }) {


  const parsedVendors = (vendors && vendors.length) ? vendors.map(vendor => {
    return {
      id: vendor._id,
      name: vendor.vendorDetail.label
    }
  }) : []
  parsedVendors.push({
    id: "",
    name: "All"
  })

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="group inline-flex justify-center text-sm font-medium text-secondary-500 hover:text-secondary-700">
          <span className="text-xs border border-dotted px-2 py-1 rounded cursor-pointer">+ Supplier</span>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-left z-10 absolute left-0 mt-2 w-52 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {parsedVendors.map((vendor) => (
              <Menu.Item key={vendor.id}>
                {/* <div className="flex items-center justify-between px-3 py-2 hover:bg-gray-100 cursor-pointer" onClick={supplierFilter(vendor.id)}> */}
                <div className="flex items-center justify-between px-3 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => { supplierFilter(vendor.id) }}>
                  <span
                    className={`${vendor.id === currentSupplier ? 'font-medium text-secondary-900' : 'text-secondary-500'}`}>
                    {vendor.name}
                  </span>
                  {vendor.id === currentSupplier &&
                    <span className="text-[#166534]">
                      {svgIcons.check}
                    </span>
                  }
                </div>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

interface ProductsListComponentProps {
  product: any
  addToCartListener: any
  location: any
  extUserId: string
  extStoreId: number
}
const ProductsListComponent: React.FC<ProductsListComponentProps> = ({ product, extStoreId, extUserId, location, addToCartListener }) => {
  // Hooks
  const navigate = useNavigate()
  // Reducers
  const { data: cartDatas, updateLoading } = useSelector(({ commonReducers }: any) => commonReducers.GetCartReducer)
  const { data: slugData } = useSelector(({ commonReducers }: any) => commonReducers.GetVendorsSlugReducer)
  //States
  const [quantity, setQuantity] = useState<number>(0)
  const commonClass = 'flex items-center justify-center whitespace-nowrap px-3 py-4 text-sm font-medium text-secondary-500 cursor-pointer'
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(updateLoading)
  const [loc, setLoc] = useState<any>()
  // Extract Data
  const pathChunks = location?.pathname?.substring(1).split("/")
  const currentVendor = pathChunks[1]
  const cartDataShort = cartDatas?.cartItems?.map(cart => cart?.item?._id)
  const alreadyInCart = currentVendor === '2ndlifephones' ? cartDataShort?.some(i => i === product?._id) : cartDataShort?.some(i => i === product?.id)
  const cartTotalQuantity = currentVendor === '2ndlifephones' ? cartDatas?.cartItems?.find(i => i?.item?._id === product?._id)?.quantity : cartDatas?.cartItems?.find(i => i?.item?._id === product?.id)?.quantity
  const ifOnVendorPage = useMatch({path: "/vendor/:slug/:id", end: true})
  const extVendorStore = slugData?.storeType;
  // Function
  const redirectToProductPage = (product) => {
    const productId = product?.id
    navigate(`/vendor/${currentVendor}/${productId}`)
    dataPoints(extUserId, 'Product_Clicked', extStoreId, `Marketplace | ${currentVendor} | Vendor Page`)
    window.scrollTo(0, 0)
  }
  const connectVendor = () => {
    AuthActions.authenticateVendor(product.vendor, 1)
    dataPoints(extUserId, 'Connect_Vendor', extStoreId, `Integrations | Marketplace | ${product?.vendor}`)
  }
  const handleAddToCart = (product: any) => {
    const alreadyAuthenticated = checkIfVendorConnected(product.vendor)
    if(extVendorStore === "shopify" && !alreadyAuthenticated){
      setIsModalVisible(false);
    }
    else if (!alreadyAuthenticated) {
      setIsModalVisible(true)
      return
    }
    setIsLoading(!updateLoading)
    const data = {
      quantity: quantity === 0 ? quantity + 1 : quantity,
      product
    }
    addToCartListener(data)
  }
  useEffect(() => {
    if (!updateLoading) {
      setIsLoading(false)
    }
  }, [updateLoading])
  useEffect(() => {
    setLoc(localStorage.getItem('Name'))
  },[localStorage.getItem('Name'), loc])
  return (
    <>
      <Modal open={isModalVisible} setOpen={setIsModalVisible} dialogTitle="Connection!" dialogMessage='Please connect to the vendor.' onClick={() => { setIsModalVisible(false); connectVendor()}}  onCancel={() => setIsModalVisible(false)} />
      <tr>
        {currentVendor=="2ndlifephones"
        ?
          <td className=" pl-4 pr-3 text-sm sm:pl-6">
            <div className="flex items-center cursor-pointer">
              <div className="ml-1" style={{ width: "610px" }}>
                <div className="text-sm font-medium text-secondary-800">{product.name}</div>
              </div>
            </div>
        </td>
        :
        <td className=" pl-4 pr-3 text-sm sm:pl-6">
          <Link to={`/vendor/${currentVendor}/${product?.id || product?._id}`}>
          <div className="flex items-center cursor-pointer" onClick={() => redirectToProductPage(product)}>
            <div className="h-10 w-10 flex-shrink-0">
                <img className="h-10 w-10 rounded-full" 
                src={ 
                (product?.imageUrls && product?.imageUrls?.length > 0) ? product?.imageUrls[0] : product?.imageSrc && product?.imageSrc?.length > 0 ? product?.imageSrc : PlaceholderImg} alt="" />
            </div>
            <div className="ml-4" style={{ width: "610px" }}>
              <div className="text-sm font-medium text-secondary-800">{product.name}</div>
            </div>
          </div>
          </Link>
        </td>
        }
        
        <td>
          <span className={`${commonClass}`}>
            <QuantityCounter productQuantity={product.qty} alreadyInCart={isLoading} onValueChange={(v) => setQuantity(v)} btnHeight="24px" btnWidth="24px" inputWidth="30px" inputHeight="26px" />
          </span>
        </td>
        <td className="whitespace-nowrap text-center" >
          <span className="text-sm font-medium text-green-600" >
          {loc === 'false' ? '$'+parseFloat(product?.price || product?.costPrice).toFixed(2) : '' }
          </span>
        </td>
        <td>
          {!isLoading ?
          <div className='relative'>
            {cartDatas?.cartItems?.length ? 
                  <div className="">
                    {alreadyInCart &&
                    <div className={`${currentVendor === '2ndlifephones' ? 'ml-5' : 'ml-8'} absolute text-xs flex items-center mt-1 justify-center text-white  h-5 w-5 rounded-full bg-red-500`}>{
                      cartTotalQuantity}</div>
                    }
                    </div>
                  :
                  ''
              }
              <span onClick={cartTotalQuantity === product?.qty || quantity > (product?.qty - cartTotalQuantity) || product?.qty === 0 ? () => {} : () => handleAddToCart(product)} className={commonClass}>
              {svgIcons.cart}
            </span>
          </div>
            : <img src={dots} width="25px" className='mx-auto' height={"25px"} alt="" />
          }
        </td>
      </tr>
    </>
  )
}


