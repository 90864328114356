import { Fragment } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

interface SkeletonProps {
  skeletonType: any
  defaultSkeletonHeight: string
  smallInputWidth: string
  smallInputHeight: string
}

const SkeletonLoader:React.FC<SkeletonProps> = ({skeletonType, defaultSkeletonHeight, smallInputWidth, smallInputHeight}) => {
  const skeletonData = [
    {count: 3},
    {count: 3},
    {count: 3}
  ]
  const productSkeletonData = [
    {count: 3},
    {count: 3},
    {count: 3},
    {count: 3}
  ]
  const SearchProductSkeletonData = [
    {count: 3},
    {count: 3},
    {count: 3},
  ]
  const filterCheckboxData = [
    {count: 3},
    {count: 3},
    {count: 3},
    {count: 3},
    {count: 3},
    {count: 3},
  ]
  const skeletonTableListView = (
    <div className="mt-4 w-[100%] h-16 bg-secondary-300 rounded-md border">
      <div className='flex justify-between mx-4 my-4'>
        <Skeleton width={"80px"} height={"20px"} count={1} className="" />
        <Skeleton width={"80px"} height={"20px"} count={1} className="" />  
        <Skeleton width={"80px"} height={"20px"} count={1} className="" />  
        <Skeleton width={"80px"} height={"20px"} count={1} className="" />  
      </div>
    </div>
  )
  switch(skeletonType) {
    case 'GridCardViewSkeleton':
      return(
        <Fragment>
            {skeletonData?.map((skeleton:any) => (
              <div className='w-full h-48 border-2 border-secondary-200 rounded p-4'>
                <div className='w-16'><Skeleton height={60} circle={true} /></div>
                <div className='mt-4'><Skeleton baseColor='#ebebeb' highlightColor='#f5f5f5' count={skeleton.count} /></div>
              </div>   
            ))}
          </Fragment>
      )
      case 'VendorListViewSkeleton':
      return(
        <Fragment>
          {skeletonData?.map((skeleton:any) => (
            <div className='border-2 border-secondary-300 p-4 mb-4 flex justify-between'>
            <SkeletonTheme baseColor='#ebebeb' highlightColor='#f5f5f5' inline={true}>
              <p>
                <Skeleton circle={true} width={30} height={30} className="mr-3" />
                <Skeleton width={150} height={25} count={1} />
              </p>
              <p><Skeleton width={150} height={25} count={1} className="ml-10" /></p>
            </SkeletonTheme>
            </div>
          ))}
          </Fragment>
      )
      case 'ProductCardSkeleton':
        return(
          <Fragment>
            {productSkeletonData?.map((skeleton:any) => (
              <div className='w-full flex flex-wrap justify-between'>
              <SkeletonTheme baseColor='#ebebeb' highlightColor='#f5f5f5' inline={true}>
                <p>
                  <Skeleton width={346} height={362} count={1} />
                </p>
              </SkeletonTheme>
              </div>
            ))}
            </Fragment>
        )
        case 'SearchProductSkeleton':
          return(
            <Fragment>
              {SearchProductSkeletonData?.map((searchskeleton:any) => (
                <div className='w-full flex flex-wrap justify-between'>
                <SkeletonTheme baseColor='#ebebeb' highlightColor='#f5f5f5' inline={true}>
                  <p>
                    <Skeleton width={346} height={362} count={1} />
                  </p>
                </SkeletonTheme>
                </div>
              ))}
              </Fragment>
          )
        case 'SubHeaderCategorySkeleton':
          return(
            <Fragment>
              {productSkeletonData?.map((skeleton:any) => (
                <div className='w-full flex flex-wrap justify-between pr-6'>
                <SkeletonTheme baseColor='#ebebeb' highlightColor='#f5f5f5' inline={true}>
                  <p>
                    <Skeleton width={100} height={32} count={1} />
                  </p>
                </SkeletonTheme>
                </div>
              ))}
              </Fragment>
          )
        case 'smallInputSkeleton':
          return(
            <Skeleton width={smallInputWidth} height={smallInputHeight} count={1} />
          )
          case 'slideOverCartSkeleton':
            return(
              <div className='h-26 border-2 rounded-md mt-12 border-secondary-300 p-4 mb-4 flex items-center justify-between'>
                <SkeletonTheme baseColor='#ebebeb' highlightColor='#f5f5f5'>
                  <p className='flex'>
                    <Skeleton width={80} height={80} className="mr-3" />
                    <p className='flex flex-col justify-between'>
                      <Skeleton width={120} height={30} count={1} />
                      <Skeleton width={49} height={20} count={1} />
                    </p>
                  </p>
                  <p className='flex flex-col items-end justify-between'>
                    <Skeleton width={25} height={25} count={1} className="ml-10 mb-4" />
                    <p className='inline-flex'>
                    <Skeleton circle={true} width={30} height={30} count={1} className="ml-10" />
                    <Skeleton width={30} height={30} count={1} className="ml-3" />
                    <Skeleton circle={true} width={30} height={30} count={1} className="ml-3" />
                    </p>
                  </p>
                </SkeletonTheme>
              </div>
            )
            case 'filterSkeleton':
              return(
                <div>
                  <Skeleton width={"330px"} height={"600px"} count={1} />      
                </div>
              )
              case 'filterCheckboxSkeleton':
                return(
                  <Fragment>
                  {filterCheckboxData?.map((checkbox:any) => {
                    return(
                    <div className='flex'>
                      <Skeleton width={"20px"} height={"20px"} count={1} enableAnimation />
                      <Skeleton width={"100px"} className="ml-4" height={"20px"} count={1} enableAnimation />      
                    </div>
                  )
                  })}
                  </Fragment>
                )
                case 'TableBodySkeleton':
                  return(
                    <Fragment>
                    <div className='flex w-[100%] my-4'>
                      <Skeleton width={"100px"} height={"20px"} count={1} className="ml-24" />
                      <Skeleton width={"100px"} height={"20px"} count={1} className="ml-24" />  
                      <Skeleton width={"100px"} height={"20px"} count={1} className="ml-24" />  
                      <Skeleton width={"100px"} height={"20px"} count={1} className="ml-24" />  
                      <Skeleton width={"100px"} height={"20px"} count={1} className="ml-24" />      
                      <Skeleton width={"100px"} height={"20px"} count={1} className="ml-24" />  
                      <Skeleton width={"100px"} height={"20px"} count={1} className="ml-24" />  
                    </div>
                    <div className='flex w-[100%] my-4'>
                      <Skeleton width={"100px"} height={"20px"} count={1} className="ml-24" />
                      <Skeleton width={"100px"} height={"20px"} count={1} className="ml-24" />  
                      <Skeleton width={"100px"} height={"20px"} count={1} className="ml-24" />  
                      <Skeleton width={"100px"} height={"20px"} count={1} className="ml-24" />  
                      <Skeleton width={"100px"} height={"20px"} count={1} className="ml-24" />      
                      <Skeleton width={"100px"} height={"20px"} count={1} className="ml-24" />  
                      <Skeleton width={"100px"} height={"20px"} count={1} className="ml-24" />  
                    </div>
                    <div className='flex w-[100%] my-4'>
                      <Skeleton width={"100px"} height={"20px"} count={1} className="ml-24" />
                      <Skeleton width={"100px"} height={"20px"} count={1} className="ml-24" />  
                      <Skeleton width={"100px"} height={"20px"} count={1} className="ml-24" />  
                      <Skeleton width={"100px"} height={"20px"} count={1} className="ml-24" />  
                      <Skeleton width={"100px"} height={"20px"} count={1} className="ml-24" />      
                      <Skeleton width={"100px"} height={"20px"} count={1} className="ml-24" />  
                      <Skeleton width={"100px"} height={"20px"} count={1} className="ml-24" />  
                    </div>
                    </Fragment>
                  )
                  case 'TableListViewSkeleton':
                    return (
                      <Fragment>
                        <div>
                        {skeletonTableListView}
                        {skeletonTableListView}
                        {skeletonTableListView}
                        </div>
                      </Fragment>
                    )
                    case 'PaginationSkeleton':
                      return(
                        <div className='flex justify-between'>
                          <Skeleton width={smallInputWidth} height={smallInputHeight} count={1} />
                          <Skeleton width={smallInputWidth} height={smallInputHeight} count={1} />
                          <Skeleton width={smallInputWidth} height={smallInputHeight} count={1} />
                          <Skeleton width={smallInputWidth} height={smallInputHeight} count={1} />
                          <Skeleton width={smallInputWidth} height={smallInputHeight} count={1} />
                        </div>
                      )
      default:
        return(
          <Fragment>
            <Skeleton width={"100%"} height={defaultSkeletonHeight} count={1} />
          </Fragment>
        )
  }
}

export default SkeletonLoader