import React from 'react'
import { PlusSmIcon as PlusSmIconOutline } from '@heroicons/react/outline'
import { MinusSmIcon as MinusSmIconOutline } from '@heroicons/react/outline'
import dots from '../../assets/images/3dot primary.svg'

interface ButtonInterface {
    btnType?: string
    btnHeight?: string
    btnWidth?: string
    defaultBtnWidth?: string
    defaultBtnHeight?: string
    defaultBtnText?: string
    onClick?: any
    alreadyInCard?: boolean
    isLoading?: boolean
    quantity?: any
    defaultBgColor?: string
    disabled?: boolean
}

export const Button: React.FC<ButtonInterface> = ({btnType, btnHeight, btnWidth, defaultBtnWidth, defaultBtnHeight, defaultBtnText, onClick, alreadyInCard, isLoading, quantity, disabled, defaultBgColor}) => {
    
    // Button Type
    const incrementButton = <PlusSmIconOutline className="h-5 w-5" aria-hidden="true" />
    const decrementButton = <MinusSmIconOutline className="h-5 w-5" aria-hidden="true" />
    const addToCart = "Add to cart"
    // <img src={dots} width="25px" className='mx-auto' height={"25px"} alt="" />

    // CSS Variables
    const btn_productCard_width = "100%"
    const btn_productCard_height = "38px"

    switch(btnType) {
        case 'increment':
            return (
                <button
                type="button"
                className="inline-flex items-center p-2 border border-secondary-200 rounded-full text-primary-600 hover:bg-secondary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
                style={{width: btnWidth, height: btnHeight}}
                >
                    {incrementButton}
                </button>
            )
        case 'decrement':
            return (
                <button
                type="button"
                className="inline-flex items-center p-2 border border-secondary-200 rounded-full text-primary-600 hover:bg-secondary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
                style={{width: btnWidth, height: btnHeight}}
                >
                    {decrementButton}
                </button>
            )
        case 'ProductCardBtn':
            return (
                !isLoading ?
                <button
                onClick={onClick}
                type="button"
                className={`bg-secondary-100 border mx-4 border-transparent rounded-md py-2 px-8 items-center justify-center text-sm font-medium text-secondary-800  ${!alreadyInCard ? 'hover:bg-secondary-200' : 'opacity-70'} ${!alreadyInCard ? 'cursor-pointer' : 'cursor-not-allowed'} `}
                style={{width:btn_productCard_width, height:btn_productCard_height}}
                >
                    {addToCart}
                </button>
                :
                <img src={dots} width="25px" className='mx-auto' height={"25px"} alt="" />
            )
        case 'ProductCardLargeBtn':
        return (
            !isLoading ?
            <button
            onClick={onClick}
            type="button"
            className={`bg-primary-600 border border-transparent rounded-md py-2 px-8 items-center justify-center text-sm font-medium text-white  ${!alreadyInCard ? 'hover:bg-primary-700' : 'opacity-70'} ${!alreadyInCard ? 'cursor-pointer' : 'cursor-not-allowed'} `}
            style={{width:btnWidth, height:btnHeight}}
            >
                {addToCart}
            </button>
            :
            <div className='w-80'>
            <img src={dots} width="40px" className='mx-auto' height={"40px"} alt="" />
            </div>
        )
        case 'SecondaryButton':
            return (
                <button
                onClick={onClick}
                type="button"
                className={`inline-flex cursor-pointer justify-center items-center px-6 py-3 ${defaultBgColor}`}
                style={{width: defaultBtnWidth, height: defaultBtnHeight}}
                >
                    {defaultBtnText}
                </button> 
            )
        default:
            return (
                <button
                onClick={onClick}
                type="button"
                className={`inline-flex cursor-pointer justify-center items-center px-6 py-3 border border-transparent text-base font-medium rounded-md ${defaultBgColor}`}
                disabled={disabled}
                style={{width: defaultBtnWidth, height: defaultBtnHeight}}
                >
                    {defaultBtnText}
                </button> 
            )
    }
}
