import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, Fragment, useState } from "react";
import * as WishListActions from "store/actions";
import { useLocation, useNavigate } from "react-router";
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { svgIcons } from '../../assets/svg/index'
import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader'
import Spinner from '../../assets/images/3dot primary.svg'
import PlaceholderImg from '../../assets/images/placeholder-image.png'
import { openNotificationWithIcon } from "components/Notification";
import { dataPoints } from "utils/CommonFunctions";

interface WishlistProps {
  show: any
  onClose: any
  extUserId: string
  currentVendor: string
  extStoreId: number
}

const WishList: React.FC<WishlistProps> = ({show, extUserId, extStoreId, currentVendor, onClose}) => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Reducer
  const wishlistState = useSelector(
    (state: any) => state.commonReducers.WishListReducer.state
  );
  const { data: wishListData, isLoading, ...wishlistRest } = useSelector(
    (state: any) => state.commonReducers.GetWishListReducer
  );
  // useEffect
  useEffect(() => {
    wishlistState && dispatch(WishListActions.getWishList());
  }, [dispatch, wishlistState]);

  const onButtonClick = (item) => {
    let redirectUrl = `/vendor/${item?.vendorId?.name}/${item._id}`;
    onClose()
    navigate(redirectUrl);
    dataPoints(extUserId, 'Product_Opened ', extStoreId, `Marketplace | Navbar | Wishlist`)
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-hidden" onClose={onClose}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto max-w-lg" style={{ width: "455px" }}>
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="flex-1 overflow-y-auto py-6 px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-semibold text-secondary-700">Wishlist</Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="-m-2 p-2 text-secondary-400 hover:text-secondary-500"
                          onClick={onClose}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>

                    <div className="mt-8">
                      <div className="flow-root">
                        <ul className="-my-6">
                          {
                            wishListData !== "null" ? (wishListData && wishListData?.items?.length > 0) ?
                            <Fragment>
                              {wishListData?.items?.map((item) => {
                                return (
                                  <CartProductItem updateCartListener={undefined} isLoading={isLoading === item._id} item={item} wishlistRest={wishlistRest} onButtonClick={onButtonClick} />
                                )
                              }
                              )}
                            </Fragment>
                            :
                            <div className='pt-53 text-center'>No Item in the wishlist!</div>
                            :
                            <SkeletonLoader skeletonType={'slideOverCartSkeleton'} defaultSkeletonHeight={''} smallInputWidth={''} smallInputHeight={''} />
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}



interface ICartProductItem {
  item: any
  wishlistRest: any
  updateCartListener: any
  isLoading: boolean
  onButtonClick: any
}

const CartProductItem: React.FC<ICartProductItem> = ({ item, updateCartListener, onButtonClick, wishlistRest, isLoading }) => {
  
  // Hooks
  const location = useLocation()
  const dispatch = useDispatch()
  // States
  const commonClass = "text-sm font-medium"
  const [loader, setLoader] = useState(isLoading)
  // useEffect
  const removeFromWishList = (item, action) => {
    setLoader(!loader)
    dispatch(WishListActions.updateWishList(item._id, action, () => {
    setLoader(false)
    openNotificationWithIcon("error", `Item removed from Wishlist!`,'')
    }));
  };
  return (
    <Fragment>
    
    <li key={item._id} className={` flex-1 pt-53 pb-5 border-b border-secondary-300`}>
      <div className={`${loader ? 'flex opacity-50' : 'flex'}`}>
        <div className="h-20 w-20 flex-shrink-0 overflow-hidden rounded-md border-secondary-200">
          <img src={item?.imageUrls?.length ? item?.imageUrls : PlaceholderImg} alt={""} className="h-full w-full rounded-md object-cover object-center" />
        </div>
        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className={`flex justify-between ${commonClass} text-secondary-700`}>
              <p>
                {item.name}
              </p>
              <span onClick={() => removeFromWishList(item,0)} className="cursor-pointer ml-8">{svgIcons.heartRed}</span>
            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-sm">
            {/* <p className={`${commonClass} text-green-600`}>${(product.costPrice * quantity).toFixed(2)}</p> */}
            <p className={`${commonClass} ${location.pathname === '/' ? 'bg-secondary-100 shadow select-none text-transparent' : ''} text-green-600`}>${item.costPrice}</p>
            <div className="flex" onClick={() => onButtonClick(item)}>
              <p className="text-sm font-medium text-secondary-500 hover:text-primary-600 cursor-pointer">View Product</p>
            </div>
          </div>
        </div>
        </div>
    </li>
    {loader ? <div className='absolute z-10 top-0 flex items-center w-full h-full'><img className='mx-auto pr-8' src={Spinner} alt="" /></div> : ''}  
    </Fragment>
  );
};

export default WishList;
