import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { svgIcons } from '../../assets/svg'
import { Input } from '../Input/Input'
interface ButtonInterface {
  btnHeight?: string
  btnWidth?: string
  inputHeight?: string
  inputWidth?: string,
  onValueChange?: any
  value?: any
  productQuantity?: number
  alreadyInCart?: any
}


export const QuantityCounter: React.FC<ButtonInterface> = ({ btnHeight, btnWidth, inputHeight, value, inputWidth, onValueChange, productQuantity, alreadyInCart }) => {
  
  // States
  const [firstLoad, setFirstLoad] = useState<boolean>(true)
  const [itemsCount, setItemsCount] = useState<number>(value || 0)
  const {state} = useSelector(({ commonReducers }: any) => commonReducers.CartReducer)
  const cartOpen = state
  // Functions
  const handleValueChange = (e) => {
    const v = (e.target.value < 0 || e.target.value === "") ? '' : e.target.value
    // only update update value until its less than max product quantity
    if (v < (productQuantity || 1)) {
      setItemsCount(parseInt(v))
    }
  }
  const handleInc = () => {
    if (itemsCount < (productQuantity || 0)) {
      setItemsCount(v => +v + 1)
    }
  }
  const handleDec = () => {
    if (itemsCount <= 0) return
    setItemsCount(v => +v - 1)
  }
  // useEffect
  useEffect(() => {
    if (!firstLoad) {
      if (itemsCount) {
        onValueChange(itemsCount)
      }
      else {
        onValueChange(0)
      }
    }
    setFirstLoad(false)
  }, [itemsCount])
  useEffect(() => {
    if(alreadyInCart){
      setItemsCount(0)
    }
  },[alreadyInCart]) // set quantity 0 when addToCart Button triggered

  return (
    <div className="flex">
      <button onClick={handleDec} className='border rounded-full flex justify-center items-center cursor-pointer' style={{ height: btnHeight, width: btnWidth }}>
        {svgIcons.decrement}
      </button>
      <div className='mx-2'>
        <Input type="number" cartOpen={cartOpen} inputHeight={inputHeight} inputWidth={inputWidth} value={itemsCount} onchange={handleValueChange} />
      </div>
      <button onClick={handleInc} className='border rounded-full flex justify-center items-center cursor-pointer' style={{ height: btnHeight, width: btnWidth }}>
        {svgIcons.increment}
      </button>
    </div>
  )
}
