import { REDIRECT_CONFIG_URL } from "config";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const useCurrentRedirectLocation = () => {
  const [url, setUrl] = useState<string>("");
  const { data }: any = useSelector(
    ({ autReducer }: any) => autReducer.GetUserReducer
  );

  const rdData = data?.data?._doc?.subdomain ||REDIRECT_CONFIG_URL;//"www.google.com"// data?.data?._doc?.subdomain;

  useEffect(() => {
    setUrl(String(rdData));
  }, [rdData]);

  return url;
};

export default useCurrentRedirectLocation;
