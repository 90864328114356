import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import './index.css';
import store from "store/index";
import BaseLayout from "layout";
import Home from "main/Home/index";
import Auth from "auth";
import { OrderPage } from "main";
import SearchPage from "main/SearchResultPage";
import VendorHomePage from "main/VendorHomePage";
import ProductPage from "main/ProductPage/ProductPage";
// import ProductPage from "main/ProductPage/ProductPage";
// import SeeAllProductsPage from "main/SeeAllProductsPage/SeeAllProductsPage";
import ErrorPage from "main/404";
import CheckoutModal from "components/Modal/CheckoutModal";

const VendorSpecificRoute = "/vendor/:slug"
const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Router>
        <Auth>
          <BaseLayout>
            <Routes>
              <Route path="/" element={<Home />} />;
              <Route path={VendorSpecificRoute} element={<VendorHomePage />} />;
              <Route path={VendorSpecificRoute + "/:id"} element={<ProductPage />} />
              {/* <Route path={VendorSpecificRoute + "/seeAll"} element={<SeeAllProductsPage />} />; */}
              <Route path="/orders" element={<OrderPage />} />
              <Route path={VendorSpecificRoute + "/search/:id"} element={<SearchPage />} />;
              <Route
                path="/checkoutcompleted"
                element={<CheckoutModal />}
              />
              ;
              <Route path="*" element={<PageNotFound />} />;
            </Routes>
          </BaseLayout>
        </Auth>
      </Router>
    </Provider>
  );
};

export default App;

let PageNotFound = () => {
  return <ErrorPage />
};
