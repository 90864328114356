import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";
import SkeletonLoader from "components/SkeletonLoader/SkeletonLoader";

interface sliderImg {
  slides: any
  vendorSlugLoading: any
}

export const Carousel: React.FC<sliderImg> = ({slides, vendorSlugLoading}) => {
  return (
    <div className="relative mt-6 mx-6">
      {!vendorSlugLoading ?
      <Swiper autoplay={true} navigation={true} modules={[Navigation]} slidesPerView={1} spaceBetween={30} loop={true} className="mySwiper" >
        {slides?.map((slide:any, index: number) => (
          <SwiperSlide key={index}><img src={slide?.img || slide?.imgUrl} className="w-full h-[384px] rounded object-fill" alt="banner img" /></SwiperSlide>
        ))}
      </Swiper>
      :
      <SkeletonLoader skeletonType={'default'} defaultSkeletonHeight={"384px"} smallInputWidth={""} smallInputHeight={""} />
      }
    </div>
  )
}