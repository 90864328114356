 import { useNavigate } from "react-router-dom"
import { Card } from "../../components/Card/Card"
// import { SearchField } from "../../components/SearchField/SearchField"
import Banner from "../../assets/images/Marketplace-banner.gif"
import { svgIcons } from "../../assets/svg"
import { useEffect, useState } from "react"
import withReducer from "store/withReducer"
import reducer from "./components/store/reducers"
import { useDispatch, useSelector } from "react-redux"
import { fetchTicker } from "./components/store/actions"

const Home = () => {
  // Hooks
  const [isCard, setIsCard] = useState(false)
  const [, setCardType] = useState(isCard ? 'VendorListingCard' : 'VendorGridLayout');
  const dispatch = useDispatch();

  // reducers
  const { data, isLoading } = useSelector(                                                                    // Vendor Reducer
    ({ commonReducers }: any) => commonReducers.GetVendorsReducer
  );
  // const {data:getTicker, isLoading:tickerLoading} = useSelector(({tickers}:any)=>tickers.GetTickersReducer); // Ticker Reducer

  // useEffect
  useEffect(() => {
    dispatch(fetchTicker())
}, [dispatch]);

  // Function
  const changeView = () => {
    setIsCard(!isCard)
    setCardType('VendorGridLayout')
  }
  // CSS
  const width = '730px';

  return (
    <div className={`w-full 3xl:w-10/12 mx-auto 4xl:w-7/12`}>
      {/* Home Top Section */}
      <div className={`flex flex-col items-center h-52 mt-[72px] mx-[auto] mb-[25px]`} style={{ maxWidth: width }}>
        <div className="flex text-5xl text-secondary-700 font-semibold" style={{minWidth: "auto", maxWidth: width}}>All your <span className="text-primary-600 mx-3">vendors</span>in one place<span className="ml-2 lg:block sm:hidden"><img src="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/160/apple/325/star_2b50.png" alt=""width="48px" height="48px" /></span></div>
        <p className="text-lg text-center mt-6 text-secondary-500" style={{width: "640px"}}>Marketplace is a one stop solution for repair parts and devices. Import from us to get your inventory managed. Order from us and never worry about creating items and purchase orders manually!
        </p>
        {/* <div className="mt-6">
          <SearchField searchType={"HomeSearch"} width={""} height={""} navigate={navigate} onSearchValueChange={undefined} setSearchValue={undefined} focus={false} />
        </div> */}
      </div>
      {/* Home Banner */}
      <div className="flex justify-between xl:flex-nowrap xl:space-x-6 xl:space-y-0 lg:flex-wrap lg:space-y-6  sm:flex-wrap sm:space-x-0 sm:space-y-6 mx-6 mt-18 mb-8">
            <div className="h-72 w-full">
              <img src={Banner} alt="" className="h-72 w-full object-fill rounded-lg"   />
          </div>
      </div>
      {/* Stats Cards */}
      {/* <div className="flex xl:flex-nowrap sm:flex-wrap lg:gap-x-6 lg:flex-wrap sm:gap-y-6 my-6 mx-6">
        <Card CardData={CardData} VendorData={() => { } } cardType="default" cardHeight="136px" ProductCardData={undefined} tickerLoading={tickerLoading} onClick={undefined} isLoading={false} vendorLoading={false} productsLoading={false} RedirectTo={undefined} slug={""} searchName={undefined} />
      </div> */}
      {/* Preferred Vendor Section */}
      <div className="mb-16 mx-6">
        <div className="flex items-center justify-between">
          <p className="text-2xl font-semibold text-secondary-700">
            Vendors
          </p>
          <span onClick={changeView}>{svgIcons.listIcon}</span>
        </div>
        <div className="my-4 space-y-4">
          <Card CardData={() => { } } VendorData={data?.data} cardType={isCard ? 'VendorListingCard' : 'VendorGridLayout'} cardHeight="104px" ProductCardData={undefined} tickerLoading={false} onClick={undefined} isLoading={isLoading} vendorLoading={false} productsLoading={false} RedirectTo={undefined} slug={""} searchName={undefined} isShopifyLoading={false} searchProduct={undefined} />
        </div>
      </div>
    </div>
  )
}
export default withReducer("tickers", reducer)(Home);
