import * as Actions from "../actions";

const initialState = {
  isLoading: false, errMsg: null, data: null, success: ''
};





export const GetUserReducer = function (state: any = initialState, action) {
  switch (action.type) {
    case Actions.GET_USER:
      return { ...state, isLoading: false, errMsg: null, data: action.payload, success: true }
    case Actions.USER_IS_lOADING:
      return { ...state, isLoading: true, errMsg: null, data: [], success: false }
    case Actions.USER_FAILED:
      return { ...state, isLoading: false, errMsg: action.payload, data: [], success: 'failed' }
    default:
      return state;
  }
};


export const AuthenticateVendorReducer = function (state: any = initialState, action) {
  switch (action.type) {
    case Actions.AUTHENTICATE_VENDOR:
      return { ...state, isLoading: false, errMsg: null, data: action.payload, success: true }
    case Actions.AUTHENTICATE_VENDOR_IS_lOADING:
      return { ...state, isLoading: true, errMsg: null, data: [], success: false }
    case Actions.AUTHENTICATE_VENDOR_FAILED:
      return { ...state, isLoading: false, errMsg: action.payload, data: [], success: 'failed' }
    default:
      return state;
  }
};

