import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader'
import React, { Fragment } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { svgIcons } from '../../assets/svg'
import { Badges } from '../Badges/Badges'
import PlaceholderImg from "../../assets/images/placeholder-image.png"
import { dataPoints } from 'utils/CommonFunctions'
import { useSelector } from 'react-redux'

interface VendorListingCardProps {
    VendorData: any
    cardHeight: string
    isLoading: boolean
}

export const VendorListingCard:React.FC<VendorListingCardProps> = ({VendorData, cardHeight, isLoading}) => {
  // Hooks
  const navigate = useNavigate()
  // Reducer
  const { data }: any = useSelector(({ autReducer }: any) => autReducer.GetUserReducer)
  // Extract Data
  const extUserId = data?.data?._doc?.rdUser
  const extStoreId = data?.data._doc?.storeId
  // Function
  const RedirectToVendorPage = (data) => {
    if(data?.isVisible === true){
    navigate(`/vendor/${data?.name}`)
    dataPoints(extUserId, 'Vendor_Clicked', extStoreId, `Marketplace | ${data?.name} `)
    }
    else {
      return ""
    }
    window.scrollTo(0,0)
  }
  return (
    <Fragment>
      {!isLoading ? VendorData?.map((data:any) => (
        <div className="bg-white flex justify-between w-full items-center rounded-lg cursor-pointer hover:opacity-80" onClick={() => RedirectToVendorPage(data)} style={{height: cardHeight}}>
          <div className="flex items-center space-x-3 ml-6">     
              <div className="flex-shrink-0 border p-1.5 border-secondary-300 rounded-md w-18 h-18">
                <img
                  className="h-12 w-12 rounded-full object-contain"
                  src={data.logo || PlaceholderImg}
                  alt=""
                />
              </div>
              <div className="min-w-0 flex-1">
                <p className="text-lg font-medium text-secondary-700">
                  <Link to="/">
                    {data.label}
                  </Link>
                </p>
                <p className="text-sm text-secondary-500">
                  <Link to="/">
                    {data.description.length > 100 ? data.description.slice(0,100) + "..." : data.description}
                  </Link>
                </p>
              </div>
          </div>
          {/* External Link */}
          <div className="flex items-center mr-8">
            {data?.isVisible === false ?
            <span className="mx-4"><Badges badgeBgColor="#DCFCE7" badgeTextColor="#166534" badgeContent="Coming Soon" textSize={'text-sm'} textWeight={'font-medium'} badgeWidth={'130px'} /></span>
            : ''
            }
            {svgIcons.arrowUpRight}
          </div>
      </div>
      ))
      :
      <div className='flex-1'><SkeletonLoader skeletonType={'VendorListViewSkeleton'} defaultSkeletonHeight={''} smallInputWidth={''} smallInputHeight={''} /></div> // Skeleton Loader 
      }
    </Fragment>
  )
}
