import { getData, postData } from "services/apiService";
import axios from "axios";
// Hide Cart
export const HIDE_WISHLIST = "HIDE_WISHLIST";
export function hideWishList() {
  return {
    type: HIDE_WISHLIST,
  };
}

// Show Cart
export const SHOW_WISHLIST = "SHOW_WISHLIST";
export function showWishList() {
  return {
    type: SHOW_WISHLIST,
  };
}
/////////////////////////

export const GET_WISHLIST = "GET_WISHLIST";
export const WISHLIST_IS_lOADING = "WISHLIST_IS_lOADING";
export const WISHLIST_FAILED = "WISHLIST_FAILED";

export const getWishListIsLoading = () => ({
  type: WISHLIST_IS_lOADING,
  payload: {},
});

export const getWishListFailed = (error) => ({
  type: WISHLIST_FAILED,
  payload: error,
});

export function getWishList() {
  const endPoint = `/cart/getWishList`;
  return (dispatch) => {
    dispatch(getWishListIsLoading());
    getData(endPoint)
      .then((response) => {
        dispatch({
          type: GET_WISHLIST,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch(getWishListFailed(error));
      });
  };
}
// //////////////////

export const UPDATE_WISHLIST = "UPDATE_WISHLIST";
export const ALTER_WISHLIST = "ALTER_WISHLIST";
export const UPDATE_WISHLIST_IS_lOADING = "UPDATE_WISHLIST_IS_lOADING";
export const UPDATE_WISHLIST_FAILED = "UPDATE_WISHLIST_FAILED";

export const updateWishListIsLoading = (id) => ({
  type: UPDATE_WISHLIST_IS_lOADING,
  payload: id,
});

export const updateWishListFailed = (error) => ({
  type: UPDATE_WISHLIST_FAILED,
  payload: error,
});

export function updateWishList(item, action, cb) {
  let body = { item, action: action === 1 ? true : false };
  const endPoint = `/cart/alterWishList`;

  return (dispatch) => {
   postData(endPoint, body)
    .then((response) => {
        dispatch(updateWishListIsLoading(body.item));
        dispatch({
          type: ALTER_WISHLIST,
          payload: response,
        });
        cb()
      })
      .catch((error) => {
        dispatch(updateWishListFailed(error));
      });
  };
}
