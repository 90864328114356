import { openNotificationWithIcon } from "components/Notification";
export const HIDE_MESSAGE = "[MESSAGE] CLOSE";
export const SHOW_MESSAGE = "[MESSAGE] SHOW";

export function hideMessage() {
  return {
    type: HIDE_MESSAGE,
  };
}

export function showMessage(options) {
  openNotificationWithIcon(options.type, "", options.message);
  return {
    type: SHOW_MESSAGE,
    options,
  };
}
