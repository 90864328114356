import * as Actions from "../../actions/common/index";

const initialState = {
  state: false,
  isLoading: false,
  errMsg: null,
  data: null,
  success: "",
};

export const WishListReducer = function (state = false, action) {
  switch (action.type) {
    case Actions.SHOW_WISHLIST: {
      return {
        state: true,
      };
    }
    case Actions.HIDE_WISHLIST: {
      return {
        state: false,
      };
    }
    default: {
      return state;
    }
  }
};
/////////////////
export const GetWishListReducer = function (state = initialState, action) {
  switch (action.type) {
      case Actions.UPDATE_WISHLIST_IS_lOADING:
        return {
          ...state,
          updateLoading: action.payload,
          isLoading: false,
          errMsg: null,
          data: null,
          success: false,
        };
    case Actions.ALTER_WISHLIST:  
      return {
        ...state,
        isLoading: false,
        errMsg: null,
        data: action.payload.data,
        updateLoading: false,
        success: true,
      };
    case Actions.GET_WISHLIST:
      return {
        ...state,
        isLoading: false,
        errMsg: null,
        data: action.payload.data,
        updateLoading: false,
        success: true,
      };
      case Actions.WISHLIST_IS_lOADING:
        return {
          ...state,
          isLoading: true,
          updateLoading: false,
          errMsg: null,
          data: null,
          success: false,
        };
    case Actions.WISHLIST_FAILED:
      return {
        ...state,
        isLoading: false,
        errMsg: action.payload,
        updateLoading: false,
        data: null,
        success: "failed",
      };
    default:
      return state;
  }
};
/////////////////
export const UpdateWishListReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.UPDATE_WISHLIST:
      return {
        ...state,
        isLoading: false,
        errMsg: null,
        data: action.payload.data,
        success: true,
      };
    case Actions.UPDATE_WISHLIST_IS_lOADING:
      return {
        ...state,
        isLoading: action.payload,
        errMsg: null,
        data: null,
        success: false,
      };
    case Actions.UPDATE_WISHLIST_FAILED:
      return {
        ...state,
        isLoading: false,
        errMsg: action.payload,
        data: null,
        success: "failed",
      };
    default:
      return state;
  }
};
