import { getData, postData } from "services/apiService";

export const GLOBAL_SEARCH = "GLOBAL_SEARCH";
export const GLOBAL_SEARCH_LOADING = "GLOBAL_SEARCH_LOADING";
export const GLOBAL_SEARCH_FAILED = "GLOBAL_SEARCH_FAILED";
export const SET_GLOBAL_SEARCH_KEY_WORDS = "SET_GLOBAL_SEARCH_KEY_WORDS";
// HELPER ACTIONS CREATORS
//************************************** /

export const getGlobalSearchLoading = () => {

  return ({
    type: GLOBAL_SEARCH_LOADING,
    payload: {},
  })
};
export const setGlobalKeyWords = (payload) => {
  return ({
    type: SET_GLOBAL_SEARCH_KEY_WORDS,
    payload: payload,
  })
};

export const getGlobalSearchFailed = (error) => ({
  type: GLOBAL_SEARCH_FAILED,
  payload: error,
});

export function 
getGlobalSearch(name) {
  const endPoint = `/search/global?keyword=${name}`

  return dispatch => {
    dispatch(getGlobalSearchLoading());

    getData(endPoint)
      .then((response) => {
        dispatch({
          type: GLOBAL_SEARCH,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch(getGlobalSearchFailed(error));
      });
  };
}

// By Vendors
export const GLOBAL_VENDOR_SEARCH = "GLOBAL_VENDOR_SEARCH";
export const GLOBAL_VENDOR_SEARCH_LOADING = "GLOBAL_VENDOR_SEARCH_LOADING";
export const GLOBAL_VENDOR_SEARCH_FAILED = "GLOBAL_VENDOR_SEARCH_FAILED";
export const SET_GLOBAL_VENDOR_SEARCH_KEY_WORDS = "SET_GLOBAL_VENDOR_SEARCH_KEY_WORDS"

// HELPER ACTIONS CREATORS
//************************************** /

export const getGlobalVendorSearchLoading = () => {

  return ({
    type: GLOBAL_VENDOR_SEARCH_LOADING,
    payload: {},
  })
};

export const setGlobalVendorKeyWords = (payload) => {
  return ({
    type: SET_GLOBAL_VENDOR_SEARCH_KEY_WORDS,
    payload: payload,
  })
};

export const getGlobalVendorSearchFailed = (error) => ({
  type: GLOBAL_VENDOR_SEARCH_FAILED,
  payload: error,
});

export function getGlobalVendorSearch(vendor, name) {
   const endPoint = `/search/vendor/${vendor}?keyword=${name}` // it was for categories
  // const endPoint = `/search/filter-items/${vendor}?keyword=${name}`
  return dispatch => {
    dispatch(getGlobalVendorSearchLoading());

    getData(endPoint)
      .then((response) => {
        dispatch({
          type: GLOBAL_VENDOR_SEARCH,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch(getGlobalVendorSearchFailed(error));
      });
  };
}

export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCT_IS_lOADING = "GET_PRODUCT_IS_lOADING";
export const GET_PRODUCT_FAILED = "GET_PRODUCT_FAILED";

export const GET_ITEMS_CATEGORIES = 'GET_ITEMS_CATEGORIES'

// HELPER ACTIONS CREATORS
//************************************** /

export const getProductIsLoading = () => ({
  type: GET_PRODUCT_IS_lOADING,
  payload: {},
});

export const getProductFailed = (error) => ({
  type: GET_PRODUCT_FAILED,
  payload: error,
});

export function getProduct(productId) {
  const endPoint = `/items/fetchItem/${productId}`;

  return (dispatch) => {
    dispatch(getProductIsLoading());

    getData(endPoint)
      .then((response) => {
        dispatch({
          type: GET_PRODUCT,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch(getProductFailed(error));
      });
  };
}

export function getItemByCategories(categoryId, vendorId, limit, page) {
  const endPoint = `/items/fetchByCategory/?category=${categoryId}&vendor=${vendorId}&limit=${limit}&page=${page}`;

  return (dispatch) => {
    // dispatch(getProductIsLoading());

    getData(endPoint)
      .then((response) => {
        dispatch({
          type: GET_ITEMS_CATEGORIES,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch(getProductFailed(error));
      });
  };
}
