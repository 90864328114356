import * as Actions from "../../actions/common/index";

const initialState = {
  state: false,
  isLoading: false,
  errMsg: null,
  data: null,
  success: "",
};

const CartReducer = function (state = false, action) {
  switch (action.type) {
    case Actions.SHOW_CART: {
      return {
        state: action.payload.vendor,
      };
    }
    case Actions.HIDE_CART: {
      return {
        state: false,
      };
    }
    default: {
      return state;
    }
  }
};
/////////////////
export const GetCartReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.UPDATE_CART_IS_lOADING:
      state = {
        ...state,
        isLoading: false,
        updateLoading: action.payload,
        data: null,
        errMsg: null,
        success: true,
      };
      return state;
    case Actions.ALTER_CART:
      // const newState = {
      //   ...state,
      //   data: {
      //     ...state.data,
      //     cartItems: action.payload.cartItems,
      //   }
      //  };
      state = {
        ...state,
        isLoading: false,
        updateLoading: false,

        errMsg: null,
        data: {
          ...state.data,
          cartItems: action.payload.cartItems,
        },
        success: true,
      };
      return state;
    case Actions.GET_CART:
      // let newStateGet = {...state}
      state = {
        ...state,
        isLoading: false,
        updateLoading: false,
        errMsg: null,
        data: action.payload.data,
        success: true,
      };
      return state;

    case Actions.GET_CART_IS_lOADING:
      state = {
        ...state,
        isLoading: true,
        updateLoading: false,

        errMsg: null,
        // data: null,
        success: false,
      };
      return state;
    case Actions.GET_CART_FAILED:
      state = {
        ...state,
        isLoading: false,
        errMsg: action.payload,
        // data: null,
        updateLoading: false,

        success: "failed",
      };
      return state;
    default:
      return state;
  }
};
/////////////////
export const UpdateCartReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.UPDATE_CART:
      
      return {
        ...state,
        isLoading: false,
        errMsg: null,
        data: action.payload.data,
        success: true,
      };
    case Actions.UPDATE_CART_IS_lOADING:
      return {
        ...state,
        isLoading: action.payload,
        errMsg: null,
        data: null,
        success: false,
      };
    case Actions.UPDATE_CART_FAILED:
      return {
        ...state,
        isLoading: false,
        errMsg: action.payload,
        data: null,
        success: "failed",
      };
    default:
      return initialState;
  }
};

export default CartReducer;
