import { combineReducers } from "redux";
import message from "./message.reducer";
import CartReducer from "./cart.reducer";
import { GetCartReducer, UpdateCartReducer } from "./cart.reducer";
import { SearchItemsReducer } from "./search.reducers";
import {
  GetWishListReducer,
  UpdateWishListReducer,
  WishListReducer,
} from "./wishList.reducer";
import {
  GetVendorsReducer,
  GetVendorsSlugReducer,
  GetCategoryReducer,
  GetShopifySideMenuReducer
} from "./vendor.reducer";
// import { GetVendorsReducer } from "main/VendorHomePage/store/reducers/vendor.reducer";
const commonReducers = combineReducers({
  message,
  CartReducer,
  GetCartReducer,
  UpdateCartReducer,
  GetWishListReducer,
  UpdateWishListReducer,
  WishListReducer,
  GetVendorsReducer,
  GetVendorsSlugReducer,
  GetCategoryReducer,
  SearchItemsReducer,
  GetShopifySideMenuReducer,
});

export default commonReducers;
