import React, { useState } from 'react'
import "./Input.css"

interface InputInterface {
    inputHeight?: string
    inputWidth?: string
    type?: string
    onchange?: any
    value?: string | number
    cartOpen?: any
  }

export const Input : React.FC<InputInterface> = ({inputWidth, inputHeight, type, onchange, value, cartOpen})  => {
  return (
    <div>
            <input
            type={type || "text"}
            name="email"
            id="email"
            disabled={cartOpen}
            className={`text-center shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border border-secondary-300 border-dashed rounded-md`}
            value={value}
            style={{width: inputWidth, height: inputHeight}}
            onChange={onchange}
            />
    </div>
  )
}
