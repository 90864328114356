import { useEffect, useRef, useState } from 'react'
import { Disclosure } from '@headlessui/react'
import { MenuIcon, SearchIcon, XIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'
import * as AuthActions from "auth/store/actions"
import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader'
import Spinner from '../../assets/images/spinner.gif'
import { useDispatch, useSelector } from 'react-redux'
import { svgIcons } from 'assets/svg'
import { Badge, Tooltip } from 'antd'
import { dataPoints } from 'utils/CommonFunctions'
import { useNavigate } from 'react-router'
import ConnectAnimation from "assets/images/connectAnimation.gif"
interface HeaderProps {
  logo: string
  currentVendor: any
  vendorSlugLoading: boolean
  vendorConnected: boolean
  storeType: string
  extUserId: string
}

export const SubHeader: React.FC<HeaderProps> = ({ logo, extUserId, vendorSlugLoading, currentVendor, vendorConnected, storeType }) => {

  // Hooks
  const [isIcon, setIsIcon] = useState(true)
  const [openSearch, setOpenSearch] = useState(false)
  const [selected, setSelected] = useState()
  const [found,setFound] = useState("")
  const [connectLoader, setConnectLoader] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // Reducers
  const { data: categoryTreeData, isLoading: categoryTreeLoading } = useSelector(
    ({ commonReducers }: any) => commonReducers.GetCategoryReducer
  );
  const { data }: any = useSelector(({ autReducer }: any) => autReducer.GetUserReducer)
  // States
  const [opene, setOpen] = useState<any>(false);
  // Category Length
  const categorySlice = categoryTreeData?.slice(0,12)
  const extStoreId = data?.data._doc?.storeId

  // Function
  const searchFunction = () => {
    setOpenSearch(!openSearch)
    setIsIcon(!isIcon)
  }
  const buttonRef = useRef<HTMLButtonElement>(null)
  const connectVendor = () => {
    setConnectLoader(true)
    AuthActions.authenticateVendor(currentVendor, 1)  
    setTimeout(() => {
      setConnectLoader(false)
    }, 4000)
    dataPoints(extUserId, 'Connect_Vendor', extStoreId, `Marketplace | ${currentVendor}`)
  }
  const disconnectVendor = () => {
    setConnectLoader(true)
    AuthActions.authenticateVendor(currentVendor, 0);
    setTimeout(() => {
      setConnectLoader(false)
      window.location.reload()
    },1500)
  }
  const handleSelectCategory = (name, id) => {
    navigate(`/vendor/${currentVendor}/search/${encodeURIComponent(name)}?category=${id}`);
    setOpen(false)
    dataPoints(extUserId, 'Category_Clicked', extStoreId, `Marketplace | ${currentVendor} | ${name}`)
  };
  const handleOpen = (value, e, open) => {
    buttonRef.current && buttonRef.current.click()
    setSelected(e)
    setOpen(value);
  };
  const MouseLeave = () => {
    setOpen(false)
    // setFound("") //setFound
    setSelected(undefined)
  }
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    if(position > 10){
      setScrollPosition(position);
    }
    else {
      setScrollPosition(0)
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleChangeForSearch = event => {
    let searchWord = event.target.value;
    opene.child.map((elem)=>{
      elem.child.map((child)=>{
        if(child.name.toLowerCase().includes(searchWord.toLowerCase())){
          setFound(searchWord.toLowerCase());
        }
      })
    })
  };

  return (
    <Disclosure as="nav" className="bg-white border-b-2 border-t-2 border-secondary-200 sticky top-[4rem] z-10">
      {({ open }: any) => (
        <>
          <div onMouseLeave={MouseLeave}>
            <div className="flex justify-between h-14">
              <div className="flex items-center mx-8 ">
                <div className="max-w-[90px] h-12 flex items-center justify-center">
                 <Link to={"/vendor/"+currentVendor}> 
                    <img src={!vendorSlugLoading ? logo : Spinner} alt={""} className={`${currentVendor === 'injured_gadgets' && 'h-12'} min-h-10 object-fit min-w-[80px]`} /> 
                 </Link> 
                </div>
                {/* Fetch Categroies */}

                {!categoryTreeLoading ? categorySlice?.map((category: any, i): any => {
                  return (
                    <div className="hidden xl:block relative" >
                      <>
                        <button
                          className={`inline-flex items-center text-xs text-secondary-500 font-semibold  ${(selected === i) ? 'text-primary-600' : ''} hover:text-secondary-600 2xl:ml-6 md:ml-4 w-71`}
                          ref={buttonRef}
                          id='popoverButton'
                          name='popoverButton'
                          onMouseOver={() => { handleOpen(category, i, opene)}}
                        >
                          {category?.name}
                        </button>
                        {/* <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 -translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 -translate-y-1"
                        > */}
                          {opene ? (
                          <div className={`w-[90vw] fixed right-[70px] ${!scrollPosition ? 'top-[123px]' : 'top-[59px]'} z-10 transform`}>
                            <div className='bg-white pl-4 py-6 w-full relative'> 
                            <div className="absolute inset-y-0 left-0 pl-8 flex items-center pointer-events-none">
                              <SearchIcon className="h-5 w-5 text-secondary-400" aria-hidden="true" />
                            </div>
                            <input
                                  onChange={handleChangeForSearch}
                                  id="search"
                                  value={found}
                                  name="search"
                                  className="block w-266 h-9 pl-10 pr-3 py-2 shadow border border-secondary-300 rounded-md leading-5 text-base bg-white text-secondary-500 focus:outline-none focus:text-secondary-400 focus:ring-1 focus:ring-secondary-300 focus:border-secondary-300 sm:text-sm w-[29vw]"
                                  placeholder="Search"
                                  type="search"
                                  autoComplete="off"
                                />
                            </div>
                            <div className="bg-white flex flex-col flex-wrap h-[500px] overflow-y-auto">
                              {opene?.child?.length > 0 ? opene?.child?.map((c, i) => (
                                <div className="overflow-y-auto">
                                  <div className={`${opene?.child?.length === 1 ? 'max-w-[190px]' : 'max-w-full'}`}>
                                      <div className="px-4 pt-4 pb-2 cursor-pointer rounded-lg hover:bg-gray-50 transition ease-in-out duration-150">
                                        <div className='border rounded-md px-2 bg-secondary-100 min-w-max'>
                                          <p className="text-sm font-bold text-primary-500" onClick={() => handleSelectCategory(c?.name, c?._id)}>{c?.name}</p>
                                        </div>
                                      </div>
                                    {c?.child?.length > 0 ? c?.child?.map((subchild, i) => (
                                      <div className=''>
                                        <div className="px-4 py-2 cursor-pointer rounded-lg hover:bg-gray-50 transition ease-in-out duration-150">
                                          <div className='w-[150px]'>
                                            <p className="text-xs text-secondary-600" onClick={() => handleSelectCategory(subchild?.name, subchild?._id)}>
                                              <span className={`${subchild?.name.toLowerCase().includes(found) && found.length > 0? "text-white bg-primary-500 p-1.5 rounded-md" : "text-secondary-400"}`}>
                                                {subchild?.name} 
                                              </span>  
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    )) :
                                    //  <div className='px-4 py-1 text-xs font-bold text-secondary-600'>No Category Found!</div>
                                    ""
                                    }
                                  </div>
                                </div>
                              )) : <div className='flex items-center justify-center text-lg font-bold text-primary-600'>No Category Found!</div>}
                            </div>
                          </div>
                          )
                          : <div></div>
                          }
                        {/* </Transition> */}
                      </>
                    </div>
                  )
                }) :
                  <div className='flex items-center justify-center ml-14'>
                    <SkeletonLoader skeletonType={'SubHeaderCategorySkeleton'} defaultSkeletonHeight={''} smallInputWidth={''} smallInputHeight={''} />
                  </div>
                }

              </div>
              <div className="flex items-center xl:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-secondary-400 hover:text-secondary-500 hover:bg-secondary-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="hidden xl:mr-4 xl:flex xl:items-center">
                <div className='divide-x-2 mr-6 flex justify-center items-center'>
                  {/* <NavLink to={"/order"} className={({ isActive }) => (isActive ? 'text-sm font-medium text-secondary-700 pr-6 cursor-pointer' : 'text-sm font-medium text-secondary-700 pr-6 cursor-pointer')}>Cutoff Time</NavLink> */}
                  {/* <NavLink to={""} className="pl-6"> */}
                  <div>
                    {
                      !connectLoader ?
                      storeType !== 'shopify' ?
                        vendorConnected ? (
                        //   <div className='flex items-center cursor-pointer'>
                        //   <Badge status="success" text="Connected" />
                        // </div>
                        <div onClick={disconnectVendor} className="flex items-center cursor-pointer">
                            <Badge status="error" text="Disconnect" />
                          </div>
                        ) : (
                          <div onClick={connectVendor} className="flex items-center cursor-pointer">
                            {/* <img
                              src={ConnectAnimation}
                              alt={"hello"}
                              height="35px"
                              width="35px"
                            /> */}
                            <Badge status="success" text="Connect" />
                          </div>
                        )
                        :  
                        <div>
                          <Badge status="success" text="Connected" />
                        </div>
                        :
                        <div className='max-w-[90px] h-12 flex items-center justify-center'><img src={Spinner} alt="loading" className="min-h-10 object-fit min-w-[80px]" /></div>
                    }
                  </div>
                  {/* </NavLink> */}
                </div>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="">
        
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
