import { getData, postData } from "services/apiService";

// Hide Cart
export const HIDE_CART = "HIDE_CART";
export function hideCart() {
  return {
    type: HIDE_CART,
  };
}

// Show Cart
export const SHOW_CART = "SHOW_CART";
export function showCart(vendor) {
  return {
    type: SHOW_CART,
    payload: { vendor },
  };
}
/////////////////////////

export const GET_CART = "GET_CART";

export const GET_CART_IS_lOADING = "GET_CART_IS_lOADING";
export const GET_CART_FAILED = "GET_CART_FAILED";

export const getCartIsLoading = () => ({
  type: GET_CART_IS_lOADING,
  payload: {},
});

export const getCartFailed = (error) => ({
  type: GET_CART_FAILED,
  payload: error,
});

export function getCart({ state: vendor }) {
  const endPoint = `/cart/getCart/${vendor}`;
  return (dispatch) => {
    if (!vendor) return;
    getData(endPoint)
      .then((response) => {
        dispatch(getCartIsLoading());
        dispatch({
          type: GET_CART,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch(getCartFailed(error));
      });
  };
}
// //////////////////

export const UPDATE_CART = "UPDATE_CART";
export const ALTER_CART = "ALTER_CART";
export const UPDATE_CART_IS_lOADING = "UPDATE_CART_IS_lOADING";
export const UPDATE_CART_FAILED = "UPDATE_CART_FAILED";

export const updateCartIsLoading = (id) => ({
  type: UPDATE_CART_IS_lOADING,
  payload: id,
});

export const updateCartFailed = (error) => ({
  type: UPDATE_CART_FAILED,
  payload: error,
});

export const updateCart = (data, action, cb) => {
  // debugger
  let body = { ...data, action: action === 1 ? true : false };
  const endPoint = `/cart/alterCart`
  return (dispatch) => {
    
    postData(endPoint, body)
    .then((response) => {
      dispatch(updateCartIsLoading(body.item));
        dispatch({
          type: ALTER_CART,
          payload: response?.data,
        });
        cb()
      })
      .catch((error) => {
        dispatch(updateCartFailed(error));
      });
  };
}
