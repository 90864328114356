/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { svgIcons } from "../../assets/svg/index";
import { QuantityCounter } from "../QuantityCounter/QuantityCounter";
import * as CommonActions from "store/actions";
import * as Actions from "store/actions";
import { Button } from "../Button/Button";
import { useDispatch } from "react-redux";
import { postData } from "../../services/apiService";
import { openNotificationWithIcon } from "../Notification";
import SkeletonLoader from "components/SkeletonLoader/SkeletonLoader";
import Spinner from "../../assets/images/3dot primary.svg";
import PlaceholderImg from "../../assets/images/placeholder-image.png";
import CubeGridGif from "../../assets/images/CubeGrid.gif";
import { dataPoints } from "utils/CommonFunctions";
import axios from "axios";
import cookieHandler from "services/HelperService/HelperService";
import { getCart } from "store/actions";

interface SlideOverProps {
  show: any;
  onClose: any;
  cartData: any;
  cartLoading: boolean;
  slug: any;
  getVenodrId: any;
  extUserId: string;
  data: any;
  extStoreId: number;
}

export const SlideOver: React.FC<SlideOverProps> = ({
  show,
  data,
  onClose,
  slug,
  extUserId,
  getVenodrId,
  cartData,
  cartLoading,
  extStoreId
}) => {
  const vendorId = getVenodrId;
  // Hooks
  const dispatch = useDispatch();
  const products = cartData ? cartData.cartItems : null;
  const [aic, setAic] = useState<any>();
  let arr: any[] = [];
  // States
  const [orderLoading, setOrderLoading] = useState(false);
  const [allRemoveCartLoading, setAllRemoveCartLoading] = useState(false)
  // Function
  const totalAmount: any = () => {
    console.log('arr', arr)
    return (
      products && products?.length > 0
      ? arr.reduce((c, prev) => parseFloat(c + prev), 0)?.toFixed(2)
        : "0"
    )
    // if (products && products?.length > 0) {
    //   let result:number = arr?.reduce((c, prev) => c + prev, 0)
    //   let res = result
    //   console.log('result', result, res)
    //   return res
    // }
    
  };
  const checkOut = async () => {
    if (!products || products.length < 1) return;
    setTimeout(async() => {
      setOrderLoading(true);
      const cartItems = products.map((product) => {
        return {
          item: product.item._id,
          quantity: product.quantity,
        };
      });
      const body = {
        cartItems,
        vendor: getVenodrId,
        totalAmount: +totalAmount(),
        isLoading: true,
      };
      const { data } = await postData("/order/create", body);
      setOrderLoading(false);
      openNotificationWithIcon(
        "success",
        "Redirecting",
        "You’ll be redirected to vendor website to complete the order."
      );
      window.location.assign(data?.url || "/*");
      dataPoints(extUserId, "Pay_Now_Clicked", extStoreId, `Marketplace | ${slug} | Cart`);
    },2000)
    
  };

  const cartDataShort = cartData?.cartItems?.map((cart) => cart?.item?._id);

  const updateCart = (v) => {
    const prodData = {
      quantity: v?.quantity,
      vendor: slug,
      item: v?.product?._id || v?.product?.id,
      vendorId: cartData?.vendorId,
    };
    setAic(v?.product?._id);
    dispatch(CommonActions.updateCart(prodData, v.action, () => {}));
    if (v.action === 1) {
      dataPoints(extUserId, "Quantity_Increased", extStoreId, `Marketplace | ${slug} | Cart`);
    }
    if (v.action === 0) {
      dataPoints(extUserId, "Quantity_Decreased", extStoreId, `Marketplace | ${slug} | Cart`);
    }
  };
  const removeAllProdcuts: any = async (vendorId) => {
    setAllRemoveCartLoading(true)
    let vendorIdForCartRemoval = vendorId;
    let token = cookieHandler.getCookie("ucfmrk", true);
    var config = {
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}/cart/clearAllCartItems/${vendorIdForCartRemoval}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    try {
      //@ts-ignore
      const data = await axios(config);
      if (data) {
        await dispatch(CommonActions.getCart({ state: vendorId }));
        openNotificationWithIcon(
          "success",
          "All items remove from cart!",
          ""
        );
        setTimeout(() => {
          setAllRemoveCartLoading(false)
        },2000)
      }
    } catch (error) {
      return error
    }
  };

  return (
    <Fragment>
      {orderLoading && (
        <div
          style={{
            position: "fixed",
            height: "100vh",
            width: "100vw",
            color: "white",
            background: "black",
            opacity: 0.8,
            zIndex: 1100,
            overflow: "hidden",
          }}
        >
          <div
            style={{
              marginLeft: "calc(50vw - 50px)",
              marginTop: "calc(50vh - 50px)",
            }}
          >
            <img src={CubeGridGif} width="100px" height="100px" alt="" />
          </div>
        </div>
      )}
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden z-50"
          onClose={onClose}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div
                  className="pointer-events-auto max-w-lg"
                  style={{ width: "455px" }}
                >
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div
                      className={`flex-1 overflow-y-auto py-6 ${
                        !cartLoading ? "opacity-100" : "opacity-40"
                      } px-6`}
                    >
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-semibold text-secondary-700">
                          {" "}
                          Shopping Cart{" "}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="-m-2 p-2 text-secondary-400 hover:text-secondary-500"
                            onClick={onClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>

                      <div className="mt-[30px]">
                        <div className="flow-root">
                          <ul className="">
                            {products !== "null" ? (
                              products && products?.length > 0 ? (
                                <Fragment>
                                  {products?.map((product:any) => {
                                     ((product.quantity === 1)
                                      ? arr.push( product?.item?.qtyArray?.length ? product.quantity * product?.item?.qtyArray?.filter(el => el?.Quantity === 1)[0]?.Price : product?.quantity * product?.item?.costPrice)
                                      : (product.quantity >= 2 && product.quantity < 5)
                                        ? arr.push(product?.item?.qtyArray?.length ? (product.quantity * product?.item?.qtyArray?.filter(el => el?.Quantity === 2)[0]?.Price) : product?.quantity * product?.item?.costPrice)
                                        : (product.quantity >= 5 && product.quantity < 10)
                                          ? arr.push( product?.item?.qtyArray?.length ? product.quantity * product?.item?.qtyArray?.filter(el => el?.Quantity === 5)[0]?.Price : product?.quantity * product?.item?.costPrice)
                                          : arr.push( product?.item?.qtyArray?.length ? product.quantity * product?.item?.qtyArray?.filter(el => el?.Quantity === 10)[0]?.Price : product?.quantity * product?.item?.costPrice));
                                    // ((productQuantity === 1)
                                    //   ? arr.push(productQuantity * filterProductPrice1)
                                    //   : (productQuantity >= 2 || productQuantity < 5)
                                    //     ? arr.push(productQuantity * filterProductPrice2)
                                    //     : (productQuantity >= 5 || productQuantity < 10)
                                    //       ? arr.push(productQuantity * filterProductPrice5)
                                    //       : arr.push(productQuantity * filterProductPrice10));
                                    // ((productQuantity >= 5 || productQuantity < 10) && arr.push(productQuantity * filterProductPrice5));
                                    // (productQuantity >= 10 && arr.push(productQuantity * filterProductPrice10))
                                    
                                    return (
                                      <CartProductItem
                                        updateCartListener={updateCart}
                                        cartData={cartData}
                                        isLoading={product?.item?._id === cartLoading}
                                        product={product.item}
                                        selectedQty={product.quantity}
                                        dispatch={dispatch}
                                        slug={slug}
                                        extUserId={extUserId}
                                        extStoreId={extStoreId} productQuantity={product}                                      />
                                    );
                                  })}
                                </Fragment>
                              ) : (
                                <div className="pt-53 text-center">
                                  No Item in the cart!
                                </div>
                              )
                            ) : (
                              <SkeletonLoader
                                skeletonType={"slideOverCartSkeleton"}
                                defaultSkeletonHeight={""}
                                smallInputWidth={""}
                                smallInputHeight={""}
                              />
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="border-t border-secondary-200 py-6 px-4 sm:px-6">
                      <div className={`${products === "null" || products?.length <= 0 ? 'opacity-50' : ''}`}>
                        <Button
                          onClick={allRemoveCartLoading || products === "null" || products?.length <= 0  ? () => {} : checkOut}
                          defaultBtnText={`Pay $${totalAmount()} now`}
                          defaultBtnWidth="100%"
                          defaultBtnHeight="48px"
                          defaultBgColor={`${products === "null" || products?.length <= 0 ? '' : 'hover:bg-green-700'} text-white bg-green-600 `}
                          disabled={products === "null" || products?.length <= 0 || allRemoveCartLoading ? true : false}
                        />
                      </div>
                      <div className={`${products === "null" || products?.length <= 0 ? 'opacity-50' : ''} mt-2`}>
                        <button
                          className="w-full bg-red-600 hover:bg-red-700 text-white h-12 rounded"
                          onClick={products && products?.length > 0 ? () => removeAllProdcuts(vendorId) : () => {}}
                        >
                          Clear Cart
                        </button>
                        {allRemoveCartLoading &&
                        <div className="absolute z-10 top-0 flex items-center w-full h-full">
                          <img className="mx-auto pr-8" src={Spinner} alt="" />
                        </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  );
};

interface ICartProductItem {
  product: any;
  selectedQty: number;
  updateCartListener: any;
  isLoading: boolean;
  cartData: any;
  dispatch: any;
  slug: string;
  extUserId: string;
  extStoreId: number;
  productQuantity: any
}

const CartProductItem: React.FC<ICartProductItem> = ({
  product,
  dispatch,
  extUserId,
  extStoreId,
  slug,
  cartData,
  updateCartListener,
  selectedQty,
  isLoading,
  productQuantity
}) => {
  // States
  const commonClass = "text-sm font-medium";
  const [quantity, setQuantity] = useState<number>(0 || selectedQty);
  const [loading, setLoading] = useState<any>(false);
  console.log('object', product, productQuantity.quantity);
  // Extract Data
  const cartTotalQuantity = cartData?.cartItems?.find(
    (i) => i?.item?._id === product?.id
  )?.quantity;

  const updateCart = (v: number) => {
    const action = v - quantity > 0 ? 1 : 0;
    const diff = Math.abs(v - quantity);
    setQuantity(v);
    setLoading(!loading);
    const data = {
      quantity: diff,
      product,
      action,
    };
    updateCartListener(data);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  const removeFromCart = () => {
    const data = {
      quantity,
      product,
      action: 0,
    };
    setLoading(!loading);
    updateCartListener(data);
    dataPoints(extUserId, "Product_Removed", extStoreId, `Marketplace | ${slug} | Cart`);
    setTimeout(() => {
      setLoading(false);
      openNotificationWithIcon("success", `Product deleted successfully!`, "");
    }, 3000);
  };

  const priceFunction = () => {
    if (productQuantity.quantity === 1 && product?.qtyArray?.length) {
      let p1 = productQuantity.quantity * product?.qtyArray?.filter(el => el?.Quantity === 1)[0]?.Price
      return p1.toFixed(2)
    }
    if ((productQuantity.quantity >= 2 && productQuantity.quantity < 5) && product?.qtyArray?.length) {
      let p2 = productQuantity.quantity * product?.qtyArray?.filter(el => el?.Quantity === 2)[0]?.Price
      return p2.toFixed(2)
    }
    if ((productQuantity.quantity >= 5 && productQuantity.quantity < 10) && product?.qtyArray?.length) {
      let p3 = productQuantity.quantity * product?.qtyArray?.filter(el => el?.Quantity === 5)[0]?.Price
      return p3.toFixed(2)
    }
    if ((productQuantity.quantity > 10) && product?.qtyArray?.length) {
      let p4 = productQuantity.quantity * product?.qtyArray?.filter(el => el?.Quantity === 5)[0]?.Price
      return p4.toFixed(2)
    }
    else {
      let pq = productQuantity.quantity * product?.costPrice
      return pq.toFixed(2)
    }
  }
  return (
    <Fragment>
      <li
        key={product._id}
        className={` flex-1 pb-5 pt-6 border-b border-secondary-300`}
      >
        <div className={`${loading ? "flex opacity-50" : "flex"}`}>
          {slug == "2ndlifephones" ? (
            ""
          ) : (
            <div className="h-20 w-20 flex-shrink-0 overflow-hidden rounded-md border-secondary-200">
              <img
                src={
                  product?.imageUrls?.length && product?.imageUrls !== null
                    ? product?.imageUrls
                    : PlaceholderImg
                }
                alt={""}
                className="h-full w-full rounded-md object-cover object-center"
              />
            </div>
          )}

          <div className="ml-4 flex flex-1 flex-col">
            <div>
              <div
                className={`flex justify-between ${commonClass} text-secondary-700`}
              >
                <p>{product?.name}</p>
                <span onClick={removeFromCart} className="cursor-pointer ml-8">
                  {svgIcons.trash}
                </span>
              </div>
            </div>
            <div className="flex flex-1 items-end justify-between text-sm">
              {/* <p className={`${commonClass} text-green-600`}>${(product.costPrice * quantity).toFixed(2)}</p> */}
              <p className={`${commonClass} text-green-600`}>
                ${priceFunction()}
              </p>
              <div className="flex">
                <QuantityCounter
                  onValueChange={
                    cartTotalQuantity === product?.qty ||
                    quantity > product?.qty - cartTotalQuantity ||
                    product?.qty === 0
                      ? () => {}
                      : updateCart
                  }
                  value={quantity}
                  productQuantity={product.qty}
                  btnWidth="24px"
                  btnHeight="24px"
                  inputWidth="30px"
                  inputHeight="28px"
                />
              </div>
            </div>
          </div>
        </div>
      </li>
      {loading ? (
        <div className="absolute z-10 top-0 flex items-center w-full h-full">
          <img className="mx-auto pr-8" src={Spinner} alt="" />
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};
