import { Disclosure, } from '@headlessui/react'
import { MinusSmIcon, PlusSmIcon } from '@heroicons/react/solid'
import { Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Slider, Pagination, Tooltip } from "antd";
import SkeletonLoader from "components/SkeletonLoader/SkeletonLoader"
import { fetchProducts, fetchShopifyProducts } from 'main/VendorHomePage/store/actions';
import { dataPoints } from 'utils/CommonFunctions';

const CategoryFilter = ({ categoryItemLoading, currentVendor, filterMenu, extUserId, scrollPosition, isLoading, onChangeListeners, minVal, maxVal, selected, extStoreId, queryCategory, extStoreName, parsed, currentPagee, categoriesData, prevVendor, keyword_Name }: any) => {
  
  const [cat, setCat] = useState([])
  const onChangeFunction = useCallback((e,category) => onChangeListeners(e, category), [selected, currentPagee])
  return (
    <div className='mt-1.5'>
      <form className={`hidden lg:block w-[350px] ${!scrollPosition ? 'h-[507px]' : 'h-[458px]'} overflow-y-auto bg-white rounded-md pb-1.5`}>
        {filterMenu?.map((item, i) => (
          <Disclosure defaultOpen={true} as="div" key={i} className="border-b border-secondary-200 mx-8 py-6">
            {({ open }) => (
              <>
                <h3 className="-my-3 flow-root">
                  <div className="py-3 bg-white w-full flex items-center justify-between text-sm text-secondary-400 hover:text-secondary-500">
                    <span className="font-medium text-sm text-secondary-800">{item.categoryTitle}</span>
                    <Disclosure.Button className="ml-6 flex items-center">
                      {open ? (
                        <MinusSmIcon className="h-5 w-5" />
                      ) : (
                        <PlusSmIcon className="h-5 w-5" />
                      )}
                    </Disclosure.Button>
                  </div>
                </h3>
                {open &&
                  <div className="pt-6 ">
                    <div className={`space-y-4 ${item.categoryTitle === 'Color' || item.categoryTitle === "Cosmetic Grade" ? 'flex flex-wrap' : ''}`}>
                      {(currentVendor === 'secondlifephones' ? !isLoading : !categoryItemLoading) ? (currentVendor !== '2ndlifephones' ? item && item?.categoryName?.length > 0 : item) ? item?.categoryName?.map((category) => {
                        category["titleId"] = item?.id
                        setCat(category)
                        return (
                          <Fragment>
                            {category.titleId === 2 ?
                              <div>
                                {/* PriceRange */}
                                <Row>
                                  <Col span={24} className="overflow-hidden">
                                    <Slider range={true} onChange={(e) => onChangeListeners(e, null)} min={minVal} max={maxVal} step={1} />
                                  </Col>
                                </Row>
                              </div>
                              :
                              category.titleId === 5 ?
                              <div className='flex items-center mr-4'>
                                  <input
                                  id={category.id}
                                  name={category.value}
                                  value={category.name}
                                  type="checkbox"
                                  checked={selected.filter(sl => sl.id == category?.value).length > 0}
                                  // onChange={(e) => onChangeListener(e, category)}
                                  onChange={(e) => onChangeFunction(e, category)}
                                  className={`h-4 w-4 ${category.name === 'Black' ? 'mt-4' : ''} cursor-pointer border-secondary-300 rounded accent-primary-600 focus:ring-indigo-500`} />
                                <Tooltip title={`${category.name === 'Black' ? 'Black' 
                                : category.name === 'Blue' ? 'Blue' 
                                : category.name === 'Coral' ? 'Coral' 
                                : category.name === 'Gold' ? 'Gold'
                                : category.name === 'Graphite' ? 'Graphite'
                                : category.name === 'Green' ? 'Green'
                                : category.name === 'Midnight' ? 'Midnight'
                                : category.name === 'Midnight Green' ? 'Midnight Green'
                                : category.name === 'PRODUCT(RED)' ? 'PRODUCT(RED)'
                                : category.name === 'Pacific Blue' ? 'Pacific Blue'
                                : category.name === 'Pink' ? 'Pink'
                                : category.name === 'Purple' ? 'Purple'
                                : category.name === 'Rose Gold' ? 'Rose Gold'
                                : category.name === 'Sierra Blue' ? 'Sierra Blue'
                                : category.name === 'Silver' ? 'Silver'
                                : category.name === 'Space Gray' ? 'Space Gray'
                                : category.name === 'Starlight' ? 'Starlight'
                                : category.name === 'White' ? 'White'
                                : category.name === 'Yellow' ? 'Yellow'
                                : ''
                              }`}>
                                <label
                                  className=
                                  {
                                  `${category.name === 'Black' ? 
                                  'w-[26px] h-6 mt-4 flex justify-center items-center rounded-sm border-none border-black bg-black' 
                                  : 
                                  category.name === 'Blue' ?
                                  'w-[26px] h-6 flex justify-center items-center rounded-sm border-none border-blue-700 bg-blue-700'
                                  :
                                  category.name === 'Coral' ?
                                  'w-[26px] h-6 flex justify-center items-center rounded-sm border-none border-[#FF7F50] bg-[#FF7F50]'
                                  :
                                  category.name === 'Gold' ?
                                  'w-[26px] h-6 flex justify-center items-center rounded-sm border-none border-[#FFD700] bg-[#FFD700]'
                                  :
                                  category.name === 'Graphite' ?
                                  'w-[26px] h-6 flex justify-center items-center rounded-sm border-none border-[#4b4e53] bg-[#4b4e53]'
                                  :
                                  category.name === 'Green' ?
                                  'w-[26px] h-6 flex justify-center items-center rounded-sm border-none border-green-700 bg-green-700'
                                  :
                                  category.name === 'Midnight' ?
                                  'w-[26px] h-6 flex justify-center items-center rounded-sm border-none border-[#004953] bg-[#004953]'
                                  :
                                  category.name === 'Midnight Green' ?
                                  'w-[26px] h-6 flex justify-center items-center rounded-sm border-none border-[#004953] bg-[#004953]'
                                  :
                                  category.name === 'PRODUCT(RED)' ?
                                  'w-[26px] h-6 flex justify-center items-center rounded-sm border-none border-red-700 bg-red-700'
                                  :
                                  category.name === 'Pacific Blue' ?
                                  'w-[26px] h-6 flex justify-center items-center rounded-sm border-none border-[#1ca9c9] bg-[#1ca9c9]'
                                  :
                                  category.name === 'Pink' ?
                                  'w-[26px] h-6 flex justify-center items-center rounded-sm border-none border-pink-700 bg-pink-700'
                                  :
                                  category.name === 'Purple' ?
                                  'w-[26px] h-6 flex justify-center items-center rounded-sm border-none border-purple-700 bg-purple-700'
                                  :
                                  category.name === 'Rose Gold' ?
                                  'w-[26px] h-6 flex justify-center items-center rounded-sm border-none border-[#B76E79] bg-[#B76E79]'
                                  :
                                  category.name === 'Sierra Blue' ?
                                  'w-[26px] h-6 flex justify-center items-center rounded-sm border-none border-[#69abce] bg-[#69abce]'
                                  :
                                  category.name === 'Silver' ?
                                  'w-[26px] h-6 flex justify-center items-center rounded-sm border-none border-[#C0C0C0] bg-[#C0C0C0]'
                                  :
                                  category.name === 'Space Gray' ?
                                  'w-[26px] h-6 flex justify-center items-center rounded-sm border-none border-[#343D46] bg-[#343D46]'
                                  :
                                  category.name === 'Starlight' ?
                                  'w-[26px] h-6 flex justify-center items-center rounded-sm border-none border-[#F8F9Ec] bg-[#F8F9Ec]'
                                  :
                                  category.name === 'White' ?
                                  'w-[26px] h-6 flex justify-center items-center rounded-sm border-none border-secondary-100 bg-secondary-100'
                                  :
                                  category.name === 'Yellow' ?
                                  'w-[26px] h-6 flex justify-center items-center rounded-sm border-none border-yellow-700 bg-yellow-700'
                                  :
                                  ''
                                  }
                                  ml-3 text-sm text-secondary-600`
                                }
                                >
                                  {/* {category.name} */}
                                </label>
                                </Tooltip>
                              </div>
                              :
                              category.titleId === 4 ?
                              <div className={`flex items-center mr-4`}>
                                 <input
                                  id={category.id}
                                  name={category.value}
                                  value={category.name}
                                  type="checkbox"
                                  checked={selected.filter(sl => sl.id == category?.value).length > 0}
                                  // onChange={(e) => onChangeListener(e, category)}
                                  onChange={(e) => onChangeFunction(e, category)}
                                  className={`h-4 w-4 ${category.name === 'A' ? 'mt-4' : ''}`} 
                                 />
                                <label
                                  className={`${category.titleId === 4 ? 'w-[26px] h-6 flex justify-center items-center rounded-lg border-none border-secondary-500 bg-secondary-300 ' : ''} ${category.name === 'A' ? 'mt-4' : ''} ml-3 text-sm text-secondary-600`}>
                                  {category.name}
                                </label>
                              </div>
                              :
                              <div className="flex items-center">
                                <input
                                  id={category.id}
                                  name={category.value}
                                  value={category.name}
                                  type="checkbox"
                                  checked={selected.filter(sl => sl.id == category?.value).length > 0}
                                  onChange={(e) => onChangeFunction(e, category)}
                                  className="h-4 w-4 cursor-pointer border-secondary-300 rounded accent-primary-600 focus:ring-indigo-500" />
                                <label
                                  className="ml-3 text-sm text-secondary-600"
                                >
                                  {category.name}
                                </label>
                              </div>
                            }
                          </Fragment>
                        )
                      }) : 'Not Found!' : <SkeletonLoader skeletonType={'filterCheckboxSkeleton'} defaultSkeletonHeight={''} smallInputWidth={''} smallInputHeight={''} />}
                    </div>
                  </div>
                }
              </>
            )}
          </Disclosure>
        ))}
        <div className='flex justify-center items-center cursor-pointer my-4'>
        </div>
      </form>
    </div>
  )
}

export default memo(CategoryFilter)