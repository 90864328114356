import * as Actions from "../actions";

const initialState = {
  isLoading: false,
  errMsg: null,
  data: null,
  dataByCategory: {
    docs:[]
  },
  success: "",
};

export const GetProductReducer = function (state: any = initialState, action) {
  switch (action.type) {
    case Actions.GET_PRODUCT:
      return {
        ...state,
        isLoading: false,
        errMsg: null,
        data: action.payload?.data,
        success: true,
      };
    case Actions.GET_ITEMS_CATEGORIES:
      return {
        ...state,
        isLoading: false,
        errMsg: null,
        dataByCategory: action.payload,
        success: true,
      };
    case Actions.GET_PRODUCT_IS_lOADING:
      return {
        ...state,
        isLoading: true,
        errMsg: null,
        data: null,
        success: false,
      };
    case Actions.GET_PRODUCT_FAILED:
      return {
        ...state,
        isLoading: false,
        errMsg: action.payload,
        data: null,
        success: "failed",
      };
    default:
      return state;
  }
};

export const GetGlobalSearchReducer = function (
  state: any = initialState,
  action
) {
  switch (action.type) {
    case Actions.GLOBAL_SEARCH:
      return {
        ...state,
        isLoading: false,
        errMsg: null,
        data: action.payload?.data,
        success: true,
      };
    case Actions.GLOBAL_SEARCH_LOADING:
      return {
        ...state,
        isLoading: true,
        errMsg: null,
        data: null,
        success: false,
      };
    case Actions.GLOBAL_SEARCH_FAILED:
      return {
        ...state,
        isLoading: false,
        errMsg: action.payload,
        data: null,
        success: "failed",
      };
    case Actions.SET_GLOBAL_SEARCH_KEY_WORDS:
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
};

export const GetGlobalVendorSearchReducer = function (
  state: any = initialState,
  action
) {
  switch (action.type) {
    case Actions.GLOBAL_VENDOR_SEARCH:
      return {
        ...state,
        isLoading: false,
        errMsg: null,
        data: action.payload?.data,
        success: true,
      };
    case Actions.GLOBAL_VENDOR_SEARCH_LOADING:
      return {
        ...state,
        isLoading: true,
        errMsg: null,
        data: null,
        success: false,
      };
    case Actions.GLOBAL_VENDOR_SEARCH_FAILED:
      return {
        ...state,
        isLoading: false,
        errMsg: action.payload,
        data: null,
        success: "failed",
      };
      case Actions.SET_GLOBAL_VENDOR_SEARCH_KEY_WORDS:
        return {
          ...state,
          data: null,
        };
    default:
      return state;
  }
};
