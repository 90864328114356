import Body from "components/Body";
import { getData, postData } from "services/apiService";

export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCTS_IS_lOADING = "FETCH_PRODUCTS_IS_lOADING";
export const FETCH_PRODUCTS_FAILED = "FETCH_PRODUCTS_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const fetchProductsIsLoading = () => ({
  type: FETCH_PRODUCTS_IS_lOADING,
  payload: {},
});

export const fetchProductsFailed = (error) => ({
  type: FETCH_PRODUCTS_FAILED,
  payload: error,
});

export function fetchProducts(vendor, page, limit, body) {
  const endPoint = "/items/fetchItems";
  fetchProductsIsLoading();

  const { default: paresedDefault, keyword:parsedKeyword,  categoryId: parsedCategoryId, ...rest } = body.filter;

  // let updatedCategoryId = vendorId === "619b271c6a240b5798042135" ? "" : parsedCategoryId 
  
  return (dispatch) => {
    dispatch(fetchProductsIsLoading());
    const payload = {
      vendor,
      page: page,
      limit: limit,
      filter: rest,
      keyword: parsedKeyword,
      categoryId: parsedCategoryId,
      // categoryId: updatedCategoryId,
      isDefault: paresedDefault,
    };
    postData(endPoint, payload)
      .then((response) => {
        dispatch({
          type: FETCH_PRODUCTS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch(fetchProductsFailed(error));
      });
  };
}

export const FETCH_SHOPIFY_PRODUCTS = "FETCH_SHOPIFY_PRODUCTS";
export const FETCH_SHOPIFY_PRODUCTS_IS_lOADING = "FETCH_SHOPIFY_PRODUCTS_IS_lOADING";
export const FETCH_SHOPIFY_PRODUCTS_FAILED = "FETCH_SHOPIFY_PRODUCTS_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const fetchShopifyProductsIsLoading = () => ({
  type: FETCH_SHOPIFY_PRODUCTS_IS_lOADING,
  payload: {},
});

export const fetchShopifyProductsFailed = (error) => ({
  type: FETCH_SHOPIFY_PRODUCTS_FAILED,
  payload: error,
});

export function fetchShopifyProducts(body) {
  const endPoint = "/items/secondLife-fetchItems";
  fetchShopifyProductsIsLoading();
  return (dispatch) => {
    dispatch(fetchShopifyProductsIsLoading());
    const bodyForApi = {
      consmeticGrades:body.consmeticGrades,
      simLockStatuses:body.simLockStatuses,
      functionalStatuses:body.functionalStatuses,
      storages:body.storages,
      models:body.models,
      colors:body.colors,
      brands:body.brands,
      priceRange: {
        min:body.priceRange.min,
        max:body.priceRange.max
      },
      keyword: body.keyword,
      page: body.page,
      limit: body.limit,
      vendor: body.vendor  
    };
    postData(endPoint, bodyForApi)
      .then((response) => {
        dispatch({
          type: FETCH_SHOPIFY_PRODUCTS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch(fetchShopifyProductsFailed(error));
      });
  };
}
