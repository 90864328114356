import * as Actions from "../actions";

const initialState = {
  isLoading: false, errMsg: null, data: null, success: ''
};





export const GetOrdersReducer = function (state: any = initialState, action) {
  switch (action.type) {
    case Actions.GET_ORDERS:
      return { ...state, isLoading: false, errMsg: null, data: action.payload?.data, success: true }
    case Actions.GET_ORDERS_IS_lOADING:
      return { ...state, isLoading: true, errMsg: null, data: null, success: false }
    case Actions.GET_ORDERS_FAILED:
      return { ...state, isLoading: false, errMsg: action.payload, data: null, success: 'failed' }
    default:
      return state;
  }
};