import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Fragment } from 'react'
import { classNames } from '../../utils/CommonFunctions'

export const MenuDropdown = ({sortHandler, sortType}: {sortHandler?: any, sortType?: number}) => {
  const sortOptions = [
    { name: 'Ascending', current: sortType === -1, onclick: () => { sortHandler(-1) } },
    { name: 'Descending', current: sortType === 1, onclick: () => { sortHandler(1) } },
  ]
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="group inline-flex justify-center text-sm font-medium text-secondary-500 hover:text-secondary-700">
          Sort
          <ChevronDownIcon
            className="flex-shrink-0 ml-1 h-5 w-5 text-secondary-400 group-hover:text-secondary-500"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-left z-10 absolute right-0 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {sortOptions.map((option) => (
              <Menu.Item key={option.name}>
                {({ active }) => (
                  <span
                    onClick={option.onclick}
                    className={classNames(
                      option.current ? 'font-medium text-secondary-900' : 'text-secondary-500',
                      active ? 'bg-secondary-100' : '',
                      'block px-4 py-2 text-sm', 'cursor-pointer'
                    )}
                  >
                    {option.name}
                  </span>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
