import SkeletonLoader from "components/SkeletonLoader/SkeletonLoader"
import { useSelector } from "react-redux"
import { useLocation, useMatch } from "react-router"
import { Table } from "../Table/Table"

interface ProductListViewProps {
  ProductCardData: any
  onClick: any
  isShopifyLoading: boolean
  searchProduct: any
}

export const ProductListView:React.FC<ProductListViewProps> = ({ProductCardData, isShopifyLoading, onClick, searchProduct}) => {
  const ifOnVendorPage = useMatch({path: "/vendor/:slug/:id", end: true})
  // useSelector
  const { data: shopifyProducts } = useSelector(
    ({ vendor }: any) => vendor.FetchShopifyProductsReducer
  );
  const {data: fetchReducerData, isLoading: categoryItemLoading } =
  useSelector(({ vendor }: any) => vendor.FetchProductsReducer);
  
  // Extract Data
  const shopifyProductData = shopifyProducts?.data?.items?.docs
  const fetchGenericData = fetchReducerData?.data?.items?.docs
  const location = useLocation()
  const pathChunks = location?.pathname?.substring(1).split("/")
  const currentVendor = pathChunks[1]
  console.log('2ndlife', searchProduct)
  return (
    !ifOnVendorPage && currentVendor !== '2ndlifephones' ? !categoryItemLoading ? currentVendor !== '2ndlifephones' && fetchGenericData && fetchGenericData?.length > 0 ?
    <Table ProductCardData={currentVendor !== '2ndlifephones' && fetchGenericData} onClick={onClick} tableType={'Product'} reportData={undefined} orderHistoryData={undefined} />
    :
    <div className='text-center mt-[112px]'><span className=''>No Products!</span></div>
    :
    <div><SkeletonLoader skeletonType={'TableListViewSkeleton'} defaultSkeletonHeight={''} smallInputWidth={''} smallInputHeight={''} /></div>
    :
    !ifOnVendorPage ? !isShopifyLoading ? currentVendor === '2ndlifephones' && shopifyProductData?.length ? !searchProduct?.length ?
        <Table ProductCardData={currentVendor === '2ndlifephones' && shopifyProductData} onClick={onClick} tableType={'Product'} reportData={undefined} orderHistoryData={undefined} />
        :
        <Table ProductCardData={currentVendor === '2ndlifephones' && searchProduct} onClick={onClick} tableType={'Product'} reportData={undefined} orderHistoryData={undefined} />
    :
    <div className='text-center mt-24'><span className=''>No Products!</span></div>
    :
    <div><SkeletonLoader skeletonType={'TableListViewSkeleton'} defaultSkeletonHeight={''} smallInputWidth={''} smallInputHeight={''} /></div>
    :
    ProductCardData?.relatedProducts && ProductCardData?.relatedProducts?.length > 0 ?
    <Table ProductCardData={ProductCardData} onClick={onClick} tableType={'Product'} reportData={undefined} orderHistoryData={undefined} />
    :
    <div className='text-center my-24'><span className=''>No Products!</span></div>
    // :
    // <div><SkeletonLoader skeletonType={'TableListViewSkeleton'} defaultSkeletonHeight={''} smallInputWidth={''} smallInputHeight={''} /></div>
  )
}