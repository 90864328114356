import * as Actions from "../actions";
const initialState = {
  isLoading: false, errMsg: null, data: null, success: ''
};

export const FetchProductsReducer = function (state: any = initialState, action) {
  switch (action.type) {
    case Actions.FETCH_PRODUCTS:
      return { ...state, isLoading: false, errMsg: null, data: action.payload, success: true }
    case Actions.FETCH_PRODUCTS_IS_lOADING:
      return { ...state, isLoading: true, errMsg: null, data: [], success: false }
    case Actions.FETCH_PRODUCTS_FAILED:
      return { ...state, isLoading: false, errMsg: action.payload, data: [], success: 'failed' }
    default:
      return state;
  }
};

export const FetchShopifyProductsReducer = function (state: any = initialState, action) {
  switch (action.type) {
    case Actions.FETCH_SHOPIFY_PRODUCTS:
      return { ...state, isLoading: false, errMsg: null, data: action.payload, success: true }
    case Actions.FETCH_SHOPIFY_PRODUCTS_IS_lOADING:
      return { ...state, isLoading: true, errMsg: null, data: [], success: false }
    case Actions.FETCH_SHOPIFY_PRODUCTS_FAILED:
      return { ...state, isLoading: false, errMsg: action.payload, data: [], success: 'failed' }
    default:
      return state;
  }
};
