import React from 'react'
import { Link } from 'react-router-dom';
import marketplaceLogo from '../../assets/images/Marketplace-single-logo.png'
import { svgIcons } from 'assets/svg';

const ErrorPage = (): JSX.Element => {
  return (
    <div className="flex justify-center items-center h-[70vh] text-center">
        <div className="">
          <img src={marketplaceLogo} width="71px" height="64px" alt="" className='mx-auto' />
          <p className='text-base font-semibold text-primary-700 mt-10 mb-2'>404 ERROR</p>
          <p className='text-[48px] font-bold text-secondary-700 mb-2'>Page not found!</p>
          <p className='text-base text-secondary-500 mb-6'>Sorry, we couldn’t find the page you’re looking for.</p>
          <Link to="/" className='text-lg text-primary-600 hover:text-primary-700 flex justify-center items-center'>Go back home <span className='ml-1'>{svgIcons.chevronRightPrimary}</span></Link>
        </div>
      </div>
  )
}

export default ErrorPage;