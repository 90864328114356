import { getData } from 'services/apiService'

export const FETCH_TICKER = "FETCH_TICKER";
export const FETCH_TICKER_IS_lOADING = "FETCH_TICKER_IS_lOADING";
export const FETCH_TICKER_FAILED = "FETCH_TICKER_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /



export const fetchTickerIsLoading = () => ({
  type: FETCH_TICKER_IS_lOADING,
  payload: {}
});

export const fetchTickerFailed = (error) => ({
  type: FETCH_TICKER_FAILED,
  payload: error
});

export function fetchTicker() {

  const endPoint = `/user/tickers`
  fetchTickerIsLoading()

  return dispatch => {
    dispatch(fetchTickerIsLoading());
    getData(endPoint)
      .then(response => {
        dispatch({
          type: FETCH_TICKER,
          payload: response
        });
      })
      .catch(error => {
        dispatch(fetchTickerFailed(error))

      });
  };
}