import axios from "axios";

export const getData = (url: string, data?: any) => {
  return axios
    .get(url, { params: data })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      return false;
    })
    .catch((err) => {
      if (err.response) throw err.response.data;
      else throw err;
    });
};

export const postData = (url: string, data: any, config?: any) => {
  return (
    axios
      .post(url, data, config)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
        return false
      })
      // .catch((err) => {
      //   let message = "";
      //   if (err?.response?.data?.message) {
      //     message = err?.response?.data?.message;
      //     throw new Error(message);
      //   } else {
      //     const error = Object.keys(err.response.data);
      //     error.map((e) => {
      //       const arr = err.response.data[e];
      //       if (Array.isArray(arr)) arr.map((p: any) => (message += p + "\r\n"));
      //       else {
      //         message = arr;
      //       }
      //     });
      //     throw new Error(message);
      //   }
      // });
      .catch((err) => {
        if (err.response) throw err.response.data;
        else throw err;
      })
  );
};

export const putData = (url: string, data: any) => {
  return axios
    .put(url, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      return false;
    })
    .catch((err) => {
      if (err.response) throw err.response.data;
      else throw err;
    });
};

export const deleteData = (url: string, data: any) => {
  return axios
    .delete(url, { params: data })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      return false;
    })
    .catch((err) => {
      if (err.response) throw err.response.data;
      else throw err;
    });
};
