import SkeletonLoader from "components/SkeletonLoader/SkeletonLoader";
import reducer from "main/VendorHomePage/store/reducers";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { useLocation, useMatch } from "react-router-dom";
import withReducer from "store/withReducer";
import BaseProductCard from "../BaseProductCard";

interface ProductCardProps {
  ProductCardData: any;
  handleClick: any;
  vendorName?: string;
  id?: string;
  vendorId?: any;
  productsLoading: boolean;
  RedirectTo: any;
  slug: any;
  searchName: any;
  searchProduct: any
}

const ProductCard: React.FC<ProductCardProps> = ({
  ProductCardData,
  handleClick,
  vendorName,
  id,
  vendorId,
  productsLoading,
  RedirectTo,
  slug,
  searchName,
  searchProduct
}) => {
  // Hooks
  const location = useLocation();
  const ifOnVendorPage = useMatch({ path: "/vendor/:slug/:id", end: true });
  // Reducer
  const { data: fetchProducts, isLoading } = useSelector(
    ({ vendor }: any) => vendor.FetchProductsReducer
  );
  const { data: fetchProductsSecondLife, isLoading: isLoadingSecondLife } = useSelector(
    ({ vendor }: any) => vendor.FetchShopifyProductsReducer
  );
  // const {data: shopifyProducts, shopifyProductLoading} = useSelector(({vendor}: any) => vendor.FetchShopifyProductsReducer)
  // State
  const extVendor = location?.pathname?.substring(1).split("/");
  const currentVendor = extVendor[1];
  const fetchDocs = slug === "2ndlifephones" ? fetchProductsSecondLife?.data?.items?.docs : fetchProducts?.data?.items?.docs  
  return (
    <Fragment>
      <div
        className={`mt-4 grid grid-cols-1 gap-y-8 sm:grid-cols-2 sm:gap-x-6 ${
          fetchProducts?.data?.items?.docs?.length <= 0 &&
          "md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 3xl:grid-cols-1 4xl:grid-cols-1"
        } ${
          (ProductCardData?.length <= 0 ||
            ProductCardData?.relatedProducts?.length <= 0) &&
          "md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 3xl:grid-cols-1 4xl:grid-cols-1"
        } ${
          location.pathname.includes("/search") &&
          currentVendor !== "mobile_sentrix"
            ? "lg:grid-cols-2"
            : "lg:grid-cols-3"
        } ${
          location.pathname.includes("/search") &&
          currentVendor !== "mobile_sentrix"
            ? "xl:grid-cols-3"
            : "xl:grid-cols-4"
        } ${
          location.pathname.includes("/search") &&
          currentVendor !== "mobile_sentrix"
            ? "3xl:grid-cols-3"
            : "3xl:grid-cols-4"
        } xl:gap-x-8 3xl:grid-cols-4 4xl:grid-cols-4`}
      >
        {!ifOnVendorPage ? (
          !isLoading ? (
            // fetchProducts?.data?.items?.docs?.length > 0 ? (
              fetchDocs?.length > 0 ? !searchProduct?.length ? (
              fetchDocs?.map((product: any) => (
                <BaseProductCard
                  key={product.id}
                  product={product}
                  currentVendor={currentVendor}
                  addToCartListener={handleClick}
                  RedirectTo={RedirectTo}
                />
              ))
            ) :
            (
                searchProduct?.map((searchproduct: any) => (
                <BaseProductCard
                  key={searchproduct.id}
                  product={searchproduct}
                  currentVendor={currentVendor}
                  addToCartListener={handleClick}
                  RedirectTo={RedirectTo}
                />
              ))  
            )
            :
            (
              <div className="text-center my-24">
                <span className="">No Products!</span>
              </div>
            )
          ) : (
            <div className="flex space-x-8">
              <SkeletonLoader
                skeletonType={
                  location.pathname.includes("/search") &&
                  currentVendor !== "mobile_sentrix"
                    ? "SearchProductSkeleton"
                    : "ProductCardSkeleton"
                }
                defaultSkeletonHeight={""}
                smallInputWidth={""}
                smallInputHeight={""}
              />
            </div>
          )
        ) : !productsLoading ? (
          ProductCardData?.relatedProducts?.length > 0 ? (
            ProductCardData?.relatedProducts?.map((items: any) => (
              <BaseProductCard
                key={items.id}
                product={items}
                addToCartListener={handleClick}
                RedirectTo={RedirectTo}
                ifOnVendorPage={ifOnVendorPage}
              />
            ))
          ) : (
            <div className="text-center my-12">
              <span className="">No Related Products</span>
            </div>
          )
        ) : (
          <div className="flex space-x-8">
            <SkeletonLoader
              skeletonType={
                location.pathname.includes("/search") &&
                currentVendor !== "mobile_sentrix"
                  ? "SearchProductSkeleton"
                  : "ProductCardSkeleton"
              }
              defaultSkeletonHeight={""}
              smallInputWidth={""}
              smallInputHeight={""}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default withReducer("vendor", reducer)(ProductCard);
