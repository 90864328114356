import { useMatch } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { Menu, Pagination } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router";
import queryString from "query-string";
import * as CommonActions from "store/actions";
import {
  fetchProducts,
  fetchShopifyProducts,
} from "main/VendorHomePage/store/actions";
import withReducer from "store/withReducer";
import reducer from "main/VendorHomePage/store/reducers";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { ProductCards } from "Sections/ProductCards/ProductCards";
import CategoryFilter from "components/CategoryFilter/CategoryFilter";
import { Capitalize, dataPoints } from "utils/CommonFunctions";
import { checkIfVendorConnected } from "services/HelperService/HelperService";
import { getShopifySidemenu } from "store/actions";

const SearchResultPage: React.FC = () => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const prevVendor = useRef();
  const isDefault = useRef(true);
  const location = useLocation();
  const { slug } = useParams();
  const { id: parsed } = useParams();
  const ifOnVendorPage = useMatch("/vendor/:slug/*");
  const { category: queryCategory } = queryString.parse(location.search);
  // Reducers
  const { data: userReducer }: any = useSelector(
    ({ autReducer }: any) => autReducer.GetUserReducer
  );
  const { data: shopifySideMenuData, isLoading } = useSelector(({ commonReducers }: any) => commonReducers.GetShopifySideMenuReducer);
  const { data: categoryItemsData, isLoading: categoryItemLoading } =
    useSelector(({ itemsReducer }: any) => itemsReducer.FetchProductsReducer);
  const { data: shopifyProducts, isLoading: isShopifyLoading } = useSelector(
    ({ itemsReducer }: any) => itemsReducer.FetchShopifyProductsReducer
  );
  const { data: searchResults, isLoading: searchLoading } = useSelector(
    ({ commonReducers }: any) => commonReducers.SearchItemsReducer
  );
  const { data: vendorData } = useSelector(
    ({ commonReducers }: any) => commonReducers.GetVendorsSlugReducer
  );
  const {
    GetGlobalVendorSearchReducer: { data },
  }: any = useSelector((state) => state);
  // Destructure Data
  const searchName = slug;
  const searchData = searchResults?.items?.docs;
  let pathChunks = location?.pathname?.substring(1).split("/");
  const currentVendor = pathChunks[1];
  let categoryItems = categoryItemsData?.data?.filter;
  let ifConnected = checkIfVendorConnected(currentVendor);
  const extUserId = userReducer?.data?._doc?.rdUser;
  const extStoreId = userReducer?.data._doc?.storeId
  const extStoreName = vendorData?.storeType;
  // states
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentLimit, setCurrentLimit] = useState<number>(50);
  const [productCardData, setProductData] = useState<any>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [isCard, setIsCard] = useState(true);
  const [, setCardType] = useState(isCard ? "ProductListView" : "ProductCard");
  // CategoryFilter States
  const [currentSelected, setCurrentSelected] = useState<any>(false);
  const filterPartName = categoryItems?.partTypes
  const filterPartId = categoryItems?.partTypes?.map(fval => fval?.value)
  // shopify filter data
  const filterSimlock = shopifySideMenuData?.simLockStatuses
  const filterFunctional = shopifySideMenuData?.functionalStatuses
  const filterPriceRange = shopifySideMenuData?.priceRange
  const filterModels = shopifySideMenuData?.models
  const filterCosmeticgrade = shopifySideMenuData?.consmeticGrades
  const filterColor = shopifySideMenuData?.colors
  const filterStorage = shopifySideMenuData?.storages
  // Filter Body States
  const filterCategory = categoryItems?.categoryName
  const [selected, setSelected] = useState<any>([]);
  const [selectedfilter, setSelectedFilter] = useState<any>([]);
  const [valueobtained, setValueObtained] = useState<any>([]);
  const [priceValues, setPriceValues] = useState<any>([]);
  const [selectcategory, setSelectCategory] = useState();
  const [selectPrev, setSelectPrev] = useState<any>([]);
  const [removeIdValue, setRemoveIdValue] = useState<any>([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const minVal = shopifySideMenuData?.priceRange?.length > 0 ? shopifySideMenuData?.priceRange[0]?.minValue : ''
  const maxVal = shopifySideMenuData?.priceRange?.length > 0 ? shopifySideMenuData?.priceRange[0]?.maxValue : ''
  // useEffect
  useEffect(() => {
    let prev = selectcategory
    let prevSel = selectPrev
          let selectedArray;
          selected.map((elem)=>{
            selectedArray = elem.name
          })
          if(currentVendor === 'injured_gadgets' && currentPage === 1 && selected.length <= 0) {
            dispatch(fetchProducts(currentVendor, currentPage, 50, {
              filter: { keyword: parsed, categoryId: queryCategory, partTypes: [] }
          }))
        }
        if(currentVendor === 'injured_gadgets' && currentPage === 1 && selected.length > 0) {
      dispatch(fetchProducts(currentVendor, currentPage, 50, {
        filter: { keyword: parsed, categoryId: queryCategory, partTypes: prev || prevSel.length > 0 ? [...selected.map(elem => elem.name)] : [] }
      }))
    }
    if(currentVendor === 'injured_gadgets' && currentPage > 1 && selected.length > 0) {
      dispatch(fetchProducts(currentVendor, currentPage, 50, {
        filter: { keyword: parsed, categoryId: queryCategory, partTypes: prev || prevSel.length > 0 ? [...selected.map(elem => elem.name)] : [] }
      }))
    }
    if(currentVendor === 'injured_gadgets' && currentPage > 1 && selected.length === 0) {
      dispatch(fetchProducts(currentVendor, currentPage, 50, {
            filter: { keyword: parsed, categoryId: queryCategory, partTypes: prev || prevSel.length > 0 ? [...selected.map(elem => elem.name)] : [] }
          }))
    }
    if(currentVendor === '2ndlifephones'){
      let bodyParser = {
        consmeticGrades: selected.filter(item => item.titleId === 4).map(item => item.id),
        simLockStatuses: selected.filter(item => item.titleId === 0).map(item => item.id),
        functionalStatuses: selected.filter(item => item.titleId === 1).map(item => item.id),
        storages: selected.filter(item => item.titleId === 6).map(item => item.id),
        models: selected.filter(item => item.titleId === 3).map(item => item.id),
        colors: selected.filter(item => item.titleId === 5).map(item => item.id),
        brands: [],
        keyword: parsed,
        vendor: currentVendor,
        limit: 50,
        page: currentPage,
        priceRange: {
          min: valueobtained[0] || priceValues[0],
          max: valueobtained[1] || priceValues[1] 
        }
      }
      dispatch(fetchShopifyProducts(bodyParser))
    }
  },[currentPage])
  useEffect(() => {
    console.log('parsed and queryCategory useEffect')
    setCurrentPage(1)
    setSelected([])
    if(parsed && currentVendor !== 'mobile_sentrix'){
      dispatch(fetchProducts(currentVendor, currentPage, 50, {
        filter: { keyword: parsed, categoryId: queryCategory, partTypes: [] }
      }))
    }
    if (parsed && currentVendor === 'mobile_sentrix' && queryCategory) {
      console.log('parsed & mobileSentrix');
      dispatch(fetchProducts(currentVendor, 1, 50, {
        filter: { keyword: parsed, categoryId: queryCategory, partTypes: [] }
      }))
    }
  }, [parsed, queryCategory])
  useEffect(() => {
    console.log('currentPage useEffect')
    if (currentVendor === 'mobile_sentrix') {
      dispatch(fetchProducts(currentVendor, currentPage, 50, {
        filter: { keyword: parsed, categoryId: queryCategory, partTypes: [] }
      }))
    }
  },[currentPage])
  // Filter Menu
  const filterMenu =
    currentVendor !== '2ndlifephones' ?
    [
      { id: filterPartId, categoryTitle: "Part Type", categoryName: filterPartName },
    ]
    :
    [
      { id: 0, categoryTitle: "Sim Lock Status", categoryName: filterSimlock },
      { id: 1, categoryTitle: "Functional Status", categoryName: filterFunctional },
      { id: 2, categoryTitle: "Price", categoryName: filterPriceRange },
        { id: 3, categoryTitle: "Model", categoryName: filterModels },
        { id: 4, categoryTitle: "Cosmetic Grade", categoryName: filterCosmeticgrade },
        { id: 5, categoryTitle: "Color", categoryName: filterColor },
        { id: 6, categoryTitle: "Storage", categoryName: filterStorage },
      ]
      useEffect(() => {
        if (categoryItemsData) {
          setTotalItems(categoryItemsData?.data?.items?.totalDocs);
          const items = categoryItemsData?.data?.items?.docs;
      if (!items || !items?.length) return;
      const parsedItems = items?.map((item) => {
        return {
          id: item._id,
          name: item.name,
          qty: item.qty,
          imageSrc: item.imageUrls[0],
          imageAlt: "Product Image",
          price: item.costPrice,
          vendor: item.vendor,
          vendorId: item.vendorId,
        };
      });
      setProductData(parsedItems);
    }
  }, [categoryItemsData]);
  console.log('object', selected);
  useEffect(() => {
    if (shopifyProducts) {
      setTotalItems(shopifyProducts.data?.items.totalDocs);
      const items = shopifyProducts.data?.items.docs;
      if (!items || !items.length) return;
      const parsedItems = items.map((item) => {
        return {
          id: item._id,
          name: item.name,
          qty: item.qty,
          imageSrc: item.imageUrls,
          imageAlt: "Product Image",
          price: item.costPrice,
          vendor: item.vendor,
          vendorId: item.vendorId,
        };
      });
      setProductData(parsedItems);
    }
  }, [shopifyProducts]);

  useEffect(() => {
    if (currentVendor === "2ndlifephones") {
      dispatch(getShopifySidemenu(currentVendor));
    }
  },[]);
  const handleScroll = () => {
    const position = window.scrollY;
    if(position > 10){
      setScrollPosition(position);
    }
    else {
      setScrollPosition(0)
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // Functions
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };
  const RedirectToProduct = (product) => {
    navigate(`/vendor/${slug}/${product.id}`);
    window.scrollTo(0, 0);
  };
  const changeView = () => {
    setIsCard(!isCard);
    setCardType("ProductCard");
  };
  let temp = [] as any
  const onChangeListener = (event, category) => {
    var valueObtained = event
    setValueObtained(valueObtained)
    var minValueForPrice = minVal
    var maxValueForPrice = maxVal
    if (valueObtained) {
      if (valueObtained[0] < minVal) {
        setPriceValues(event)
        minValueForPrice = valueObtained[0]
        maxValueForPrice = valueObtained[1]
      }
      else if (valueObtained[1] > minVal) {
        setPriceValues(event)
        maxValueForPrice = valueObtained[1]
        minValueForPrice = valueObtained[0]
      }
    }
    const checked = event?.target?.checked
    if (checked || valueObtained.length) {
      setCurrentPage(1)
      setCurrentSelected(checked)
      const categoryName = category?.value
      const selectedItem = [...selected, { id: category?.value, name: category?.name, titleId: category?.titleId }]
      const sss = [...selected].map(d => d.id)
      temp = selectedItem
      setSelected(temp)
      setSelectedFilter(selectedItem)
      setSelectCategory(categoryName)
      setSelectPrev(sss)
      let bodyParser = {
        consmeticGrades: selectedItem.filter(item => item.titleId === 4).map(item => item.id),
        simLockStatuses: selectedItem.filter(item => item.titleId === 0).map(item => item.id),
        functionalStatuses: selectedItem.filter(item => item.titleId === 1).map(item => item.id),
        storages: selectedItem.filter(item => item.titleId === 6).map(item => item.id),
        models: selectedItem.filter(item => item.titleId === 3).map(item => item.id),
        colors: selectedItem.filter(item => item.titleId === 5).map(item => item.id),
        brands: [],
        keyword: parsed,
        vendor: currentVendor,
        limit: 50,
        page: currentPage,
        priceRange: {
          min: valueObtained[0] || priceValues[0],
          max: valueObtained[1] || priceValues[1]
        }
      }
      currentVendor !== '2ndlifephones' ?
        dispatch(
          fetchProducts(currentVendor, currentSelected ? 1 : currentPage, 50, {
            filter: { keyword: parsed, categoryId: queryCategory, partTypes: [...sss, categoryName] }
          }))
        :
        dispatch(fetchShopifyProducts(bodyParser))
      dataPoints(extUserId, 'Filter_Clicked', extStoreId, `Marketplace | ${currentVendor} | ${categoryName || minValueForPrice + ' , ' + maxValueForPrice}`,)
    }
    else {
      setCurrentPage(1)
      setCurrentSelected(!checked)
      const c = selected.filter(sl => sl.id !== category?.value)
      temp = c
      const removeId = temp.map(t => t.id)
      setRemoveIdValue(removeId)
      setSelected(temp)
      let bodyParser = {
        consmeticGrades: selected.filter(item => item.titleId === 4 && item.id !== category?.value).map(item => item.id),
        simLockStatuses: selected.filter(item => item.titleId === 0 && item.id !== category?.value).map(item => item.id),
        functionalStatuses: selected.filter(item => item.titleId === 1 && item.id !== category?.value).map(item => item.id),
        storages: selected.filter(item => item.titleId === 6 && item.id !== category?.value).map(item => item.id),
        models: selected.filter(item => item.titleId === 3 && item.id !== category?.value).map(item => item.id),
        colors: selected.filter(item => item.titleId === 5 && item.id !== category?.value).map(item => item.id),
        brands: [],
        keyword: parsed,
        vendor: currentVendor,
        limit: 50,
        page: currentPage,
        priceRange: {
          min: valueObtained[0] || priceValues[0],
          max: valueObtained[1] || priceValues[1]
        }
      }
      currentVendor !== '2ndlifephones' ?
        dispatch(
          fetchProducts(currentVendor, 1, 50, {
            filter: { keyword: parsed, categoryId: queryCategory, partTypes: [...removeId] },
          }))
        :
        dispatch(fetchShopifyProducts(bodyParser))
    }
  }
  return (
    <div className={`w-full 3xl:w-10/12 mx-auto 4xl:w-9/12 overflow-hidden`}>
      <div className="mt-6 mx-6">
        <Breadcrumbs
          currentPage={Capitalize(slug)}
          currentPageSlug={slug}
          location={location}
          id={undefined}
          productId={undefined}
        />
      </div>
      <div className="pt-6">
        <div className="flex">
          {/* Filters */}
          {currentVendor === "mobile_sentrix" ? (
            ""
          ) : (
            <div className={`lg:ml-6 sm:ml-0 fixed h-screen ${!scrollPosition ? 'top-[194px]' : 'top-[130px]' } `}>
              <CategoryFilter
                onChangeListeners={onChangeListener}
                currentPagee={currentPage}
                extStoreId={extStoreId}
                keyword_Name={parsed}
                minVal={minVal}
                maxVal={maxVal}
                selected={selected}
                filterMenu={filterMenu}
                extUserId={extUserId}
                queryCategory={queryCategory}
                extStoreName={extStoreName}
                currentVendor={slug}
                levels={vendorData?.levels || []}
                categoriesData={categoryItems}
                prevVendor={prevVendor}
                parsed={parsed}
                categoryItemLoading={categoryItemLoading}
                isLoading={isLoading}
                scrollPosition={scrollPosition}
              />
            </div>
          )}
          <div className={`flex-1 ${currentVendor !== 'mobile_sentrix' ? 'ml-[360px]' : '' } flex-wrap shrink-1 overflow-auto`}>
            <ProductCards
              isShopifyLoading={isShopifyLoading}
              data={data}
              queryCategory={queryCategory}
              categoryItemsData={categoryItemsData}
              onClick={RedirectToProduct}
              cardType={
                !isCard || currentVendor === "2ndlifephones"
                  ? "ProductListView"
                  : "ProductCard"
              }
              cardHeight={""}
              changeView={changeView}
              productCardData={(parsed ? productCardData : searchData) || []}
              // productCardData={
              //   parsed
              //     ? productCardData
              //     : currentVendor === "2ndlifephones"
              //     ? productCardData
              //     : searchData
              // }
              totalItems={totalItems}
              productsLoading={searchLoading || categoryItemLoading}
              RedirectTo={undefined}
              slug={slug}
              searchName={parsed}
              productId={undefined}
              vendorName={undefined}
              handleSort={undefined}
              sortType={undefined}
              parsed={parsed}
              extUserId={""}
              extStoreName={extStoreName}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end items-center mr-6 mb-6">
        <Pagination
          hideOnSinglePage={true}
          onChange={handleChangePage}
          total={
            currentVendor !== "2ndlifephones"
              ? categoryItemsData?.data?.items?.totalDocs || 0
              : shopifyProducts?.data?.items?.totalDocs || 0
          }
          pageSizeOptions={[]}
          defaultPageSize={50}
          current={currentPage}
          pageSize={currentLimit}
        />
      </div>
    </div>
  );
};

export default withReducer("itemsReducer", reducer)(SearchResultPage);
