import { combineReducers } from "redux";
import commonReducers from "./common";
// import auth from "app/auth/store/reducers";
import autReducer from "auth/store/reducers";
import { GetGlobalSearchReducer,GetGlobalVendorSearchReducer } from "main/ProductPage/store/reducers/product.reducer";
import { GetCartReducer } from "./common/cart.reducer";
const createReducer = (asyncReducers) =>
  combineReducers({
    autReducer,
    commonReducers,
    GetGlobalSearchReducer,
    GetGlobalVendorSearchReducer,
    GetCartReducer,
    ...asyncReducers,
  });

export default createReducer;
