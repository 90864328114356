import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
// import { svgIcons } from "../../assets/Svg/Svg"
import { svgIcons } from "../../assets/svg";
import { Badges } from "../../components/Badges/Badges"
import * as CommonActions from "../../store/actions"
import * as Actions from "store/actions";
import { Card } from "../../components/Card/Card"
import { MenuDropdown } from "../../components/MenuDropdown/MenuDropdown"
import SkeletonLoader from "components/SkeletonLoader/SkeletonLoader";
import { Capitalize, dataPoints } from "utils/CommonFunctions";
import { Notification, openNotificationWithIcon } from "components/Notification";
import { useEffect, useState } from "react";
import { useMatch } from "react-router";
import { SearchIcon } from "@heroicons/react/outline";

interface ProductCardProps {
  onClick?: any
  cardType: string
  cardHeight: string
  changeView: any
  totalItems: number
  productCardData: any
  productsLoading: boolean
  RedirectTo: any
  slug: any
  searchName: any
  productId: any
  vendorName: any
  handleSort: any
  sortType: any
  queryCategory: any
  data: any
  categoryItemsData: any
  parsed: any
  extUserId: string
  extStoreName: any
  isShopifyLoading: boolean
}

export const ProductCards: React.FC<ProductCardProps> = ({cardType, extUserId, isShopifyLoading, cardHeight, changeView, productCardData, totalItems,productsLoading, RedirectTo, slug, searchName, productId, vendorName, handleSort, sortType, extStoreName, queryCategory, categoryItemsData, data, parsed}) => {
  // Hooks
  const location = useLocation()
  const dispatch = useDispatch()
  const ifOnVendorPage = useMatch({ path: "/vendor/:slug/:id", end: true })
  const ifOnVendorHomePage = useMatch({ path: "/vendor/:slug", end: true })
  
  // Reducer
  const { data: userReducer }: any = useSelector(({ autReducer }: any) => autReducer.GetUserReducer)
    const { data: shopifyProducts } = useSelector(({ vendor }: any) => vendor.FetchShopifyProductsReducer);
  // States
  const [hidePrice, setHidePrice] = useState<any>(false)

  let allTheProd = categoryItemsData?.data?.items?.docs;
  let secondlifeProd = shopifyProducts?.data?.items?.docs
  const [found, setFound] = useState([]);

  // Extract
  const extStoreId = userReducer?.data._doc?.storeId
  const pathChunks = location?.pathname?.substring(1).split("/")
  const currentVendor = pathChunks[1]
  const onClick = (v) => {
    const userId = userReducer?.data?._doc?.rdUser
    const prodData = {
      quantity: v?.quantity,
      vendor: v?.product?.vendor,
      item: v?.product?._id || v?.product?.id,
      vendorId: v?.product?.vendorId
    }
    dispatch(CommonActions.updateCart(prodData, 1, ()=>{
      setTimeout(() => {
        openNotificationWithIcon("success", `Product added successfully!`, "Open cart to see the product.")
      }, 600);
    }));
    dataPoints(userId, 'Add_To_Cart_Clicked', extStoreId, `Marketplace | ${v.product.vendor} | Vendor Page`)
  }
  const [loc, setLoc] = useState<any>()
  const changePrice = () => {
    setHidePrice(!hidePrice)  
  }
useEffect(() => {
  setLoc(localStorage.setItem('Name', hidePrice))
  setLoc(localStorage.getItem('Name'))
},[localStorage.getItem('Name'),hidePrice])
  // CSS Variables
  const max_width = "94rem"
  // const [storeKeyword, setStoreKeyword] = useState('')
  //  const handleChangeForSearch = (event) => {
  //    let searchWord = event.target.value;
  //   const foundProducts = !ifOnVendorHomePage ? allTheProd?.filter((elem) => {
  //     if (elem?.name?.toLowerCase().includes(searchWord.toLowerCase())) {
  //       return elem;
  //     }
  //   }) : currentVendor !== '2ndlifephones' ?
  //   productCardData?.filter((elem) => {
  //     if (elem?.name?.toLowerCase().includes(searchWord.toLowerCase())) {
  //       return elem;
  //     }
  //   })
  //   :
  //   secondlifeProd?.filter((elem) => {
  //     if (elem?.name?.toLowerCase().includes(searchWord.toLowerCase())) {
  //       return elem;
  //     }
  //   })
  //   // setStoreKeyword(searchWord)
  //   setFound(foundProducts);
  // };
  // useEffect(() => {
  //   console.log('in useEffect');
  //   if (changePage || storeKeyword.length) {
  //     setFound([])  
  //   }

  // },[changePage])
  // console.log(found, storeKeyword, "<===========This is the found one==========>");
  return (
    <div>
      <div className={`w-${max_width} px-4 mb-14 sm:px-6`}>
        <div className="flex items-center justify-between">
          <p className="flex items-center text-2xl font-semibold text-secondary-700">{location.pathname === `/vendor/${vendorName}/${productId}` ? 'Related Products' : 'Products'}
          {(!productsLoading && !isShopifyLoading) ? location.pathname.includes('/search') ? <div className="md:hidden lg:hidden xl:block"><span className="text-xl text-primary-500 ml-2">{parsed || queryCategory ? '(' + totalItems : 0}</span><span className="text-xl text-secondary-600 ml-2">Items found for <span className="text-xl text-primary-500">{Capitalize(parsed?.length > 30 ? parsed?.slice(0,30) + "..." : parsed)+')'}</span></span></div> 
          : 
           !ifOnVendorPage ? <span className="ml-3"><Badges badgeBgColor="#DBEAFE" badgeTextColor="#1E40AF" badgeContent={`${totalItems} Products`} textSize={'text-sm'} textWeight={'font-medium'} badgeWidth={"130px"}/></span> 
          : 
          ''
          : 
          <span className="ml-3">
            <SkeletonLoader skeletonType={'smallInputSkeleton'} defaultSkeletonHeight={""} smallInputWidth={"90px"} smallInputHeight={"25px"} />
          </span>
          }
          </p>
          <div className="flex items-center">
            {/* {!ifOnVendorPage && (
              <div className="mr-3 flex items-center">
                <SearchIcon
                  className="h-4 w-4 text-secondary-400 absolute ml-2"
                  aria-hidden="true"
                />
                <input
                  onChange={handleChangeForSearch}
                  id="search"
                  name="search"
                  className="block w-200 h-9 pl-8 pr-3 py-2 shadow border border-secondary-300 rounded-md leading-5 text-base bg-white text-secondary-500 focus:outline-none focus:text-secondary-400 focus:ring-1 focus:ring-secondary-300 focus:border-secondary-300 sm:text-sm w-[15vw] mr-1"
                  placeholder="Search"
                  type="search"
                  autoComplete="off"
                />
              </div>
            )
              } */}
            <div onClick={changePrice} className="flex items-center">
              <p className={`flex items-center ${currentVendor === '2ndlifephones' ? 'mr-0' : 'mr-4'} text-sm border border-none rounded-[12px] ${loc === 'false' ? 'bg-red-300' : 'bg-green-300' } h-[26px] px-3 cursor-pointer`}>
              {loc === 'false' ? 'Hide price' : 'Show price'}
              </p>
            </div>
            {currentVendor === '2ndlifephones' ?
            ''
            :
            <div className="flex items-center" onClick={changeView}>
              {svgIcons.listIcon}
            </div>
}
          </div>
        </div>
        <Card searchProduct={found} onClick={onClick} cardHeight={cardHeight} cardType={cardType} VendorData={undefined} CardData={undefined} ProductCardData={productCardData} tickerLoading={false} isLoading={false} vendorLoading={false} productsLoading={productsLoading} RedirectTo={RedirectTo} slug={slug} searchName={searchName} isShopifyLoading={isShopifyLoading} />
      </div>
    </div>
  )
}
