import { useDispatch, useSelector } from 'react-redux'
import { Disclosure, Tab } from '@headlessui/react'
import { MinusSmIcon, PlusSmIcon } from '@heroicons/react/outline'
import { Button } from '../../components/Button/Button'
import { svgIcons } from '../../assets/svg'
import { classNames, dataPoints } from '../../utils/CommonFunctions'
import { QuantityCounter } from '../../components/QuantityCounter/QuantityCounter'
import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader'
import { useEffect, useState } from "react"
import { checkIfVendorConnected } from "../../services/HelperService/HelperService"
import * as CommonActions from "../../store/actions"
import * as Actions from "store/actions";
import Modal from "../../components/Modal/Modal"
import PlaceholderImg from '../../assets/images/placeholder-image.png'
import dots from '../../assets/images/3dot primary.svg'
import { openNotificationWithIcon } from 'components/Notification'


export default function ProductSection({ singleProduct, addToCartListener, extUserId, extName, currentVendor, cartData, isProductLoading, updateLoading, alreadyInCart, RedirectTo, data, wishListData, productId, itemSku }: any) {
  // Hooks
  const dispatch = useDispatch()
  // Product Features
  const features = [
    { name: 'Manufacturer', detail: singleProduct?.manufacturer },
    { name: 'Model', detail: singleProduct?.model },
    { name: 'Feature', detail: singleProduct?.metaDescription },
    { name: 'Compatible With', detail: singleProduct?.device?.map(device => device) },
  ]
  // Reducer
  const { data: userReducer }: any = useSelector(({ autReducer }: any) => autReducer.GetUserReducer)
  // Parsed Description
  const rgx = /\{{(.*?)\}}/gi
  const description = singleProduct?.description?.replace(rgx, "")
  const cartDataShort = cartData?.cartItems?.map(cart => cart?.item?._id)
  const cartTotalQuantity = cartData?.cartItems?.find(i => i?.item?._id === singleProduct?._id)?.quantity
  const extStoreId = userReducer?.data._doc?.storeId
  const discountQty = singleProduct?.qtyArray?.map(item => item?.Price) || ''
  // States
  const [quantity, setQuantity] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(updateLoading)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [wishListIcon, setWishListIcon] = useState(false)
  const [priceHide, setPriceHide] = useState(false)
  // Product Quantity Difference
  const diff = singleProduct?.qty - cartTotalQuantity
  // Functions
  const handleAddToCart = () => {
    const alreadyAuthenticated = checkIfVendorConnected(singleProduct.vendor)
    if (!alreadyAuthenticated) {
      setIsModalVisible(true)
      return
    }
    setIsLoading(!updateLoading)
    const data = {
      quantity: quantity === 0 ? quantity + 1 : quantity,
      vendor: singleProduct.vendor,
      vendorId: singleProduct.vendorId,
      item: singleProduct._id
    }
    addToCartListener(data)
  }
  const handleWishList = ({ item, action }) => {
    dispatch(CommonActions.updateWishList(item, action, () => {
      if(action === 0){
        openNotificationWithIcon("error", `Item removed from Wishlist!`,'')
        dataPoints(extUserId, 'Removed_From_Wishlist', extStoreId, `Marketplace | ${currentVendor} | Product Page`)
      }
      else {
        openNotificationWithIcon("success", `Item added in Wishlist!`, "Open wishlist to see the item.")
        dataPoints(extUserId, 'Added_Wishlist', extStoreId, `Marketplace | ${currentVendor} | Product Page`)
      }
    }));
  };
  // useEffect
  useEffect(() => {
    if (!updateLoading) {
      setIsLoading(false)
    }
  }, [updateLoading])
  useEffect(() => {
    setWishListIcon(wishListData?.items?.filter(e => e._id === productId).length>0)
  }, [wishListData, productId])
  useEffect(() => {
    if(data) setWishListIcon(data?.inWishList)
  }, [data]);
  // CSS varibles
  const max_width = "94rem"
  const imgHeight = "592px"

  return (
    <>
      <Modal open={isModalVisible} setOpen={setIsModalVisible}  dialogTitle="Connection!" dialogMessage='Please connect to the vendor.' onClick={() => { setIsModalVisible(false); RedirectTo()}}  onCancel={() => setIsModalVisible(false)} />
      <div className="bg-white mx-6 mb-6 rounded-lg">
        <div className={`mx-auto w-${max_width} 4xl:w-${max_width} py-16 px-4 sm:py-24 sm:px-6 lg:px-8`}>
          <div className="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
            {/* Image gallery */}
            <Tab.Group as="div" className="flex flex-col-reverse">
              {/* Image selector */}
              <div className="hidden mt-6 w-full max-w-2xl mx-auto sm:block lg:max-w-none">
                <Tab.List className="grid grid-cols-4 gap-6">
                  {singleProduct?.imageUrls.map((image) => (
                    <Tab
                      key={image.id}
                      className="relative h-24 bg-white rounded-md flex items-center justify-center text-sm font-medium uppercase cursor-pointer hover:bg-secondary-50 focus:outline-none focus:ring focus:ring-offset-4 focus:ring-opacity-50"
                    >
                      {({ selected }) => (
                        <>
                          <span className="absolute inset-0 rounded-md overflow-hidden">
                            <img src={image || dots} alt="" className="w-full h-full object-center object-contain" />
                          </span>
                          <span
                            className={classNames(
                              selected ? 'ring-primary-500' : 'ring-secondary-300',
                              'absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none'
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </Tab>
                  ))}
                </Tab.List>
              </div>

              <Tab.Panels className="w-full aspect-w-1 aspect-h-1">
                {!isProductLoading ? singleProduct?.imageUrls?.length > 0 ? singleProduct?.imageUrls.map((image) => (
                  <Tab.Panel key={image.id} className="border border-secondary-300  rounded-lg">
                    <img
                      src={image}
                      alt={image}
                      style={{height: imgHeight}}
                      className={`w-full object-center ${image.height === imgHeight ? 'object-contain' : ''}  sm:rounded-lg`}
                    />
                  </Tab.Panel>
                ))
                  :
                  <div><img src={PlaceholderImg} alt='' className="w-full h-full m-0 p-0 object-cover  sm:rounded-lg"/></div>
                  :
                  <SkeletonLoader skeletonType={'default'} defaultSkeletonHeight={'592px'} smallInputWidth={''} smallInputHeight={''} />
                }
              </Tab.Panels>
            </Tab.Group>

            {/* Product info */}
            <div className="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0">
              {!isProductLoading ?
                <h1 className="text-3xl font-semibold tracking-tight text-secondary-700">{singleProduct?.name?.length < 80 ? singleProduct?.name : singleProduct?.name?.slice(0, 100) + '...'}</h1>
                :
                <SkeletonLoader skeletonType={'default'} defaultSkeletonHeight={'72px'} smallInputWidth={''} smallInputHeight={''} />
              }
              <div className="mt-3 flex items-center">
                <h2 className="sr-only">Product CostPrice</h2>
                {
                  !priceHide ?
                  <p className="text-3xl text-secondary-600">{!isProductLoading ? "$" + parseFloat(singleProduct?.costPrice).toFixed(2) : <SkeletonLoader skeletonType={'smallInputSkeleton'} defaultSkeletonHeight={''} smallInputWidth={'72px'} smallInputHeight={'36px'} />}</p>
                  :
                  ''
                }
                <p className={`flex items-center text-sm text-secondary-500 border rounded-[12px] bg-secondary-100 h-[26px] px-3 cursor-pointer ${priceHide ? 'ml-0' : 'ml-4'} hover:bg-secondary-200`} onClick={() => setPriceHide(!priceHide)}>{!priceHide ? 'Hide price' : 'Show price'}</p>
                <div className='w-[auto] h-[26px] border flex items-center ml-4 px-3 rounded-[12px] bg-secondary-100'>SKU:<span className='text-sm text-secondary-500 pl-1'>{itemSku}</span></div>
              </div>

              <div className="mt-6">
                <h3 className="sr-only">Description</h3>
                {!isProductLoading ?
                  <div
                    className="text-base text-secondary-700 space-y-6"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                  :
                  <SkeletonLoader skeletonType={'default'} defaultSkeletonHeight={'72px'} smallInputWidth={''} smallInputHeight={''} />
                }
              </div>

              <div className='add-cart-buttons flex items-center mt-6'>
                {/* Add / Remove button */}
                <QuantityCounter productQuantity={singleProduct?.qty} alreadyInCart={isLoading} onValueChange={v => setQuantity(v)} btnHeight='36px' btnWidth='36px' inputHeight='36px' inputWidth='36px' />
                {cartData?.cartItems?.length ? 
                  <div className="mb-[45px]">
                    {alreadyInCart &&
                    <div className="absolute text-xs flex items-center ml-2 justify-center text-white  h-5 w-5 rounded-full bg-red-500">{
                      cartTotalQuantity}</div>
                    }
                    </div>
                  :
                  ''
              }
              <div className='ml-4'>{svgIcons.cart}</div>
              </div>
              <div className="mt-6 flex items-center">
                {/* Add to cart btn */}
                <Button onClick={cartTotalQuantity === singleProduct?.qty || quantity > diff || singleProduct?.qty === 0 ? () => {} : handleAddToCart} alreadyInCard={undefined} quantity={quantity} isLoading={isLoading} btnType="ProductCardLargeBtn" btnWidth='320px' btnHeight='40px' defaultBgColor={''} />
                <div className='ml-4 cursor-pointer' onClick={() => { handleWishList({ item: data?._id, action: wishListIcon ? 0 : 1 });}}>
                { wishListIcon === true ?
                  svgIcons.heartRed : svgIcons.heart
                }
                </div>
              </div>
              <section aria-labelledby="details-heading" className="mt-12">
                                {/* Rounded Quantity discount */}
                                {currentVendor === 'rounded' ?
                  <div className="border-t divide-y divide-secondary-200">
                    <Disclosure as="div" defaultOpen key={singleProduct?.name}>
                      {({ open }) => (
                        <>
                          <h3>
                            <Disclosure.Button className="group relative w-full py-6 flex justify-between items-center text-left">
                              <span
                                className={classNames(open ? 'text-primary-600' : 'text-secondary-600', 'text-sm font-medium')}
                              >
                                Our Quantity Discount
                              </span>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <MinusSmIcon
                                    className="block h-6 w-6 text-secondary-400 group-hover:text-secondary-500"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <PlusSmIcon
                                    className="block h-6 w-6 text-secondary-400 group-hover:text-secondary-500"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel as="div" className=" pb-6 prose prose-sm">
                                      <div className="sm:px-0 lg:px-0">
                                      <div className="flex flex-col">
                                        <div className="-my-2 overflow-x-auto sm:-mx-0 lg:-mx-0">
                                          <div className="inline-block  rounded-lg min-w-full py-2 align-middle md:px-0 lg:px-0">
                                            <div className="overflow-hidden">
                                              <table className="min-w-full">
                                                <thead className="bg-secondary-50">
                                                  <tr>
                                                    <th scope="col" className="py-3 pl-4 pr-3 text-left font-semibold text-sm text-secondary-500 sm:pl-6">
                                                      Quantity
                                                    </th>
                                                    <th scope="col" className="px-3 py-3 text-center font-semibold text-sm text-secondary-500">
                                                      2+
                                                    </th>
                                                    <th scope="col" className="px-3 py-3 text-center font-semibold text-sm text-secondary-500">
                                                      5+
                                                    </th>
                                                    <th scope="col" className="px-3 py-3 text-center font-semibold text-sm text-secondary-500">
                                                      10+
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody className="divide-y-8 divide-secondary-200 bg-white border-t">
                                                  <tr>
                                                    <td className='text-left py-1 pl-6'>Price</td>
                                                    <td className='text-center py-1'>{discountQty[1] || ''}</td>
                                                    <td className='text-center py-1'>{discountQty[2] || ''}</td>
                                                    <td className='text-center py-1'>{discountQty[3] || ''}</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                  :
                  ''
                }
                {features.map(feature => (
                  <div className="border-t divide-y divide-secondary-200">
                    <Disclosure as="div" defaultOpen key={singleProduct?.name}>
                      {({ open }) => (
                        <>
                          <h3>
                            <Disclosure.Button className="group relative w-full py-6 flex justify-between items-center text-left">
                              <span
                                className={classNames(open ? 'text-primary-600' : 'text-secondary-600', 'text-sm font-medium')}
                              >
                                {feature.name}
                              </span>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <MinusSmIcon
                                    className="block h-6 w-6 text-secondary-400 group-hover:text-secondary-500"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <PlusSmIcon
                                    className="block h-6 w-6 text-secondary-400 group-hover:text-secondary-500"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel as="div" className=" pb-6 prose prose-sm">
                            <ul>
                              {feature?.name === 'Feature' ?
                                <span className="list-disc text-sm text-secondary-500 my-2">
                                  {feature?.detail}
                                </span>
                                :
                                <span className="inline-flex items-center px-2.5 py-0.5 mr-3 mb-3 rounded-md text-sm font-medium bg-secondary-100 text-secondary-800">
                                  {feature?.detail}
                                </span>
                              }
                            </ul>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                ))}
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
