import axios from 'axios'
export const SEARCH_ITEMS = "SEARCH_ITEMS";
export const SEARCH_ITEMS_IS_lOADING = "SEARCH_ITEMS_IS_lOADING";
export const SEARCH_ITEMS_FAILED = "SEARCH_ITEMS_FAILED";



// HELPER ACTIONS CREATORS
//************************************** /



export const searchItemsIsLoading = () => ({
    type: SEARCH_ITEMS_IS_lOADING,
    payload: {}
});

export const searchItemsFailed = (error) => ({
    type: SEARCH_ITEMS_FAILED,
    payload: error
});

export function searchItems(vendor, keyword, page = 1, limit = 30, sort = -1) {
    // keyword,  page,    limit,    sort

    const endPoint = `/search/filter-items/${vendor}?keyword=${keyword}&page=${page}&limit=${limit}&sort=${sort}`
    return dispatch => {
        dispatch(searchItemsIsLoading());

        axios.get(endPoint)
            .then(({ data }) => {

                dispatch({
                    type: SEARCH_ITEMS,
                    payload: data
                });
            })
            .catch(error => {
                dispatch(searchItemsFailed(error))

            });
    };
}
