interface BadgeProps {
  badgeContent: any
  badgeBgColor: string
  badgeTextColor: string
  textSize: string
  textWeight: string
  badgeWidth: string
}

export const Badges:React.FC<BadgeProps> = ({badgeContent, badgeBgColor, badgeTextColor, textSize, textWeight, badgeWidth}) => {
  return (
    <div className={`inline-flex items-center justify-center rounded-full`} style={{ width: badgeWidth, padding: '10px 0px', height: "24px", backgroundColor: badgeBgColor, color: badgeTextColor}}>
        <span className={`${textSize} ${textWeight}`}>{badgeContent}</span>
    </div>
  )
}