import { message } from 'antd';
import { getData } from 'services/apiService'

export const GET_USER = "GET_USER";
export const USER_IS_lOADING = "USER_IS_lOADING";
export const USER_FAILED = "USER_FAILED";


export const AUTHENTICATE_VENDOR = "AUTHENTICATE_VENDOR";
export const AUTHENTICATE_VENDOR_IS_lOADING = "AUTHENTICATE_VENDOR_IS_lOADING";
export const AUTHENTICATE_VENDOR_FAILED = "AUTHENTICATE_VENDOR_FAILED";



// HELPER ACTIONS CREATORS
//************************************** /


export const userIsLoading = () => ({
  type: USER_IS_lOADING,
  payload: {}
});

export const userFailed = (error) => ({
  type: USER_FAILED,
  payload: error
});


export const authVendorIsLoading = () => ({
  type: AUTHENTICATE_VENDOR_IS_lOADING,
  payload: {}
});

export const authVendorFailed = (error) => ({
  type: AUTHENTICATE_VENDOR_FAILED,
  payload: error
});



/**
 * 
 * @param user save user data in redux
 * 
 */
export function getUser(user) {

  return dispatch => {
    dispatch({
      type: GET_USER,
      payload: user
    });


  };
}



export function authenticateVendor(vendor, connect) {
  const endPoint = `/mobileVendor/signIn/${vendor}?connect=${connect}`
  getData(endPoint)
  .then((res) => {
      // console.log('res url', res.data)
      if(connect === 1){
      if (res.data && res.data.url) {
        let redirectUrl = res.data.url
        window.location.href = redirectUrl;
      }
      else {
        throw new Error("not found url")
      }
    }
    else {
      window.location.href = `/vendor/${vendor}?auth=failed`;
    }

    })
    .catch(err => console.log(err))

}