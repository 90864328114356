import { SearchIcon } from '@heroicons/react/solid'
import * as Actions from 'store/actions'
import { Badges } from 'components/Badges/Badges'
import { getGlobalSearch, getGlobalVendorSearch, setGlobalKeyWords, setGlobalVendorKeyWords } from 'main/ProductPage/store/actions'
import { fetchProducts } from 'main/VendorHomePage/store/actions'
import { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import SearchImg from "../../assets/images/searchImg.svg"
import Spinner from '../../assets/images/spinner.gif'
import { dataPoints } from 'utils/CommonFunctions'

interface SearchFieldProps {
  searchType: string
  width: string
  height: string
  navigate: any
  onSearchValueChange: any
  setSearchValue: any
  focus: boolean
}

export const SearchField: React.FC<SearchFieldProps> = ({searchType, focus, width, height, onSearchValueChange, setSearchValue}) => {
  // States
  const [searchTerm, setSearchterm] = useState<any>([]);
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // Get VendorName
  const getVendorName = location?.pathname?.split("/").slice(2, 3);
  const obj = Object.assign({}, getVendorName);
  // Get Location of SearchPage
  const vendorHomePage = location.pathname.includes("/vendor")
  const isOnSearchPage = location.pathname.includes("/search")
  // Reducers
  const { data: userReducer }: any = useSelector(({ autReducer }: any) => autReducer.GetUserReducer)
  const { GetGlobalSearchReducer: { data: globalSearchData, isLoading }} = useSelector((state:any) => state);
  const { GetGlobalVendorSearchReducer: { data: globalVendorSearchData, isLoading: vendorIsLoading }} = useSelector((state:any) => state);
  const extUserId = userReducer?.data?._doc?.rdUser
  const extStoreId = userReducer?.data._doc?.storeId
  // useEffect
  useEffect(() => {
    window.onclick = function (event) {          // onClick outside value remove
      if (event.target?.id !== "search") {
        setSearchterm([])
      }
    }
    let timeout = setTimeout(() => {
      if (searchTerm.length > 0 && location.pathname === '/') {
        dispatch(getGlobalSearch(searchTerm))
      }
    }, 1000)
    dispatch(setGlobalKeyWords(''))
    return () => clearTimeout(timeout)
  }, [dispatch, location.pathname, searchTerm])


  useEffect(() => {                              // Global Vendor Search useEffect
    window.onclick = function (event) {          // onClick outside value remove
      if (event.target?.id !== "search") {
        setSearchterm([])
      }
    }
    let timeout = setTimeout(() => {
      if(searchTerm.length > 0 && location.pathname !== '/' && (isOnSearchPage || vendorHomePage)){
        return dispatch(getGlobalVendorSearch(getVendorName, searchTerm)) 
      }
    }, 1000)
    
    dispatch(setGlobalVendorKeyWords(''))
    return () => clearTimeout(timeout);
  },[searchTerm])
  useEffect(() => {                              
      if(isOnSearchPage) return;
  },[isOnSearchPage])
  useEffect(() => {
    if(isOnSearchPage){
    for(let i = 0; i < onSearchValueChange?.length ; i++){
      if(searchTerm === onSearchValueChange[i].toLowerCase()){
        return setSearchValue(onSearchValueChange[i]);
      }
    }
  }
  else return ''
   setSearchValue = undefined
  }, [isOnSearchPage, onSearchValueChange, searchTerm, setSearchValue])
  // Functions
  const onValueChange = (e) => {
    setSearchterm(e.target.value)
  }
  const RedirectToSearchPage = (global) => {
    navigate(`/vendor/${global._id}/search/${encodeURIComponent(searchTerm)}`)
  }
  const searchOnClick = (global) => {
    dataPoints(extUserId, 'Search_Clicked', extStoreId, `Marketplace | Nav Bar | Vendor Page`)
    if(searchTerm.length > 0){
      navigate(`/vendor/${getVendorName}/search/${encodeURIComponent(searchTerm)}`)
    }
    else return 
  }
  // On Press Enter
  const onPress = (e) => { 
    if (e.key === "Enter") {
      e.preventDefault();
      const onPressValue = (e.target.value)
        if(e.target.value !== ""){
          navigate(`/vendor/${getVendorName}/search/${encodeURIComponent(onPressValue)}`)
          dataPoints(extUserId, 'Search_Entered', extStoreId, `Marketplace | Nav Bar | Vendor Page`)
      }
      else return
      setSearchterm('')
    }
  }
  const redirectToProductPage = (vendorSearchData) => {
    dataPoints(extUserId, 'Search_Item_Clicked', extStoreId, `Marketplace | Nav Bar | Vendor Page`)
    navigate(`/vendor/${vendorSearchData.vendor}/${vendorSearchData._id}`)
  }
  switch (searchType) {
    case 'HomeSearch':
      return (
        <div>
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
              <SearchIcon className="h-5 w-5 text-secondary-400" aria-hidden="true" />
            </div>
            <input
              id="search"
              name="search"
              className="block h-12 pl-10 pr-3 py-2 shadow border border-secondary-300 rounded-full leading-5 text-base bg-white text-secondary-500 focus:text-secondary-400 focus:border-secondary-500 outline-none sm:text-sm w-[520px]"
              placeholder="Search"
              autoFocus={focus}
              type="search"
              value={searchTerm}
              onInput={onValueChange}
              autoComplete="off"
            />
          </div>
          {/* {isLoading && <div className='absolute bg-red-500'>loader</div>} */}
          {!isLoading ? globalSearchData?.length > 0 ? location.pathname === '/' && searchTerm?.length && globalSearchData?.length ?
          //  style={{ width: "682px", marginTop: "10px" }}
            <div className='absolute z-10 h-[306] overflow-y-auto overflow-x-hidden bg-white border border-secondary-300 rounded-md w-[520px] mt-[10px]'> 
              {globalSearchData?.map((global: any) => (
                <div className='h-14 cursor-pointer flex justify-between items-center mx-4 my-4 p-4 border border-secondary-300 rounded-md hover:bg-secondary-100 ' onClick={() => RedirectToSearchPage(global)}>
                  <div className='flex items-center'>
                    <img src={global?.vendor_detail?.logo} width={52} height={35} alt={""} />
                    <p className='ml-4'>{global?.vendor_detail?.label}</p>
                  </div>
                  <div className='bg-blue-100 w-[48px] h-5 rounded-[10px] text-center'>
                    <p>{global?.count}</p>
                  </div>
                </div>
              ))}
            </div>
            : []
            :
            globalSearchData?.length <= 0 &&
            <div className='absolute z-10 h-[24] overflow-y-auto overflow-x-hidden bg-white border border-secondary-300 rounded-md w-[520px] mt-[10px]'>
              <div>
                <img src={SearchImg} className="mx-auto mt-10 mb-6" width={150} alt=""/>
                <p className='text-base font-medium text-secondary-700 text-center'>Sorry we coudn’t find any matches</p>
                <p className='text-xs text-secondary-500 text-center mt-2 mb-10'>Please try searching with another item</p>
              </div> 
            </div>
            :
            <div className='absolute z-10 h-[24] overflow-y-auto overflow-x-hidden bg-white border border-secondary-300 rounded-md w-[520px] mt-[10px]'>
              <img src={Spinner} className="mx-auto my-auto" width={150} alt=""/>
            </div>
          }
        </div>
      )
      case 'VendorSearch': 
      return(
        <div>
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-4 flex items-center">
              <SearchIcon className="h-5 w-5 text-secondary-400 cursor-pointer" aria-hidden="true" onClick={searchOnClick}/>
            </div>
            <input
              id="search"
              name="search"
              className={`block ${height} ${width} min-w-full h-9 pl-10 pr-3 py-2 border-2 border-secondary-300 rounded-md leading-5 text-base bg-white text-secondary-500 focus:outline-none focus:text-secondary-400 focus:ring-1 focus:ring-secondary-400 focus:border-secondary-400 sm:text-sm`}
              style={{boxShadow: '0px 1px 2px 0px RGBA(0, 0, 0, 0.05)'}}
              placeholder="Search"
              type="search"
              value={searchTerm}
              autoFocus={focus}
              onInput={onValueChange}
              onKeyPress={(e) => onPress(e)}
              autoComplete="off"
            />
          </div>
          {!vendorIsLoading ? globalVendorSearchData?.items?.length > 0 ? location.pathname !== '/' && searchTerm?.length && globalVendorSearchData?.length | globalVendorSearchData?.items?.length ?
            <div className='absolute z-[100] overflow-y-auto overflow-x-hidden bg-white border border-black-500 w-[690px] lg:w-[250px] xl:w-[500px] 2xl:w-690 rounded-md' style={{ marginTop: "10px", maxHeight: "500px", height: "auto" }}>
              {globalVendorSearchData?.items?.map((vendorSearchData: any) => (
                <div onClick={() => redirectToProductPage(vendorSearchData)} className='h-15 cursor-pointer flex justify-between items-center p-4 hover:bg-primary-400'>
                  <div className='flex items-center'>
                    <img src={vendorSearchData?.imageUrls[0]} width={40} height={40} alt={""} className="rounded-lg" />
                    <p className='ml-4'>{vendorSearchData?.name}</p>
                  </div>
                  <div className='' style={{ marginLeft: "72px" }}>
                    <Badges badgeContent={vendorSearchData?.qty >= 20 ? '20+ in stock' : vendorSearchData?.qty + ' in stock'} badgeBgColor={vendorSearchData?.qty === 0 ? '#f1010194' : vendorSearchData?.qty > 0 && vendorSearchData?.qty <= 20 ? '#FEE2E2' : vendorSearchData?.qty > 20 ? '#DCFCE7' : ''} badgeTextColor={vendorSearchData?.qty === 0 ? '#222326' : vendorSearchData?.qty > 0 && vendorSearchData?.qty <= 20 ? '#222326' : vendorSearchData?.qty > 20 ? '#222326' : ''} textSize={'text-xs'} textWeight={''} badgeWidth={'100px'} />
                  </div>
                </div>
              ))}
            </div>
            : []
            :
            globalVendorSearchData?.items?.length <= 0 &&
            <div className='absolute z-[100] h-[24] overflow-y-auto overflow-x-hidden bg-white border border-secondary-300 rounded-md w-[690px] lg:w-[250px] xl:w-[500px] 2xl:w-690 mt-[10px]'>
              <div>
                <img src={SearchImg} className="mx-auto mt-10 mb-6" width={150} alt=""/>
                <p className='text-base font-medium text-secondary-700 text-center'>Sorry we coudn’t find any matches</p>
                <p className='text-xs text-secondary-500 text-center mt-2 mb-10'>Please try searching with another item</p>
              </div> 
            </div>
            :
            <div className='absolute z-[100]  h-[24] overflow-y-auto overflow-x-hidden bg-white border border-secondary-300 rounded-md w-[690px] lg:w-[250px] xl:w-[500px] 2xl:w-690 mt-[10px]'>
              <img src={Spinner} className="mx-auto my-auto" width={150} alt=""/>
            </div>
          }
        </div>
      )
    default:
      return(
        <Fragment>
        {isOnSearchPage &&
        <div>
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
            <SearchIcon className="h-5 w-5 text-secondary-400" aria-hidden="true" />
          </div>
          <input
            id="search"
            name="search"
            className={`block ${height} ${width} min-w-full h-9 pl-10 pr-3 py-2 shadow border border-secondary-300 rounded-md leading-5 text-base bg-white text-secondary-500 focus:outline-none focus:text-secondary-400 focus:ring-1 focus:ring-secondary-500 focus:border-secondary-500 sm:text-sm`}
            placeholder="Search"
            type="search"
            value={searchTerm}
            onChange={onValueChange}
            autoComplete="off"
          />
        </div>
      </div>
      }
      </Fragment>
      )
  }
}
