/**
 * @module JwtService
 * @author Hammad Ali
 * @fires AuthenticationApi 
 */

import axios from "axios";
import { openNotificationWithIcon } from "components/Notification";
import { REDIRECT_CONFIG_URL } from "config";

import { getData } from 'services/apiService'
import cookieHandler from 'services/HelperService/HelperService'
// const baseUrl = "https://api-microsite.repairdesk.co";
let baseUrl = process.env.REACT_APP_BASE_URL;

interface userData {
  firstName: string;
  lastName: string;
  _id: string;
  profileUrl: string;

}

const loginEndPoint = "/auth/login"
const getUserEndPoint = "/user/me"
const errorPage = "/*"

class JwtService {

  /**
   * @name setInterceptors
   * @description Inspect responses of all the http requests
   */
  setInterceptors = () => {
    axios.defaults.baseURL = baseUrl;
    axios.interceptors.response.use(
      (response) => {

        return response;
      },
      (err) => {
        if (err?.response?.status === 401) {
          this.setSession(null);
        }
        else {
          openNotificationWithIcon("error", `Error ${err?.response?.data?.statusCode || 400}!`, err?.response?.data?.message || "something went wrong!");
          window.location.href = errorPage
        }
        return err;
      }
    );
  };

  /**
   * @name handleAuthentication
   * @returns Promise<any> || resolve (userData) || reject(sessionError)
   * @fires getAccessToken
   * @fires setSession
   * @description this method is responsible to inspect current auth session and set user data + maintain auth session
   * 
   */
  handleAuthentication = (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      const token = this.getAccessToken();

      if (token) {
        this.setSession(token);
        const userData = await this.getUserData()

        if (!userData) {
          reject('something went wrong')
        }
        resolve({ token, userData });
        return { token, userData };
      } else {
        this.setSession(null);
        let error = new Error()
        error.message = "token not found"
        reject(error)
        window.location.href = REDIRECT_CONFIG_URL
      }
      // else {

      //   postData(loginEndPoint, {
      //     "email": "ahsan@repairdesk.co",
      //     "password": "ec9ad7471b4462981473b5b02d12427b",
      //     "isRd": "1"
      //   })
      //     .then(async (res) => {

      //       if (!res) {
      //         reject('something went wrong')
      //         return
      //       }
      //       try {

      //         const token = res.data.access_token
      //         this.setSession(token);
      //         const userData = await this.getUserData()
      //         console.log(userData)

      //         if (!userData) reject('something went wrong')

      //         resolve({ token, userData });
      //       } catch (error) {

      //         console.log(error)

      //         this.setSession(null);

      //         throw error
      //       }

      //     })
      //     .catch(error => {

      //       this.setSession(null);
      //       reject(error)
      //       throw (error);

      //     })

      // }


    });
  };

  /**
   * @name setUserData
   * @param data \\ data of current user
   * @description It set the current use data in redux store and make it globally accesible 
   * @fires redux_action
   * @typedef userData
   */
  setUserData = (token: string, data: userData) => {
  };

  getUserData = async (): Promise<any> => {
    return getData(getUserEndPoint)
  }

  /**
   * @name setSession
   * @param access_token \\ access token of current session
   * @description It stores and maintain session of the current user
   */

  setSession = (access_token) => {
    let url = `${window.location.origin.toString()}`;
    const Redirect_TeamC = 'https://team-c.repaircrm.co/index.php?r=site/login'
    const Redirect_CRM = 'https://app.repaircrm.co/index.php?r=site/login'
    const Redirect_Production = 'https://app.repairdesk.co/index.php?r=site/login'
    if (access_token) {
      // localStorage.setItem("jwt_access_token", access_token);
      // cookieHandler.setCookie("marketplace_token", access_token, true)
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;

    } else {
      // localStorage.removeItem("jwt_access_token");
      delete axios.defaults.headers.common.Authorization;
      openNotificationWithIcon("error", "Session Expired", "Please log in again, your session has been expired")
      setTimeout(() => {
        window.location.href = (url === "https://marketplace-staging.repaircrm.co" ? `${Redirect_TeamC}` : "https://marketplace.repaircrm.co" ? `${Redirect_CRM}` : "https://marketplace.repairdesk.co" ? `${Redirect_Production}` : "")
      }, 500);
    }
  };

  /**
   * @fires setSession
   * @description Responsible for destroying current session 
   */
  logout = () => {
    this.setSession(null);
  };

  /**
   * 
   * @returns Access token
   * @description Grab the current session token from cookie or localStorage
   */
  getAccessToken = (): string | null => {
    if (process.env.NODE_ENV === 'development') {
      // dev code
      return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZElkIjo0MjgyOSwidWlkIjo0MjgyOSwiZW1haWwiOiJ0YXl5YWIyLjBAcmVwYWlyZGVzay54eXoiLCJpZCI6IjYyYTA5ZWUwMDdhNzcxODM1MzNkYjE0NyIsImNvb2tpZSI6eyJrZXkiOiJ1Y2ZfeTcxNTQxNjUzZWRmZDgxZWUwYWU2MmZiMWZkMWJlMDExIiwidmFsdWUiOiJkbnBEYkZJM05sTkVTRWR1Y1N0NU1Fa3lZMlJLTjJFM1ZrRkpObmRPWmtOM1NFYzJaaXM0Y3pabVJHeDBSWEIwYVRsblIwRmhUazFqYjJsclVHVk5hemxQVVV4M2VtNTNVVGhRTmtWcU5sZzBSbUpYU1djelZWZFlXa3hMVVV0TVJpczFlR055Tnlzdk5XeDBVbkoyY1U1cmJpczJablpzV2pRMk5XZEdOelk1WWtSUGRFTXlPR05HUW0xTlVIVlpjelYxY1hGUlBUMD0ifSwic3ViZG9tYWluIjoiaHR0cHM6Ly9hcHAucmVwYWlyY3JtLmNvIiwiaWF0IjoxNjY4NTA3NTIwLCJleHAiOjE2Njg1MzYzMjB9.4-v_5W3BWnBdLd6F4rTefoLYsDvGzHsZjpuXrpO2Bn8";
    } else {
      //   // production code
      return cookieHandler.getCookie("ucfmrk", true)/* || window.localStorage.getItem("jwt_access_token")*/;
    }
  };
}

// Instance of jwtService
const jwtService = new JwtService();

export default jwtService;
