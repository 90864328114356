import React, { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as CommonActions from "store/actions";
import * as AuthActions from "auth/store/actions";
import { Button } from "../Button/Button";
import { QuantityCounter } from "../QuantityCounter/QuantityCounter";
import PlaceholderImg from "../../assets/images/placeholder-image.png";
import { svgIcons } from "../../assets/svg";
import { checkIfVendorConnected } from "../../services/HelperService/HelperService";
import {
  Notification,
  openNotificationWithIcon,
} from "components/Notification";
import { dataPoints } from "utils/CommonFunctions";
import { Link } from "react-router-dom";

function BaseProductCard({
  product,
  addToCartListener,
  RedirectTo,
  hidePrice,
  currentVendor,
  ifOnVendorPage,
}: any) {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Reducers
  const { data }: any = useSelector(
    ({ autReducer }: any) => autReducer.GetUserReducer
  );
  const { data: cartDatas, updateLoading } = useSelector(
    ({ commonReducers }: any) => commonReducers.GetCartReducer
  );
  const { data: wishListData } = useSelector(
    (state: any) => state.commonReducers.GetWishListReducer
  );
  const { data: slugData } = useSelector(
    ({ commonReducers }: any) => commonReducers.GetVendorsSlugReducer
  );
  // State
  const [quantity, setQuantity] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(updateLoading);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [onMouse, setOnMouse] = useState(false);
  const [loc, setLoc] = useState<any>();
  const [wishlistloc, setWishlistLoc] = useState<any>();
  const [isWishlist, setIsWishlist] = useState<any>(false);
  // Extract Data
  const cartDataShort = cartDatas?.cartItems?.map((cart) => cart?.item?._id);
  const alreadyInCart = cartDataShort?.find((i) => i === product?.id);
  const cartTotalQuantity = cartDatas?.cartItems?.find(
    (i) => i?.item?._id === product?.id
  )?.quantity;
  const chipColor: any = (itemQty: number) =>
    itemQty > 20 ? "green" : itemQty < 20 && itemQty > 10 ? "orange" : "red";
  const extVendorStore = slugData?.storeType;
  const extUserId = data?.data?._doc?.rdUser;
  const extStoreId = data?.data._doc?.storeId;
  // const extQtyArray = product?.qtyArray?.map((q) => q.Quantity);
  // const extPrice = product?.qtyArray?.map((p) => p.Price);
  // const filterQty = extQtyArray.filter((data) => data === 1);
  // let testQty = product?.qtyArray?.filter((el) => el.Quantity === 1)[0]?.Price;
  // Function
  const RedirectToProduct = (product) => {
    const slug = product?.vendor;
    const productId = product?.id || product?._id;
    navigate(`/vendor/${slug}/${productId}`);
    window.scrollTo(0, 0);
    dataPoints(
      extUserId,
      "Product_Clicked",
      extStoreId,
      `Marketplace | ${slug} | Vendor Page`
    );
  };
  const connectVendor = () => {
    AuthActions.authenticateVendor(product.vendor, 1);
    dataPoints(
      extUserId,
      "Connect_Vendor",
      extStoreId,
      `Integrations | Marketplace | ${product?.vendor}`
    );
  };
  const handleAddToCart = (product: any) => {
    const alreadyAuthenticated = checkIfVendorConnected(product.vendor);
    if (extVendorStore === "shopify" && !alreadyAuthenticated) {
      setIsModalVisible(false);
    } else if (!alreadyAuthenticated) {
      setIsModalVisible(true);
      return;
    }
    setIsLoading(!updateLoading);
    const data = {
      quantity: quantity === 0 ? quantity + 1 : quantity,
      product,
    };
    addToCartListener(data);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };
  const handleWishList = ({ item, action }) => {
    dispatch(
      CommonActions.updateWishList(item, action, () => {
        if (action === 0) {
          openNotificationWithIcon("error", `Item removed from Wishlist!`, "");
          dataPoints(
            extUserId,
            "Removed_From_Wishlist",
            extStoreId,
            `Marketplace | ${product?.vendor} | Vendor Page`
          );
        } else {
          openNotificationWithIcon(
            "success",
            `Item added in Wishlist!`,
            "Open wishlist to see the item."
          );
          dataPoints(
            extUserId,
            "Add_To_Wishlist",
            extStoreId,
            `Marketplace | ${product?.vendor} | Vendor Page`
          );
        }
      })
    );
  };
  // useEffect
  useEffect(() => {
    setLoc(localStorage.getItem("Name"));
  }, [localStorage.getItem("Name"), loc]);
  return (
    <>
      {/* Modal when vendor is not connected */}
      <Modal
        open={isModalVisible}
        setOpen={setIsModalVisible}
        dialogTitle="Connection!"
        dialogMessage="Please connect to the vendor."
        onClick={() => {
          setIsModalVisible(false);
          connectVendor();
        }}
        onCancel={() => setIsModalVisible(false)}
      />
      <div key={product?.id}>
        <div
          className="relative bg-white rounded-md"
          onMouseEnter={() => setOnMouse(true)}
          onMouseLeave={() => setOnMouse(false)}
          style={{ minHeight: "340px", maxHeight: "500px" }}
        >
          <div className="relative overflow-hidden rounded-t-md">
            <img
              src={
                product?.imageUrls && product?.imageUrls?.length > 0
                  ? product?.imageUrls[0]
                  : product?.imageSrc[0]
                 || PlaceholderImg
              }
              alt={product?.imageAlt}
              onClick={
                extVendorStore === "shopify"
                  ? () => {}
                  : () => RedirectToProduct(product)
              }
              className={`object-center ${
                product?.imageUrls?.length ? "object-conatin" : "object-cover"
              } object-contain cursor-pointer w-full bg-transparent group-hover:opacity-75`}
              style={{ height: "180px" }}
            />
          </div>

          {/* Quantity Paper */}
          <div className="absolute top-0 inset-x-0 flex justify-start overflow-hidden">
            <span className="inline-flex border items-center w-15 h-6 px-3 py-0.5 mt-3 ml-3 rounded-full text-sm font-medium bg-white border-secondary-300 text-secondary-700">
              <svg
                className="-ml-1 mr-1.5 h-2 w-2 text-indigo-400"
                fill={chipColor(product?.qty)}
                viewBox="0 0 8 8"
              >
                <circle cx={4} cy={4} r={3} />
              </svg>
              {product?.qty >= 20 ? "20+ in stock" : product?.qty}
            </span>
          </div>
          {onMouse ? (
            wishListData?.items?.some(
              (wishlistId) => wishlistId._id === product?.id
            ) ? (
              <div className="absolute flex justify-center items-center top-3 right-3 shadow-[0px, 1px, 2px, 0px rgba(0,0,0,0.5)] bg-white rounded-md w-8 h-8">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    handleWishList({ item: product?.id, action: 0 });
                  }}
                >
                  {svgIcons.heartRed}
                </div>
              </div>
            ) : (
              <div className="absolute flex justify-center items-center top-3 right-3 shadow-[0px, 1px, 2px, 0px rgba(0,0,0,0.5)] bg-white rounded-md w-8 h-8">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    handleWishList({ item: product?.id, action: 1 });
                  }}
                >
                  {svgIcons.heart}
                </div>
              </div>
            )
          ) : (
            ""
          )}
          <div className="relative mx-4 mt-3">
            <Link
              to={`/vendor/${currentVendor || product?.vendor}/${
                product?.id || product?._id
              }`}
            >
              <h3
                className={`text-sm font-medium text-secondary-800 cursor-pointer`}
                onClick={() => RedirectToProduct(product)}
              >
                {product?.name}
              </h3>
            </Link>
          </div>
          <div className="flex justify-between items-center mt-3 mx-4">
            <QuantityCounter
              productQuantity={product?.qty}
              alreadyInCart={isLoading}
              btnHeight="25px"
              btnWidth="24px"
              inputHeight="28px"
              inputWidth="30px"
              onValueChange={(v) => setQuantity(v)}
            />
            <div className="flex items-center text-sm font-medium text-green-600">
              {cartDatas?.cartItems?.length ? (
                <div className="mb-[45px]">
                  {alreadyInCart && (
                    <div className="absolute text-xs flex items-center -ml-4 justify-center text-white  h-6 w-6 rounded-full bg-red-500">
                      {cartTotalQuantity}
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
              <div className="mr-3">{svgIcons.cart}</div>
              {loc === "false"
                ? "$" + parseFloat(product?.costPrice || product.price).toFixed(2)
                : ""}
            </div>
          </div>
          <div className="py-4 flex justify-center">
            <Button
              onClick={
                cartTotalQuantity === product?.qty ||
                quantity > product?.qty - cartTotalQuantity ||
                product?.qty === 0
                  ? () => {}
                  : () => handleAddToCart(product)
              }
              alreadyInCard={undefined}
              quantity={product.quantity}
              isLoading={isLoading}
              btnType="ProductCardBtn"
              defaultBgColor={""}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default BaseProductCard;
