import queryString from "query-string";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useMatch, useParams } from "react-router";
import VendorBanner from "../../assets/images/banner.jpg";
import VendorBannerMS1 from "../../assets/images/banner_dummy.jpg";
import VendorBannerMS2 from "../../assets/images/casper.gif";
import VendorBannerIG1 from "../../assets/images/ig-banner1.png";
import VendorBannerIG2 from "../../assets/images/ig-banner2.png";
import { svgIcons } from "../../assets/svg";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { Carousel } from "../../components/Carousel/Carousel";
import { ProductCards } from "../../Sections/ProductCards/ProductCards";
import withReducer from "../../store/withReducer";
import { Capitalize, scrollToBottom } from "../../utils/CommonFunctions";
// import * as CommonActions from "./store/actions"
import * as Actions from "./store/actions";
import * as CommonActions from "store/actions";
import reducer from "./store/reducers";
import { Pagination } from "antd";
import CheckoutModal from "components/Modal/CheckoutModal";
import { showCart } from "store/actions";
import { checkIfVendorConnected } from "services/HelperService/HelperService";
import SearchResultPage from "main/SearchResultPage";
import { fetchShopifyProducts } from "./store/actions";

const VendorHomePage = () => {
  // Hooks
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  // Slug
  const slug = params.slug;
  const parsed = queryString.parse(location.search);
  let ifConnected = checkIfVendorConnected(slug);
  const extOrder = parsed.order;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const ifOnVendorPage = useMatch("/vendor/:slug/*");

  // Reudcers
  const { data }: any = useSelector(
    ({ autReducer }: any) => autReducer.GetUserReducer
  );
  const { data: VendorData } = useSelector(
    ({ commonReducers }: any) => commonReducers.GetVendorsReducer
  );
  const { data: slugData, isLoading: vendorSlugLoading } = useSelector(
    ({ commonReducers }: any) => commonReducers.GetVendorsSlugReducer
  );
  const { data: shopifyProducts, isLoading: isShopifyLoading } = useSelector(
    ({ vendor }: any) => vendor.FetchShopifyProductsReducer
  );
  const { data: vendorProducts, isLoading: productsLoading } = useSelector(
    ({ vendor }: any) => vendor.FetchProductsReducer
  );
  const { data: shopifySideMenuData }: any = useSelector(
    ({ commonReducers }: any) => commonReducers.GetShopifySideMenuReducer
  );

  const vendorData = slugData
    ? [
        {
          id: slugData._id,
          cardIcon: slugData.logo,
          title: slugData.label,
          description: slugData.description,
        },
      ]
    : [
        {
          id: 1,
          cardIcon: svgIcons.injuredGadgetsLogo,
          title: "InjuedGadgets",
          description:
            "As being in this repair industry for many years,d to dealing directly with our retailers and repair shops.",
        },
      ];
  // Extract Data
  const getVendorIdFromSlug = (slug) => {
    let results = VendorData?.data?.filter((item) => item.name === slug);
    return results?.length > 0 ? results[0]?._id : "";
  };
  const extStoreName = slugData?.storeType;
  const getCarousel = slugData?.carousel;
  const extTotalPages = vendorProducts?.data?.items?.totalPages;
  const extUserId = data?.data?._doc?.rdUser;
  const pathChunks = location?.pathname?.substring(1).split("/");
  const currentVendor = pathChunks[1];
  // State
  const [isCard, setIsCard] = useState(true);
  const [, setCardType] = useState(isCard ? "ProductListView" : "ProductCard");
  const [productCardData, setProductData] = useState<any>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalShopifyItems, setTotalShopifyItems] = useState<number>(0);
  const [success, setSuccess] = useState(false);
  // useEffect
  useEffect(() => {
    if (currentVendor !== "2ndlifephones" && ifOnVendorPage) {
      dispatch(
        Actions.fetchProducts(currentVendor, currentPage, 50, {
          filter: parsed,
          limit: 50,
        })
      );
      scrollToBottom();
    }
    // else {
    //   let bodyParser = {
    //     consmeticGrades: [],
    //     simLockStatuses: [],
    //     functionalStatuses: [],
    //     storages: [],
    //     models: [],
    //     colors: [],
    //     brands: [],
    //     keyword: "",
    //     vendor: currentVendor,
    //     limit: 50,
    //     page: currentPage,
    //     priceRange: {
    //       min: '',
    //       max: ''
    //     }
    //   }
    //   dispatch(fetchShopifyProducts(bodyParser))
    // }
  }, [currentPage, currentVendor]);
  useEffect(() => {
    if (vendorProducts) {
      setTotalItems(vendorProducts.data?.items.totalDocs);
      const items = vendorProducts.data?.items.docs;
      if (!items || !items.length) return;
      const parsedItems = items.map((item) => {
        return {
          id: item._id,
          name: item.name,
          qty: item.qty,
          imageSrc: item.imageUrls,
          imageAlt: "Product Image",
          price: item.costPrice,
          vendor: item.vendor,
          vendorId: item.vendorId,
        };
      });
      setProductData(parsedItems);
    }
  }, [vendorProducts]);
  useEffect(() => {
    if (shopifyProducts) {
      setTotalShopifyItems(shopifyProducts.data?.items.totalDocs);
      const items = shopifyProducts.data?.items.docs;
      if (!items || !items.length) return;
      const parsedItems = items.map((item) => {
        return {
          id: item._id,
          name: item.name,
          qty: item.qty,
          imageSrc: item.imageUrls,
          imageAlt: "Product Image",
          price: item.costPrice,
          vendor: item.vendor,
          vendorId: item.vendorId,
        };
      });
      setProductData(parsedItems);
    }
  }, [shopifyProducts]);
  useEffect(() => {
    if (extOrder) {
      setSuccess(true);
    }
  }, [extOrder]);
  // Function
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };
  const handleAddToCart = (prodData) => {
    if (ifConnected) {
      dispatch(CommonActions.updateCart(prodData, 1));
    }
  };
  // const slides = slugData?.carousel ? slugData.carousel.map((car: { imgUrl: any }) => {
  const slides =
    slugData?.name === "mobile_sentrix"
      ? [{ img: VendorBannerMS1 }, { img: VendorBannerMS2 }]
      : slugData?.name === "injured_gadgets"
      ? [{ img: VendorBannerIG2 }, { img: VendorBannerIG1 }]
      : extStoreName === "shopify"
      ? getCarousel
      : [{ img: VendorBanner }];
  const RedirectTo = () => {
    window.open(slugData.website, "_blank");
    window.scrollTo(0, 0);
  };
  const changeView = () => {
    setIsCard(!isCard);
    setCardType("ProductCard");
  };
  useEffect(() => {
    const path = queryString.parse(location.search);
    if (path.cartOpen === "true") {
      dispatch(showCart(slug));
    }
  }, [dispatch, location.search, slug]);
  return (
    <Fragment>
      {success ? <CheckoutModal /> : null}
      {extStoreName === "shopify" ? (
        <SearchResultPage />
      ) : (
        <div className={`w-full 3xl:max-w-[1536px] mx-auto 4xl:max-w-[1536px]`}>
          <div className="mt-6 mx-6">
            <Breadcrumbs
              currentPage={Capitalize(slug)}
              currentPageSlug={slug}
              location={location}
              productId={undefined}
              id={undefined}
            />
          </div>
          {/* Banner */}
          <div>
            <Carousel slides={slides} vendorSlugLoading={vendorSlugLoading} />
          </div>
          {/* Product Cards Section */}
          <div>
            {/* <div>
              <CategoryFilterBody
                categoriesData={shopifySideMenuData}
                queryCategory={undefined}
                extUserId={undefined}
                currentVendor={undefined}
                keyword_Name={undefined}
                extStoreName={undefined}
                currentPage={undefined}
                currentLevel={undefined}
                changeRadio={undefined}
                currentSelected={undefined}
              />
            </div> */}
            <div className="mt-6">
              {/* <ProductCards cardType={!isCard ? 'ProductListView' : 'ProductCard'} cardHeight={''} changeView={changeView} productCardData={productCardData} onClick={undefined} /> */}
              <ProductCards
                extStoreName={extStoreName}
                totalItems={
                  extStoreName !== "2ndlifephones"
                    ? totalItems
                    : totalShopifyItems
                }
                extUserId={extUserId}
                cardType={
                  !isCard || currentVendor === "2ndlifephones"
                    ? "ProductListView"
                    : "ProductCard"
                }
                onClick={handleAddToCart}
                cardHeight={""}
                changeView={changeView}
                productCardData={productCardData}
                productsLoading={productsLoading}
                RedirectTo={RedirectTo}
                slug={""}
                searchName={undefined}
                productId={undefined}
                vendorName={undefined}
                handleSort={undefined}
                sortType={undefined}
                queryCategory={undefined}
                data={undefined}
                categoryItemsData={undefined}
                parsed={undefined}
                isShopifyLoading={isShopifyLoading}
              />
            </div>
          </div>
          <div className="flex justify-end mr-6 mb-4">
            <Pagination
              // defaultCurrent={1}
              hideOnSinglePage={true}
              onChange={handleChangePage}
              total={
                currentVendor !== "2ndlifephones"
                  ? vendorProducts?.data?.items?.totalDocs || 0
                  : shopifyProducts?.data?.items?.totalDocs || 0
              }
              pageSizeOptions={[]}
              defaultPageSize={50}
              current={currentPage}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default withReducer("vendor", reducer)(VendorHomePage);
