import { useEffect, useRef, useState } from "react";
import { BackTop } from "antd";
import { Header, Footer } from "../components";
import { useSelector, useDispatch } from "react-redux";
import { openNotificationWithIcon } from "components/Notification";
import { SubHeader } from "../components/SubHeader/SubHeader";
import * as CommonActions from "store/actions";
import { checkIfVendorConnected } from "services/HelperService/HelperService";
import { useLocation, useNavigate } from "react-router-dom";
import { useMatch } from "react-router-dom";
import { SlideOver } from "components/SlideOver/SlideOver";
import queryString from "query-string";
import useCurrentRedirectLocation from "hooks/useCurrentRedirectLocation/useCurrentRedirectLocation";
import Spinner from "../assets/images/spinner.gif";
import WishList from "components/WishList/WishList";
import { dataPoints } from "utils/CommonFunctions";
import BackToTop from "../assets/images/Group.svg";

const BaseLayout: React.FC = ({ children }) => {
  // hooks
  const REDIRECT_URL = useCurrentRedirectLocation();
  const ifOnVendorPage = useMatch("/vendor/:slug/*");
  const ifOnVendorHomePage = useMatch({ path: "/vendor/:slug", end: true });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = useLocation();
  const prevVendor = useRef<any>();
  const alreadyOnVendorPage = useRef<boolean>();
  const [connected, setConnected] = useState(false);

  // Reducers
  const { data }: any = useSelector(
    ({ autReducer }: any) => autReducer.GetUserReducer
  );
  const { data: wishListData }: any = useSelector(
    (state: any) => state.commonReducers.GetWishListReducer
  );
  const {
    GetCartReducer: { data: cartData, updateLoading: cartLoading },
  }: any = useSelector((state): any => state);
  const { data: categories, isLoading: categoriesLoading } = useSelector(
    ({ commonReducers }: any) => commonReducers.GetCategoryReducer
  );
  const { data: vendorData, isLoading: vendorSlugLoading } = useSelector(
    ({ commonReducers }: any) => commonReducers.GetVendorsSlugReducer
  );

  // Extract Data
  const userVendors = data?.data?.vendors;
  const extStoreName = data?.data?.rdUser.map((user) => user.storeName);
  const rdImage = data?.data?.rdUser.map((user) => user.image);
  const extVendorStore = vendorData?.storeType;
  const extVendorId = vendorData?.id;
  const extUserId = data?.data?._doc?.rdUser;
  const extStoreId = data?.data._doc?.storeId

  // url parsing
  let pathChunks = location?.pathname?.substring(1).split("/");
  let injuredGadgetsLocation = location?.pathname === "/vendor/injured_gadgets";
  let productLocation = location?.pathname?.includes("/vendor");
  const currentVendor = pathChunks[1];
  const getVendor = location.pathname.split("/").slice(2, 3);
  const navigtionString = `${location.pathname}`;
  let ifConnected = checkIfVendorConnected(currentVendor);
  const params = new URLSearchParams(search).get("auth");
  // useEffects

  useEffect(() => {
    setConnected(
      userVendors &&
        userVendors.length > 0 &&
        userVendors.some((ele) => ele.name === currentVendor)
    );
  }, [currentVendor]);
  useEffect(() => {
    if (params === "success") {
      openNotification(
        "success",
        "Authentication",
        "Vendor Authenticated Successfuly"
      );
      return;
    }
    if (params === "failed") {
      openNotification(
        "success",
        "Authentication",
        "Vendor disconnected successfully!"
      );
      return;
    }
  }, [params]);
  useEffect(() => {
    extVendorStore === "shopify" &&
      dispatch(CommonActions.getCart({ state: extVendorId }));
  }, [dispatch, extVendorStore, ifConnected]);
  useEffect(() => {
    if (ifOnVendorPage && ifConnected) {
      // alreadyOnVendorPage.current !== currentVendor &&
        dispatch(CommonActions.getCart({ state: ifConnected._id }));
    }
    if (prevVendor.current !== currentVendor) {
      if (ifOnVendorPage) {
        dispatch(CommonActions.getCategory(currentVendor, 0));

        dispatch(CommonActions.getVendorsBySlug(currentVendor));
      }
      prevVendor.current = currentVendor;
    }
  }, [location]);
  useEffect(() => {
    dispatch(CommonActions.getWishList());
  }, [dispatch]);
  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.orderStatus === "success") {
      openDrawer();
    }
  }, []);
  // methods
  const openNotification = (
    type: string,
    header: string,
    message: string,
    icon?: any
  ) => {
    openNotificationWithIcon(type, header, message, icon);
  };
  const { state: drawerState } = useSelector(
    ({ commonReducers }: any) => commonReducers.CartReducer
  );
  // State
  const [isOpen, setIsOpen] = useState(false);
  const [isWishlistOpen, setIsWishlistOpen] = useState(false);

  const openDrawer = () => {
    if (!connected) return;
    let ifConnected = checkIfVendorConnected(currentVendor);
    dispatch(CommonActions.showCart(ifConnected ? ifConnected._id : false));
    dataPoints(extUserId, "Cart_Open", extStoreId, `Marketplace | ${currentVendor}`);
  };

  const closeDrawer = () => {
    setIsOpen(false);
    // dispatch close drawer method to close drawer
    dispatch(CommonActions.hideCart());
  };
  const openWishList = () => {
    dispatch(CommonActions.showWishList());
    dataPoints(extUserId, "Wishlist_Opened", extStoreId, "Marketplace | Nav Bar");
  };
  const closeWishlist = () => {
    setIsWishlistOpen(false);
    // dispatch close drawer method to close drawer
    dispatch(CommonActions.hideWishList());
  };

  // VendorPath
  const isOnVendorPage = location.pathname.includes("/vendor");

  return (
    <div className="flex flex-col min-h-screen">
      {/* Header */}
      <Header
        rdImage={rdImage}
        data={data}
        connected={connected}
        extUserId={extUserId}
        extStoreId= {extStoreId}
        extStoreName={extStoreName}
        currentVendor={currentVendor}
        storeType={extVendorStore}
        onClick={() => {}}
        openCart={() => {
          setIsOpen(true);
          openDrawer();
        }}
        wishListData={wishListData}
        cartData={cartData}
        isOnVendorPage={isOnVendorPage}
        openWishlist={() => {
          setIsWishlistOpen(true);
          openWishList();
        }}
      />
      {/* <Header onClick={() => { }} openCart={() => setIsOpen(!isOpen)} wishListData={wishListData} cartData={cartData}  isOnVendorPage={isOnVendorPage} /> */}

      {/* SubHeader */}
      {isOnVendorPage && (
        <SubHeader
          vendorConnected={ifConnected ? true : false}
          extUserId={extUserId}
          currentVendor={currentVendor}
          logo={vendorData ? vendorData?.logo : Spinner}
          vendorSlugLoading={vendorSlugLoading}
          storeType={extVendorStore}
        />
      )}
      {/* Check Out SlideOver Component  */}
      <SlideOver
        cartData={cartData}
        extUserId={extUserId}
        getVenodrId={extVendorId}
        slug={currentVendor}
        cartLoading={cartLoading}
        show={isOpen === true || (drawerState ? true : false)}
        onClose={closeDrawer}
        data={data} 
        extStoreId={extStoreId}      
        />
      <WishList show={isWishlistOpen === true} extStoreId={extStoreId} extUserId={extUserId} currentVendor={currentVendor} onClose={closeWishlist} />
      {/* Body */}
      {children}
      {/* BackToTop */}
      <BackTop>
        <span className="">
          <img
            className="bg-teal-600 p-[7px] w-[40px] h-[40px] rounded-full"
            src={BackToTop}
            alt=""
          />
        </span>
      </BackTop>
      {/* Footer */}
      {/* {location.pathname !== '/' ? */}
      <div className="flex grow flex-col justify-end">
        <Footer location={location} vendorName={currentVendor} />
      </div>
    </div>
  );
};
export default BaseLayout;
