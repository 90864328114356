import * as Actions from "../../actions";

const initialState = {
  isLoading: false,
  errMsg: null,
  data: null,
  success: "",
};

export const GetVendorsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_VENDORS:
      return {
        ...state,
        isLoading: false,
        errMsg: null,
        data: action.payload,
        success: true,
      };
    case Actions.GET_VENDORS_IS_LOADING:
      return {
        ...state,
        isLoading: 'loading',
        errMsg: null,
        data: null,
        success: false,
      };
    case Actions.GET_VENDORS_FAILED:
      return {
        ...state,
        isLoading: false,
        errMsg: action.payload,
        data: null,
        success: "failed",
      };
    default:
      return state;
  }
};

export const GetVendorsSlugReducer = function (
  state = {
    isLoading: false,
    errMsg: null,
    data: null,
    success: "",
  },
  action
) {
  switch (action.type) {
    case Actions.GET_VENDORS_BY_SLUG:
      return {
        ...state,
        isLoading: false,
        errMsg: null,
        data: action.payload.data,
        success: true,
      };
    case Actions.GET_VENDORS_SLUG_IS_LOADING:
      return {
        ...state,
        isLoading: true,
        errMsg: null,
        data: [],
        success: false,
      };
    case Actions.GET_VENDORS_SLUG_FAILED:
      return {
        ...state,
        isLoading: false,
        errMsg: action.payload,
        data: [],
        success: "failed",
      };
    default:
      return state;
  }
};

export const GetCategoryReducer = function (
  state = {
    isLoading: false,
    errMsg: null,
    data: null,
    success: "",
  },
  action
) {
  switch (action.type) {
    case Actions.GET_CATEGORY:
      return {
        ...state,
        isLoading: false,
        errMsg: null,
        data: action.payload,
        success: true,
      };
    case Actions.GET_CATEGORY_IS_LOADING:
      return {
        ...state,
        isLoading: true,
        errMsg: null,
        data: [],
        success: false,
      };
    case Actions.GET_CATEGORY_FAILED:
      return {
        ...state,
        isLoading: false,
        errMsg: action.payload,
        data: [],
        success: "failed",
      };
    default:
      return state;
  }
};

export const GetShopifySideMenuReducer = function (
  state = {
    isLoading: false,
    errMsg: null,
    data: null,
    success: "",
  },
  action
) {
  switch (action.type) {
    case Actions.GET_SHOPIFY_SIDEMENU:
      return {
        ...state,
        isLoading: false,
        errMsg: null,
        data: action.payload,
        success: true,
      };
    case Actions.GET_SHOPIFY_SIDEMENU_IS_LOADING:
      return {
        ...state,
        isLoading: true,
        errMsg: null,
        data: [],
        success: false,
      };
    case Actions.GET_SHOPIFY_SIDEMENU_FAILED:
      return {
        ...state,
        isLoading: false,
        errMsg: action.payload,
        data: [],
        success: "failed",
      };
    default:
      return state;
  }
};