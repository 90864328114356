import { getData, postData } from 'services/apiService'


export const GET_ORDERS = "GET_ORDERS"
export const GET_ORDERS_IS_lOADING = "GET_ORDERS_IS_lOADING"
export const GET_ORDERS_FAILED = "GET_ORDERS_FAILED"



// HELPER ACTIONS CREATORS
//************************************** /



export const getOrdersIsLoading = () => ({
  type: GET_ORDERS_IS_lOADING,
  payload: {}
})

export const getOrdersFailed = (error) => ({
  type: GET_ORDERS_FAILED,
  payload: error
})

export function getOrders(searchValue, page = 1, sort = -1, supplier = "") {

  const endPoint = `/order/GetAll`
  let params = { page, sort }
  searchValue.trim().length ? params['superSearchText'] = searchValue : params['superSearchText'] = ""
  params['limit'] = ""
  params['supplier'] = supplier
  return dispatch => {
    dispatch(getOrdersIsLoading())

    getData(endPoint, params)
      .then(response => {


        dispatch({
          type: GET_ORDERS,
          payload: response
        })
      })
      .catch(error => {
        dispatch(getOrdersFailed(error))

      })
  }
}
