import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import withReducer from 'store/withReducer'
import { Capitalize, dataPoints, scrollToBottom } from '../../utils/CommonFunctions'
// import { Alert } from '../../components/Alert/Alert'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { ProductCards } from '../../Sections/ProductCards/ProductCards'
import ProductSection from '../../Sections/ProductWithRelatedItems/ProductWithRelatedItems'
import * as Actions from "./store/actions";
import * as CommonActions from "../../store/actions";
import reducer from './store/reducers'
import { openNotificationWithIcon } from 'components/Notification'

const ProductPage = () => {

  // Hooks
  const location = useLocation();
  const dispatch = useDispatch();
  // Reducers
  const { data: cartReducer, updateLoading } = useSelector(({ commonReducers }: any) => commonReducers.GetCartReducer);                                        // CartItems
  const { data }: any = useSelector(({ autReducer }: any) => autReducer.GetUserReducer)
  const { data: productReducer, isLoading: isProductLoading, dataByCategory } = useSelector(({ product }: any) => product.GetProductReducer);   // Fetch Products
  const { data: slugData, isLoading: vendorSlugLoading } = useSelector(({ commonReducers }: any) => commonReducers.GetVendorsSlugReducer);     // Vendor Data
  const { data: wishListData, ...wishlistRest } = useSelector((state: any) => state.commonReducers.GetWishListReducer);
  //  State
  const [isCard, setIsCard] = useState(true)
  const [, setCardType] = useState(isCard ? 'ProductListView' : 'ProductCard');
  const [productQuantity, setProductQuantity] = useState<number>(0);
  const [productCardData, setProductData] = useState<any>([])
  const [totalItems, setTotalItems] = useState<number>(0)
  const cartItemId = cartReducer?.cartItems?.map(cart => cart?.item?._id)
  const vendorWebsite = slugData?.website
  // Get Product id from route
  const { id: productId } = useParams();
  const {slug: vendorName} = useParams();
  const alreadyInCart = cartItemId?.some(i => i === productId)
  const extUserId = data?.data?._doc?.rdUser
  const extStoreId = data?.data._doc?.storeId
  // useEffect
  useEffect(() => {
    dispatch(Actions.getProduct(productId));
    scrollToBottom();
  }, [productId]);
  useEffect(()=> {
    if (dataByCategory) {
      setTotalItems(dataByCategory?.totalDocs)
      const items = dataByCategory?.docs
      if(!items || !items.length) return setProductData([])
      const parsedItems = items.map(item => {
        return {
          id: item._id,
          name: item.name,
          qty: item.qty,
          imageSrc: item.imageUrls[0],
          imageAlt: "Product Image",
          price: item.costPrice,
          vendor: item.vendor,
          vendorId: item.vendorId
        }
      })
      setProductData(parsedItems)
    }
  }, [dataByCategory])
  // useEffect(() => {
  //   const vendor_Id = productReducer?.vendorId;
  //   if (productReducer) {
  //     dispatch(Actions.getItemByCategories(String(productReducer?.category), vendor_Id, 8, 1));
  //   }
  // }, [dispatch, productReducer]);
  // Functions
  const changeView = () => {
    setIsCard(!isCard)
    setCardType('ProductCard')
  }
  const RedirectTo = () => {
    window.open(vendorWebsite, '_blank')
  }
const addToCart = (data) => {
  dispatch(CommonActions.updateCart(data, 1, () => {
    openNotificationWithIcon("success", `Product added successfully!`, "Open cart to see the product.")
  }));
  dataPoints(extUserId, 'Add_To_Cart_Clicked', extStoreId, `Marketplace | ${vendorName} | Product Page`)
}
const extSku = productReducer?.SKU
const extName = productReducer?.name
  return (
    <Fragment>
      <div className={`w-full 3xl:w-10/12 mx-auto 4xl:w-7/12 mx-auto`}>
        <div className='my-6 mx-6'>
          <Breadcrumbs currentPage={Capitalize(vendorName)} extName={extName} currentPageSlug={vendorName} location={location} productId={productId} id={productId} itemSku={extSku} />
        </div>
        {/* <div className='my-6 mx-6'>
          <Alert />
        </div> */}
        {/* Product Cards Detail Section */}
        <div>
          <ProductSection itemSku={extSku} currentVendor={vendorName} extUserId={extUserId} extName={extName} alreadyInCart={alreadyInCart} updateLoading={updateLoading} cartData={cartReducer} addToCartListener={addToCart} singleProduct={productReducer} isProductLoading={isProductLoading} RedirectTo={RedirectTo} data={productReducer} wishListData={wishListData} productId={productId} />
        </div>
        {/* Product Cards Section */}
        <div>
          <ProductCards totalItems={totalItems} onClick={() => { } } cardType={!isCard ? 'ProductListView' : 'ProductCard'} cardHeight={''} changeView={changeView} productCardData={productReducer} productsLoading={isProductLoading} RedirectTo={undefined} slug={''} searchName={undefined} productId={productId} vendorName={vendorName} handleSort={undefined} sortType={undefined} queryCategory={undefined} data={undefined} categoryItemsData={undefined} parsed={undefined} extUserId={''} extStoreName={undefined} isShopifyLoading={false} />
        </div>
      </div>
    </Fragment>
  )
}

export default withReducer("product", reducer)(ProductPage);