import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { svgIcons } from '../../assets/svg'

interface VendorInfoCardProps {
    VendorData: any
    onClick: any
    cardHeight: string
    vendorLoading: boolean
}

export const VendorInfoCard:React.FC<VendorInfoCardProps> = ({VendorData, onClick, cardHeight, vendorLoading}) => {
const width = "704px"
const img_div_width = "189px"
  return (
    <Fragment>
      {!vendorLoading ? VendorData?.map((data:any, i) => (
        <div key={i} className="bg-white flex justify-between w-full items-center rounded-lg cursor-pointer" onClick={onClick} style={{height: cardHeight}}>
          <div className="flex items-center ml-50">     
              <div className=" flex items-center justify-center flex-shrink-0 rounded-md" style={{height: "76px"}} >
                <img src={data.cardIcon} alt={""} width="138px" style={{height: "inherit"}} className="object-contain" />
              </div>
              <div className="min-w-0 flex-1 ml-50">
                <p className="text-3xl font-semibold text-secondary-700" >
                  <Link to="/">
                    {data.title}
                  </Link>
                </p>
                <p className="mt-2 text-base text-secondary-700" style={{minWidth: "auto", maxWidth: width}}>
                  <Link to="/">
                    {data?.description?.length > 170 ? data?.description.slice(0,170) + '...' : data?.description}
                  </Link>
                </p>
              </div>
          </div>
          {/* External Link */}
          <div className="mx-6 w-10 h-10 lg:border-2 sm:border-0 flex items-center justify-center rounded-full border-teal-500">
            {svgIcons.chevronRight}
          </div>
      </div>
      ))
      :
      <SkeletonLoader skeletonType={'default'} defaultSkeletonHeight={'140px'} smallInputWidth={''} smallInputHeight={''} />
    }
        </Fragment>
  )
}
