import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'
import { useNavigate, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import queryString from "query-string";
import { Button } from 'components/Button/Button';
import successGif from '../../assets/images/success.gif';

export default function CheckoutModal() {
  
  // Hooks
  const navigate = useNavigate();
  const location = useLocation()
  const { id, order_id } = queryString.parse(location.search);
  //Reducer
  const userData: any = useSelector(({ autReducer }: any) => autReducer.GetUserReducer);
  // Extract Data
  const extNewUi = userData?.data?.data?.rdUser?.map(u => u.new_ui)
  const extSubDomain = userData?.data?.data?._doc?.subdomain
  // states
  const [open, setOpen] = useState(true)
  // Functions
  const goToOrderPage = () => {
      navigate('/orders')
  }
  const Continue = () => {
      navigate('/')
  }
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(true)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative h-[344px] w-[448px] bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all">
                <div>
                  <div className="mx-auto mt-8 flex items-center justify-center">
                    <img src={successGif} alt='' width="104px" height="104px" className='max-w-none' />
                  </div>
                  <div className="mt-6 text-center">
                    <Dialog.Title as="h3" className="text-2xl font-semibold text-primary-600">
                      Success!
                    </Dialog.Title>
                    <div className="mt-2 mx-16">
                      <p className="text-base text-secondary-500">
                        Purchase Order{" "}
                        <span className='text-primary-500 cursor-pointer' onClick={() => {
                        const nux = extNewUi[0] === 1 ? "nux/": "";
                        const viewId = extNewUi[0] === 1 ? `index#/${id}`: `view&id=${id}`;
                        const url = `${extSubDomain}/index.php?r=${nux}purchaseorder/${viewId}`
                        return window.open(url)
                        }}>
                         {order_id}{" "}</span>
                         has been created against your order.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-6 flex items-center justify-center">
                  <Button defaultBgColor={'mr-4 text-base font-medium rounded-md bg-white border-2 hover:bg-secondary-100 focus:outline-none'} btnType="SecondaryButton" defaultBtnWidth="192px" defaultBtnHeight='40px' defaultBtnText="Go to orders" onClick={goToOrderPage} />
                  <Button defaultBgColor={'text-white bg-primary-600 hover:bg-primary-700'} defaultBtnText="Continue" defaultBtnWidth="192px" defaultBtnHeight='40px' onClick={Continue} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
